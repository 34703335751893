import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../../style/particular/atome.scss';

const tabText = ["Localisez-vous ou utilisez la barre d'adresse.",
    "Comparez les prestations et les professionnels.",
    "Sélectionnez un créneau et validez votre commande."]

const Atome = ({ isSmallScreen }) => {
    const [stepClass, setStepClass] = useState(1);
    const [initialStep, setInitialStep] = useState(true);


    const handleonMouseEnter = (text, step) => {
        setStepClass(step);
        setInitialStep(false);
    }

    return (
        <Container fluid>
            {
                !isSmallScreen
                    ? <Row>
                        <Col className="col-car-stepper" md>
                            <img
                                className={"car-stepper " + (stepClass === 2 ? "step-2" : stepClass === 3 ? "step-3" : "") + (initialStep ? "initial-step-car" : "")}
                                src="img/particular/car-stepper.png"
                                alt="voiture" />
                        </Col>
                    </Row>
                    : <></>
            }

            <Row>
                <Col xs={4} md>

                    <div id="little-circle"
                        className="initial-step"
                        onMouseEnter={() => handleonMouseEnter(0, 1)}
                    >
                        1
                    </div>
                </Col>
                <Col xs={4} md>
                    <div id="little-circle"
                        className={(stepClass === 2 || stepClass === 3) && !initialStep ? "initial-step" : ""}
                        onMouseEnter={() => handleonMouseEnter(1, 2)}
                        onMouseLeave={() => setInitialStep(true)} >
                        2
                    </div>
                </Col>
                <Col xs={4} md>
                    <div id="little-circle"
                        onMouseEnter={() => handleonMouseEnter(2, 3)}
                        onMouseLeave={() => setInitialStep(true)}>
                        3
                    </div>
                </Col>
            </Row>
            {
                !isSmallScreen
                    ? <Row className="align-center">
                        <Col md>
                            <p>{tabText[0]}</p>

                        </Col>
                        <Col md>
                            {(stepClass === 2 || stepClass === 3) && !initialStep
                                ? <p>{tabText[1]}</p>
                                : <></>
                            }
                        </Col>
                        <Col md>
                            {stepClass === 3 && !initialStep
                                ? <p>{tabText[2]}</p>
                                : <></>
                            }
                        </Col>
                    </Row>
                    : <Row style={{ marginTop: '2em', marginLeft: '1em', marginRight: '1em' }}>
                        <Col className="no-padding" md>
                            <p className="text-underline ">1. Localisez-vous ou utilisez la barre d'adresse.</p>
                            <p className={(stepClass === 2 || stepClass === 3) && !initialStep ? "text-underline" : ""}>2. Comparez les prestations et les professionnels.</p>
                            <p className={stepClass === 3 && !initialStep ? "text-underline " : ""}>3. Sélectionnez un créneau et validez votre commande.</p>
                        </Col>
                    </Row>
            }

        </Container>
    );
}

export default Atome;