import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ScrollAnimation from "react-animate-on-scroll";

export const WashmeeEasyMobile = () => {
    return (
        <section id="easy-paricular" style={{ marginBottom: '3em' }} md>
            <Row>
                <Col className="text-section">
                    <Row>
                        <Col>
                            <h2>Le lavage de votre véhicule n’a jamais été aussi simple !</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <img
                                style={{ borderRadius: '5%' }}
                                // className="border-radius-img"
                                alt="digital"
                                src="img/particular/lavage-auto-simple-washmee-particulier.jpg"
                            />
                        </Col>
                        <Col>
                            <ScrollAnimation animateIn="slideInLeft">
                                <p>
                                    WashMee c’est la marketplace où vous retrouvez l'ensemble
                                    des professionnels du lavage automobile en France pour vous
                                    simplifier le nettoyage de votre voiture ou 2 roues. Que vous
                                    soyez un(e) passionné(e) ou un une personne considérant son véhicule
                                    comme un simple moyen de locomotion, WashMee répond à vos attentes.</p>
                                <p>En quelques clics, choisissez le lavage qui vous convient : d’un lavage
                                    haute pression à un nettoyage poussé réalisé à la main par un pro...</p>
                                <p>WashMee est pour vous la certitude de trouver le professionnel proche de
                                    chez vous qui répond à vos besoins du moment.</p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <button className="btn-washmee" onClick={() => window.location = "https://www.washmee.fr/commander-lavage/?p=commander-station"} > Commander un lavage</button>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </section>
    );
};

export const WhyWashmeeMobile = () => {
    return (
        <section id="section-why-mobile">
            <Row>
                <Col>
                    <h2>Pourquoi choisir WashMee pour le lavage de votre véhicule ?</h2>
                </Col>
            </Row>
            <Row className="row-img">
                <Col>
                    <img className="border-radius-img" src="./img/particular/avantage-washmee-visibilite.jpg" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="bold-tilinium ">Visibilité</h4>
                </Col>
            </Row>
            <ScrollAnimation animateIn="slideInLeft">
                <Row>
                    <Col>
                        <p>Vous ne serez plus perdus dans des annuaires qui ne listent
                        qu’une partie des stations et des professionnels du lavage.
                        Notre équipe référence tous les jours de nouveaux partenaires
                        pour que vous trouviez facilement la solution près de vous.</p>
                    </Col>
                </Row>
            </ScrollAnimation>
            <Row className="row-img">
                <Col>
                    <img className="border-radius-img" src="./img/particular/avantage-washmee-personnalise.jpg" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="bold-tilinium "> Personnalisation</h4>
                </Col>
            </Row>
            <ScrollAnimation animateIn="slideInLeft">
                <Row>
                    <Col>
                        <p>Indiquez votre type de véhicule afin de découvrir les
                        prestations de lavage et tarifs adaptés. Comparez facilement
                         les offres des professionnels et commandez !</p>
                    </Col>
                </Row>
            </ScrollAnimation>
            <Row className="row-img">
                <Col>
                    <img className="border-radius-img" src="./img/particular/avantage-washmee-liberte.jpg" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="bold-tilinium ">Liberté</h4>
                </Col>
            </Row>
            <ScrollAnimation animateIn="slideInLeft">
                <Row>
                    <Col>
                        <p>Découvrez les informations utiles des stations ou laveurs,
                        réservez facilement un lavage où et quand vous voulez. Vous
                        pourrez aussi déclencher via votre smartphone un programme sur
                        un portique ou récupérer des jetons pour un aspirateur !</p>
                    </Col>
                </Row>
            </ScrollAnimation>
        </section>
    )
}