import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import CookieConsent from "react-cookie-consent";
import { Document } from 'react-pdf';
import axios from 'axios';

import { tabIconMedia, tabNav } from '../../helpers/texts/footer.json';
import { api } from '../../helpers/api';

import '../../style/layout/footer.scss';
import 'react-toastify/dist/ReactToastify.css';


const Footer = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data =>
        api.post('/newsletter', {
            ...data
        })
            .then(data => {
                toast.success("Merci de vous être inscrit à la newsletter ! Un mail de confirmation vous à été envoyé !", {
                    autoClose: 5000,
                    position: "top-center",
                    closeOnClick: true,
                });
            })
            .catch(err => {
                if (err.response && err.response.status === 400)
                    toast.warn(err.response.data, {
                        autoClose: 5000,
                        position: "top-center",
                        closeOnClick: true,
                    });

                else
                    toast.error("Erreur interne", {
                        autoClose: 5000,
                        position: "top-center",
                        closeOnClick: true,
                    });
            })

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    return (
        <footer>
            {
                !isSmallScreen
                    ? <Row>

                        <Col className="col-newsletter" md>
                            <h6 className="bold-tilinium">Newsletter</h6>
                            <p>Recevez l’actu de WashMee et nos conseils pour entretenir
                                votre véhicule ! </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input name="email" placeholder="Email" ref={register({ required: true })} />
                                <button type="submit">S'abonner</button>
                            </form>


                            {errors.exampleRequired && <span>This field is required</span>}


                        </Col>
                        <Col className="col-application" md>
                            <h6 className="bold-tilinium">Application</h6>
                            <p>Comparez, réservez, achetez, lancez votre programme ou récupérez
                                vos jetons depuis notre appli :</p>
                            <a href="https://apps.apple.com/fr/app/washmee-lavage-auto-et-%C3%A9colo/id1457853657"><img className="icon-download" src="img/icon-apple.png" alt="icon apple" /></a>
                            <a href="https://play.google.com/store/apps/details?id=fr.washmee.app&gl=FR"><img className="icon-download" src="img/icon-google.png" alt="icon google" /></a>
                        </Col>
                        <Col className="col-nav" md>
                            <h6 className="bold-tilinium">Navigation</h6>
                            <Row>
                                <Col className="margin-top" md><a href="/particulier">Particulier</a></Col>
                                <Col className="margin-top" md><a href="/entreprise">Entreprise</a></Col>
                            </Row>
                            <Row>
                                <Col className="margin-top" md><a href="https://www.washmee.fr/blog/">Blog</a></Col>
                                <Col className="margin-top" md><a href="/partenaire">Partenaires</a></Col>
                            </Row>
                            <Row>
                                <Col md><a href="/CGU">CGU</a></Col>
                                <Col className="margin-top" md><a href="/contact">Contact</a></Col>
                            </Row>
                            <Row>
                                <Col className="margin-top" md><a href="/mentionlegal">Mentions légales</a></Col>
                            </Row>
                        </Col>
                        <Col className="col-contact" md>
                            <h6 className="bold-tilinium">Contact</h6>
                            <p><b>Téléphone : </b> <a href="tel:0974748803">09 74 74 88 03</a></p>
                            <p><b>Email : </b> <a href="mailTo:info@washmee.fr">info@washmee.fr</a></p>
                            <br />
                            <h6>Suivez-nous :</h6>
                            {
                                tabIconMedia.map((e, i) =>
                                    <a href={e.href} key={i}><img key={i} src={e.img} /></a>
                                )
                            }
                        </Col>
                    </Row>
                    : <Row className="full-width no-margin">
                        <Row >
                            <Col className="col-newsletter">
                                <h6 className="bold-tilinium">Newsletter</h6>
                                <p>Recevez l’actu de WashMee et nos conseils pour entretenir votre véhicule ! </p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input name="email" placeholder="Email" ref={register({ required: true })} />
                                    {errors.exampleRequired && <span>This field is required</span>}
                                    <button type="submit">S'abonner</button>
                                </form>
                            </Col>
                        </Row>
                        <hr style={{
                            color: "white",
                            backgroundColor: "white",
                            width: '100%',
                            height: "2px"
                        }} />
                        <Row >
                            <Col className="col-app">
                                <h6 className="bold-tilinium"> Application</h6>
                                <p>Comparez, réservez, achetez, lancez votre programme ou récupérez vos jetons depuis notre appli :</p>
                                <a href="https://apps.apple.com/fr/app/washmee-lavage-auto-et-%C3%A9colo/id1457853657"><img className="icon-download" src="./img/icon-apple.png" alt="icon apple" /></a>
                                <a href="https://play.google.com/store/apps/details?id=fr.washmee.app&gl=FR"><img className="icon-download" src="./img/icon-google.png" alt="icon google" /></a>
                            </Col>
                        </Row>
                        <hr style={{
                            color: "white",
                            backgroundColor: "white",
                            width: '100%',
                            height: "2px"
                        }} />
                        <Row >
                            <Col className="col-contact">
                                <h6 className="bold-tilinium">Contact</h6>
                                <p><b>Téléphone : </b> <a href="tel:0974748803">09 74 74 88 03</a></p>
                                <p><b>Email : </b> <a href="mailTo:info@washmee.fr">info@washmee.fr</a></p>
                                <h6 style={{ marginTop: '1em' }} className="bold-tilinium">Suivez-nous :</h6>
                                {
                                    tabIconMedia.map((e, i) =>
                                        <a href={e.href}><img key={i} src={e.img} /></a>
                                    )
                                }
                            </Col>
                        </Row>
                        <hr style={{
                            color: "white",
                            backgroundColor: "white",
                            width: '100%',
                            height: "2px"
                        }} />
                        <Row >
                            <Col className="col-nav">
                                <h6 className="bold-tilinium"> Navigation</h6>
                                <Row >
                                    <Col md={12} ><a href="/particulier">Particulier</a></Col>
                                    <Col md={12} ><a href="/entreprise">Entreprise</a></Col>
                                    <Col md={12} ><a href="https://www.washmee.fr/blog/">Blog</a></Col>
                                    <Col md={12} ><a href="/contact">Contact</a></Col>
                                    <Col md={12} ><a href="/partenaire">Partenaires</a></Col>
                                    <Col md={12} ><a href="/CGU">CGU</a></Col>
                                    <Col md={12} ><a href="/mentionlegal">Mentions légales</a></Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{
                            color: "white",
                            backgroundColor: "white",
                            width: '100%',
                            height: "2px"
                        }} />
                    </Row>
            }
            <Row>
                <Col className="align-center" md>
                    <p >© Copyright 2021 WashMee | Designed with ❤️ | Tous droits réservés</p>
                </Col>
            </Row>
            <CookieConsent
                location="bottom"
                buttonText="Tout accepter"
                declineButtonText="Tout refuser"
                style={{
                    background: "#3A4553",
                    border: "1px solid #3A4553",
                    color: "white",
                    marginBottom: "1em",
                }}
                buttonStyle={{
                    background: "#96D7DE",
                    fontSize: "16px",
                    color: "white"
                }}
                expires={396} enableDeclineButton flipButtons>
                <img src="img/home/cookies.png" alt="cookie" style={{
                    width: "2em",
                    marginRight: "1em"
                }} />
                Utilisation des cookies
                <br />
                Afin de vous offrir la meilleure expérience possible, nous utilisons des cookies
                dans les conditions prévues par notre Politique de protection des données personnelles.
                <br />
                <a href="/mentionlegal?p=cookie" className="bold-tilinium" style={{ color: '#96D7DE' }}>En savoir plus.</a>
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </footer >
    )
}

export default Footer;