import React, { useState, useEffect } from "react";
import { Row, Col, Container, Carousel } from "react-bootstrap";

import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import SliderDynamic from "../../components/SliderDynamic";
import { WashmeeEasyDesktop } from "./Desktop";
import { WashmeeEasyMobile, WhyWashmeeMobile } from "./Mobile";
import Atome from './Atome';

import { particular } from "../../helpers/texts/particular.json";

import "../../style/particular/particular.scss";
import "../../style/particular/particular-phone.scss";
import "../../style/particular/stepper.css";
import "../../style/home/home.scss";
import "../../style/particular/cardFeedback.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const WhyWashmee = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [sectionWhyWashmee, setSectionWhyWashmee] = useState("image1");
  const [textWhyWashmee, setTextWhyWashmee] = useState("text1");
  const [textMobileWhyWashmee, setTextMobileWhyWashmee] = useState(
    "Ne vous perdez plus dans les milliers d’annuaires qui liste qu’une partie des professionnels du lavage. Notre équipe référence tous les jours de nouveaux partenaires pour que vous trouviez facilement un professionnel près de chez vous."
  );
  const [titleMobileWhyWashmee, setTitleMobileWhyWashmee] = useState(
    "Visibilité"
  );
  const HandleMobileText = (i) => {
    if (i === 1) {
      setTitleMobileWhyWashmee("Visibilité");
      setTextMobileWhyWashmee(
        "Ne vous perdez plus dans les milliers d’annuaires qui liste qu’une partie des professionnels du lavage. Notre équipe référence tous les jours de nouveaux partenaires pour que vous trouviez facilement un professionnel près de chez vous."
      );
    } else if (i === 3) {
      setTitleMobileWhyWashmee("Personnalisé");
      setTextMobileWhyWashmee(
        "renseignez votre véhicule et découvrez les tarifs ajustés pour une meilleure précision lors de la comparaison entre les professionnels."
      );
    } else {
      setTitleMobileWhyWashmee("Liberté");
      setTextMobileWhyWashmee(
        "en quelques clics découvrez les informations utiles des laveurs près de chez-vous, réservez facilement un lavage où et quand vous voulez avec l’un des professionnels référencés ou profitez de la digitalisation des programmes et jetons utilisables dans la station sélectionnée."
      );
    }
  };
  const HandleMouseEvents = (e, i) => {
    setSectionWhyWashmee(`image${i}`);
    {
      !isSmallScreen && setTextWhyWashmee(`text${i}`);
    }
    {
      isSmallScreen && HandleMobileText(i);
    }
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);
  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
      setTextWhyWashmee(null);
      console.log("SMALL SCREEN");
    } else {
      setTextWhyWashmee(`text1`);
      setIsSmallScreen(false);
    }
  };
  return (
    <>
      <Row>
        <Col>
          <h2 style={{ marginBottom: '1em', textAlign: 'center' }}>Pourquoi choisir WashMee pour le lavage de votre véhicule ?</h2>
        </Col>
      </Row>
      <section id="section-why-washmee" className={sectionWhyWashmee}>

        <Container fluid>
          <Row>
            {isSmallScreen && (
              <div className="bg-mobile-title">
                <div className="bg-text-mobile-content">
                  <h3>{titleMobileWhyWashmee}</h3>
                  <p>{textMobileWhyWashmee}</p>
                </div>
              </div>
            )}
            <div
              className="bg-list-column"
              onMouseEnter={() => HandleMouseEvents(true, 1)}
              // onMouseLeave={() => HandleMouseEvents(false, 1)}
              md={5}
              sm={true}
            >
              <div className="bg-list-content">
                <div
                  className="bg-list-title"
                  style={{
                    display: textWhyWashmee === "text1" ? "block" : "none",
                  }}
                // onMouseEnter={showText(1)}
                >
                  <h3>Visibilité</h3>
                  <p>
                    Vous ne serez plus perdus dans des annuaires qui ne listent
                    qu’une partie des stations et des professionnels du lavage.
                    Notre équipe référence tous les jours de nouveaux partenaires
                    pour que vous trouviez facilement la solution près de vous.
                </p>
                </div>
                <div className="bg-list-text">
                  <div className="bg-list-desc"></div>
                </div>
              </div>
            </div>
            <div
              className="bg-list-column"
              onMouseEnter={() => HandleMouseEvents(true, 2)}
              // onMouseLeave={() => HandleMouseEvents(false, 2)}
              md={5}
              sm={true}
            >
              <div className="bg-list-content">
                <div
                  className="bg-list-title"
                  style={{
                    display: textWhyWashmee === "text2" ? "block" : "none",
                  }}
                >
                  <h3>Personnalisation</h3>
                  <p>
                    Indiquez votre type de véhicule afin de découvrir les
                    prestations de lavage et tarifs adaptés. Comparez facilement
                    les offres des professionnels et commandez !
                </p>
                </div>
                <div className="bg-list-text">
                  <div className="bg-list-desc"></div>
                </div>
              </div>
            </div>
            <div
              className="bg-list-column"
              onMouseEnter={() => HandleMouseEvents(true, 3)}
              // onMouseLeave={() => HandleMouseEvents(false, 3)}
              md={5}
              sm={true}
            >
              <div className="bg-list-content">
                <div
                  className="bg-list-title"
                  style={{
                    display: textWhyWashmee === "text3" ? "block" : "none",
                  }}
                >
                  <h3>Liberté</h3>
                  <p>
                    Découvrez les informations utiles des stations ou laveurs,
                    réservez facilement un lavage où et quand vous voulez. Vous
                    pourrez aussi déclencher via votre smartphone un programme sur
                    un portique ou récupérer des jetons pour un aspirateur !
                </p>
                </div>
                <div className="bg-list-text">
                  <div className="bg-list-desc"></div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};


const CarouselFeedback = () => {
  return (
    <section style={{ marginTop: '3em', marginBottom: '3em' }}>
      <Row>
        <Col>
          <h2 style={{ textAlign: 'center', marginLeft: '0' }}>Ils ont été séduits par la simplicité de WashMee</h2>
        </Col>
      </Row>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {
          particular.feedBack.map((e, i) =>
            <Carousel.Item>
              <figure class="snip1167">
                <img
                  src={e.img}
                  alt="sq-sample3"
                />
                <blockquote>
                  {e.feedback}
                </blockquote>
                {/* <div class="author">
                  <h5>
                    {e.title}
                  </h5>
                </div> */}
              </figure>
            </Carousel.Item>
          )
        }
        {
          particular.feedBack.map((e, i) =>
            <Carousel.Item>
              <figure class="snip1167">
                <img
                  src={e.img}
                  alt="sq-sample3"
                />
                <blockquote>
                  {e.feedback}
                </blockquote>
                {/* <div class="author">
                  <h5>
                    {e.title}
                  </h5>
                </div> */}
              </figure>
            </Carousel.Item>
          )
        }
      </Carousel>
    </section>
  );
};

const StepDynamique = ({ isSmallScreen }) => {
  return (
    <Container style={{
      marginTop: '2em'
    }} fluid>
      <Row >
        <Col className="align-center" md>
          <h2 style={{ marginLeft: '0' }}>La réservation d’un lavage simple et rapide !</h2>
        </Col>
      </Row>
      <Row >
        <Col>
          <p style={{ marginLeft: '1em' }}> WashMee digitalise la réservation du lavage de votre véhicule. Il ne
            vous faudra que 3 étapes et quelques minutes.</p>
        </Col>
      </Row>
      <Atome isSmallScreen={isSmallScreen} />
    </Container >
  )
}


const Particular = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };
  return (
    <>
      <Header />
      <SliderDynamic
        title="WASHMEE, LA RÉFÉRENCE FRANÇAISE DU LAVAGE AUTOMOBILE"
        slides={particular.slides}
        slidesMobile={particular.slidesMobile}
        isSmallScreen={isSmallScreen}
        page="particular"
      />

      {!isSmallScreen ? <div style={{ height: '5em' }}></div> : <></>}
      {!isSmallScreen ? <WashmeeEasyDesktop /> : <WashmeeEasyMobile />}
      {!isSmallScreen ? <div style={{ height: '5em' }}></div> : <></>}
      {isSmallScreen ? <WhyWashmeeMobile /> : <WhyWashmee />}
      <StepDynamique isSmallScreen={isSmallScreen} />
      <div style={{ height: "2em" }}></div>
      <CarouselFeedback />
      <Footer />
    </>
  );
};
export default Particular;
