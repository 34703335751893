import React, { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import '../../style/home/autocomplete.scss';

const EndAdornment = ({ error }) => (error === true ? <></> : null);


const AddressAutocomplete = ({ address, setAddress, setLocation }) => {
    const [colorAddress, setColorAddress] = useState("grey");
    const [error, setError] = useState(undefined);

    const checkError = () => address === '';

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                window.location = `https://www.washmee.fr/commander-lavage/?p=commander-station&lat=${latLng.lat}&lng=${latLng.lng}`;
            })
            .catch(error => console.error('Error', error));
    };
    return (
        <PlacesAutocomplete
            value={address}
            onChange={e => setAddress(e)}
            onSelect={e => {
                setAddress(e);
                handleSelect(e, setLocation);
            }}
            searchOptions={{
                componentRestrictions: { country: 'fr' },
                types: ['address'],
                region: 'fr',
                geometry: { location_type: 'ROOFTOP' },
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="address-autocomplete">
                    <input
                        {...getInputProps({ className: 'location-search-input' })}
                        placeholder="    Entrez votre adresse"
                        startAdornment={(
                            // <InputAdornment position="start" size="80%">
                            {/* <Home htmlColor={colorAddress} /> */ }
                            // </InputAdornment>
                        )}
                        onFocus={() => setColorAddress("#3A4553")}
                        onBlur={() => {
                            setColorAddress("#3A4553");
                            setError(checkError);
                        }}
                        endAdornment={<EndAdornment error={error} />}
                    />
                    <div
                        className="pac-container b-white"
                        style={suggestions.length === 0 ? { display: 'none' } : null}
                    >
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            const style = suggestion.active
                                ? { backgroundColor: '#3A4553', cursor: 'pointer', color: 'white' }
                                : { backgroundColor: 'white', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                        {/* <img
                            src="/img/address/powered_by_google_on_white_hdpi.png"
                            alt="google autocomplete"
                        /> */}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};
export default AddressAutocomplete;