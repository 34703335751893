import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


export const WhyWashmeeDesktop = () => {
    return (
        <section id="section-univers" className="text-img-section" style={{ marginTop: '10em' }}>
            <div id="div-title">
                <h1>Pourquoi</h1>
                <img className="logo-washmee-title" src="./img/logo.png" style={{ width: '9em' }} />
                <p>?</p>
            </div>
            <ScrollAnimation animateIn="slideInLeft">
                <div id="div-text" className="div-text-washer">
                    <p style={{ marginTop: '0' }}>WashMee est la solution complète et indépendante de
                    toute marque de lavage auto. Tous les types de lavages sont référencés :
                    lavage à la main, à la vapeur, haute pression, portique ou tunnel...</p>

                    <p>WashMee met en relation des professionnels du lavage de véhicules avec
                    des utilisateurs afin de simplifier et digitaliser l'expérience client.</p>

                    <p style={{ marginBottom: '0' }}>Vous êtes autoentrepreneur, gérant de station, franchisé ou indépendant,
                    WashMee vous aide à développer votre notoriété et votre résultat ! Le
                processus d’inscription est simple et a été conçu pour couvrir chacun de vos besoins.</p>
                    <div className="btn-div-text" style={{ marginTop: '1em' }}>
                        <button className="btn-washmee" onClick={() => window.location = "https://washmee.fr/contact?form=washer"}>Contactez-nous</button>
                    </div>

                </div>
            </ScrollAnimation>
            {/* <ScrollAnimation animateIn="slideInRigth"> */}
            <div id="div-img">
                <div class="partContentFileImageGallery partContentFileImageGallerySingle">
                    <img className="img-div-img" src="img/washer/Washer.jpg" alt="image" />
                </div>
            </div>
            {/* </ScrollAnimation> */}
        </section>

    )
}