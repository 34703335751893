import axios from 'axios';
import url from '../secret';

export const api = axios.create({
  baseURL: url,
  timeOut: 1000,
  headers: { Accept: 'application/json' },
});

export const loadAuthorisationHeader = () => ({ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
