import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { api } from '../../helpers/api';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import SearchBar from './SearchBar';

import { partnership } from '../../helpers/texts/partnership.json';

import '../../style/utils.scss';
import '../../style/partner/partner.scss';

const SelectedCity = () => {
    const [selectedCity, setSelectedCity] = useState(null);

    const removeState = () => setSelectedCity(null)

    return (
        <>
            <Container id="section-main-picture" fluid>
                <Row>
                    <Col id="picture" md>
                        <Row>
                            <Col md>
                                <h2>TOUTES LES SOLUTIONS DE LAVAGE EN FRANCE</h2>
                            </Col>
                        </Row>
                        <Row style={{ margin: '0 auto' }}>
                            <Col md style={{ padding: "0" }}>
                                <SearchBar removeState={removeState} setCity={setSelectedCity} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {
                selectedCity &&
                <SelectedCityContent selectedCity={selectedCity} />
            }

        </>
    )
}

const SelectedCityContent = ({ selectedCity }) => {
    const [washingCompanies, setWashingCompanies] = useState([]);
    const [homeWashingCompanies, setHomeWashingCompanies] = useState([]);
    const [href, setHref] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get(`/getwasherbycity/${selectedCity}`)
            .then(response => {
                setWashingCompanies(response.data.washingCompanies);
                setHomeWashingCompanies(response.data.homeWashers);
                setHref(response.data.href);
                setError(false);
            })
            .catch(err => {
                setError(true);
            })

    }, [])

    // const removeState = () => {
    //     setWashingCompanies(null);
    //     setHomeWashingCompanies(null);
    //     setHref(null)
    // }

    return (
        <>
            {
                error ?
                    <>
                        <div style={{ height: '2em' }}></div>
                        <Row>
                            <Col className="align-center" md>
                                <p>Pas de station et de laveur disponible ou en cours de recrutement dans votre ville !</p>
                            </Col>
                        </Row>
                    </>
                    : <></>
            }
            {
                washingCompanies && washingCompanies.length !== 0 || homeWashingCompanies && homeWashingCompanies.length !== 0 ?
                    <Container id="section-selected-city" fluid>
                        <div style={{ height: '2em' }}></div>
                        <Row>
                            <Col md>
                                <h2 className="align-center" > Lavage auto à {selectedCity}</h2>
                            </Col>
                        </Row>
                        <div style={{ height: '2em' }}></div>
                        <Row>
                            <Col md={1} xs={3}>
                                <img src="./img/partnership/hautep.png" className="picto-laveur" alt="lavage en station" />
                            </Col>
                            <Col md={10} xs={7}>
                                <h3>Stations de lavage</h3>
                            </Col>
                        </Row>
                        <Row style={{ magin: '0 auto', position: 'relative' }}>
                            {
                                washingCompanies && washingCompanies.length !== 0 && washingCompanies.map((e, i) =>
                                    <Col md={4}>
                                        <Card
                                            key={i} className="wash-station">
                                            <Card.Body>
                                                <Card.Title className="bold-tilinium">{e.name}</Card.Title>
                                                <Card.Text>
                                                    <p>{e.route}</p>
                                                    <p>{e.postal_code} {washingCompanies[0].city}</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            }
                            {
                                washingCompanies.length === 0 ?
                                    <Col className="align-center" md>
                                        <p>Pas de station disponible, ou en cours de recrutement dans votre ville !</p>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Row>
                        <div style={{ height: '2em' }}></div>
                        {
                            washingCompanies && washingCompanies.length !== 0 &&

                            <Row>
                                <Col className="align-center" md>
                                    <button className="btn-washmee" onClick={() => window.location = href}>Réserver un lavage à {selectedCity}</button>
                                </Col>
                            </Row>
                        }
                        <div style={{ height: '2em' }}></div>
                        <Row>
                            <Col md={1} xs={3}>
                                <img src="./img/partnership/domicile.png" className="picto-laveur" alt="lavage en station" />
                            </Col>
                            <Col md={11} xs={7}>
                                <h3 >Laveurs à domicile</h3>
                            </Col>
                        </Row>
                        <Row>
                            {
                                homeWashingCompanies && homeWashingCompanies.map((e, i) =>
                                    <Col md={4}>
                                        <Card
                                            key={i} className="home-washing">
                                            <Card.Body>
                                                <Card.Title className="bold-tilinium">{e.firstname} {e.lastname}</Card.Title>
                                                <Card.Text>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Row>
                        <div style={{ height: '2em' }}></div>
                        <Row>
                            <Col className="align-center" md>
                                <button className="btn-washmee" onClick={() => window.location = href}>Réserver un lavage à {selectedCity}</button>
                            </Col>
                        </Row>
                    </Container>
                    : <></>
            }
        </>

    )
}

const MainCities = () => {
    const [cities, setCities] = useState(null);

    useEffect(() => {
        api.get(`/getcities`)
            .then(response => {
                setCities(response.data.maincities);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <Container id="section-main-cities">
            <Row>
                <Col>
                    <h2 style={{ textAlign: 'center' }}>Trouvez un professionnel du lavage près de chez vous ! </h2>
                </Col>
            </Row>

            <Row className="cards-component-home">
                {
                    partnership.cities.map((e, i) =>
                        <MainCitiesCard key={i} data={e} />
                    )
                }
            </Row>
        </Container>
    )
}

const MainCitiesCard = ({ data }) => {

    return (
        <Col className="card-component-home " md={4} >
            <a style={{ width: '100%', height: '100%', display: "block", textDecoration: 'none' }} href={data.href}>
                <Row className="text-over-image-home  no-gutters" >
                    <div className="col-6 image"><img src={data.img} alt={data.city} /></div>
                    <div className="col-6 text">
                        <div>
                            <p>{data.city}</p>
                        </div>
                    </div>

                </Row>
            </a>
        </Col>
    )
}

const CitiesList = () => {
    const [cities, setCities] = useState(null);
    useEffect(() => {
        api.get(`/getcities`)
            .then(response => {
                setCities(response.data.cities);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    return (
        <Container id="section-cities-list" fluid>
            <Row>
                <Col md>
                    <h2>Trouvez un lavage auto par ville</h2>
                </Col>
            </Row>

            <Row>
                {
                    cities &&
                    cities.map((e, i) =>
                        <Col md={4} className="col-city" key={i}><a href={e.href}>Lavage auto à {e.city}</a></Col>
                    )
                }
            </Row>
        </Container>
    )
}

const Partnership = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = (mediaQuery) => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };
    return (
        <>
            <Header />
            {/* <MainPicture setCity={setSelectedCity} /> */}
            {/* {selectedCity ? <SelectedCity city={selectedCity} /> : <></>} */}
            <SelectedCity />

            {/* <SelectedCity city="Paris" /> */}
            <MainCities />
            <CitiesList />
            <Footer />
        </>
    )
}

export default Partnership;