import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


export const WhyWashmeeMobile = () => {
    return (
        <section id="why-washmee-washer">
            <Container>
                <Row>
                    <Col>
                        <h2>Pourquoi WashMee ?</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src="img/washer/Washer.jpg" alt="laveur" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>WashMee est la solution complète et indépendantes de
                        toutes marques du lavage de véhicules. La solution gère
                            tous types de lavages et tous types de véhicules.</p>
                        <p>WashMee met en relation des professionnels du lavage et
                        des utilisateurs afin de simplifier et digitaliser le domaine
                        du lavage automobile pour ainsi ôter ce stéréotype de corvée et
                        contrainte et mettre en avant les différentes techniques aujourd’hui
                        disponible sur le marché : lavage à la main, à la vapeur, haute pression,
                            portique ou tunnel.</p>
                        <p>Sur WashMee l’utilisateur peut découvrir des professionnels proche de
                                chez lui et choisir un lavage adapté à ses besoins et attentes.</p>
                        <p>Vous êtes autoentrepreneurs, gérants de station, franchisés ou indépendants,
                        WashMee vous aide à développer votre notoriété et votre résultat ! Le
                        processus d’inscription est simple, gratuit et a été conçu pour couvrir
                                chacun de vos besoins.</p>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}