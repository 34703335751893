import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';

const MentionLegal = (props) => {
    const [isMentionLegale, setIsMentionLegale] = useState(true);
    const [isCookie, setIsCookie] = useState(false);
    const [isConditionVente, setIsConditionVente] = useState(false);
    const [isPolitiqueConfidentialite, setIsPolitiqueConfidentialite] = useState(false);

    useEffect(() => {
        if (window.location.search === '?p=cookie') {
            setIsMentionLegale(false);
            setIsCookie(true);
            setIsConditionVente(false);
            setIsPolitiqueConfidentialite(false);
        }
    }, [])

    const handleMentionLegale = () => {
        setIsMentionLegale(true);
        setIsCookie(false);
        setIsConditionVente(false);
        setIsPolitiqueConfidentialite(false);
    }

    const handleCookie = () => {
        setIsMentionLegale(false);
        setIsCookie(true);
        setIsConditionVente(false);
        setIsPolitiqueConfidentialite(false);
    }
    const handleConditionVente = () => {
        setIsMentionLegale(false);
        setIsCookie(false);
        setIsConditionVente(true);
        setIsPolitiqueConfidentialite(false);
    }
    const handlePolitiqueConfidentialite = () => {
        setIsMentionLegale(false);
        setIsCookie(false);
        setIsConditionVente(false);
        setIsPolitiqueConfidentialite(true);
    }

    return (
        <>
            <Header />
            <Row style={{ marginTop: '2em', marginLeft: '2em' }}>
                <Col>
                    <button className="btn-washmee" onClick={() => handleMentionLegale()}>Mentions Légales</button>
                </Col>
                <Col>
                    <button className="btn-washmee" onClick={() => handleCookie()}>Déclaration Relative aux Cookies</button>
                </Col>
                <Col>
                    <button className="btn-washmee" onClick={() => handleConditionVente()}>Condition Générales de Vente</button>
                </Col>
                <Col>
                    <button className="btn-washmee" onClick={() => handlePolitiqueConfidentialite()}>Politique de Confidentialité</button>
                </Col>
            </Row>
            {
                isMentionLegale
                    ? <div style={{ padding: '3em' }}>
                        <h2>Mentions légales</h2>

                        <p>Date de modification : 04 mars 2019</p>
                        <p>Date d'entrée en vigueur : 04 mars 2019</p>

                        <p><b>Introduction</b></p>
                        <p>La société WashMee / BGA CBA, soucieuse des droits des individus, notamment au regard des traitements automatisés, et dans une volonté de transparence avec ses clients, a mis en place une politique reprenant l’ensemble de ces traitements, des finalités poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus afin qu’ils puissent au mieux exercer leurs droits.
                        La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent. Vous acceptez l'utilisation de cookies et autres traceurs. En cas de refus, veuillez nous envoyer un courriel à info@washmee.fr.
            La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.</p>
                        <p><b>Article 1 - Mentions légales</b></p>

                        <p><b>1.1 Site (ci-après « le site ») : WashMee</b></p>

                        <p><b>1.2 Éditeur (ci-après « l'éditeur ») :</b></p>

                        <p>WASHMEE / BGA CBA SAS, au capital de 17 000€,
                        dont le siège social est situé: 39 rue Carnot, 92300 Levallois-Perret
                        représentée par Bertrand GERARD, en sa qualité de Président,
                        immatriculée au RCS de RCS NANTERRE 835 149 949,
                        n° de téléphone : +33 6 52 52 07 69,
                        adresse mail : info@washmee.fr,
            directeur de la publication : Christophe BARON.</p>

                        <p><b>1.3 Hébergeur (ci-après « l'hébergeur ») :</b></p>

                        <p>WashMee est hébergé par OVH, dont le siège social est situé 2 rue Kellermann, 59100 Roubaix.</p>


                        <p><b>Article 2 - Accès au site</b></p>

                        <p>L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurant à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l'envoi de courriers électroniques non sollicités.
                L’accès au site professionnel (rubrique « Pro ») et son utilisation sont réservés à un usage strictement professionnel après création d’un compte et d’un mot de passe.</p>

                        <p><b>Article 3 - Contenu du site</b></p>

                        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
            Ils sont la propriété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur, sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>

                        <p><b>Article 4 - Gestion du site</b></p>

                        <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :
<br />- suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internautes ;
<br />- supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette ;
<br />- suspendre le site afin de procéder à des mises à jour.</p>

                        <p><b>Article 5 - Responsabilités</b></p>

                        <p>La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.
                        Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet. Vous êtes par ailleurs seul responsable des sites et données que vous consultez.

                        L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
<br />- du fait de l'usage du site ou de tout service accessible via Internet ;
<br />- du fait du non-respect par vous des présentes conditions générales.

L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.
Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de votre utilisation du site, il pourra se retourner contre vous pour obtenir l'indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>

                        <p><b>Article 6 - Liens hypertextes</b></p>

La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l'éditeur. Tout lien devra être retiré sur simple demande de l'éditeur.
Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun droit sur le contenu présent dans ledit lien.

<p><b>Article 7 - Loi applicable</b></p>

Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux du siège social de l'éditeur, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.

<p><b>Article 8 - Contactez-nous</b></p>

Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez laisser envoyer un courriel à info@washmee.fr.
</div>
                    : <></>
            }
            {
                isCookie
                    ? <div style={{ padding: '3em' }}>
                        <h3>Déclarations relatives au cookies</h3>

                        <p>Date de modification : 04 mars 2019
<br />Date d'entrée en vigueur : 04 mars 2019
</p>


                        <p><b>Légal</b></p>
                        <p>Ce site utilise des cookies afin d'assurer sa sécurité et de vous proposer du contenu personnalisé. Les cookies nous permettent de comprendre comment les utilisateurs naviguent sur notre site et d'y apporter des améliorations. Nous pouvons employer des tiers pour placer des cookies sur le présent site Web afin de vous proposer des publicités sur d'autres sites Web. Ce document vous permettra d’en savoir plus sur notre utilisation des cookies, sur ces tiers et sur la procédure vous permettant de vous opposer à cette utilisation à des fins publicitaires. En poursuivant votre navigation sur ce site, vous acceptez notre utilisation des cookies.</p>

                        <h6><b>DÉCLARATION RELATIVE AUX COOKIES</b></h6>
                        <p>Date d'entrée en vigueur du règlement européen sur la protection des données personnelles : 25 mai 2018
WashMee, des tiers et d'autres partenaires utilisent des cookies et d'autres technologies d'identification sur leurs sites Web, sur leurs applications mobiles, dans leurs communications par e-mail, dans leurs publicités et sur d'autres services en ligne (appelés collectivement les « services ») à différentes fins, notamment pour : authentifier des utilisateurs, se souvenir des préférences et des paramètres des utilisateurs, établir la popularité d'un contenu, délivrer et mesurer l'efficacité des campagnes publicitaires, analyser le trafic du site et les tendances et, d'une manière générale, comprendre les comportements en ligne et les intérêts des personnes qui interagissent avec les services.</p>

                        <p>Aperçu des cookies et technologies associées
                        Les cookies sont de petits fichiers textes qui sont stockés dans votre navigateur ou votre appareil par les sites Web, les applications, les médias en ligne et les publicités, et utilisés pour mémoriser votre navigateur ou votre appareil durant et entre vos visites. Nous utilisons également d'autres technologies pouvant vous identifier ou identifier les appareils que vous utilisez. Par exemple, les « pixels espions » sont de petits blocs de code installés sur (ou appelés par) une page Web, une application ou une publicité qui peuvent récupérer certaines informations sur votre appareil ou votre navigateur, telles que : le type d'appareil, le système d'exploitation, le type et la version du navigateur, le site Web visité, l'heure de visite, le site Web référent, l'adresse IP, les identifiants de publicité et d'autres informations similaires comme le petit fichier texte (cookie) qui identifie l'appareil de façon unique. Les pixels fournissent les moyens par lesquels les tiers peuvent définir et lire des cookies HTTP à partir d'un domaine qu'ils n'exploitent pas eux-mêmes, et récolter des informations sur les visiteurs de ce domaine, en général avec la permission du propriétaire du domaine. Le « stockage local » fait généralement référence à un autre emplacement sur un navigateur ou un appareil, où les informations peuvent être stockées par des sites Web, des publicités ou des tiers (comme le stockage local HTML5 et le cache du navigateur). Les « kits de développement logiciel » (SDK) fonctionnent comme les pixels et les cookies, mais dans des contextes d'application mobile, ce qui n'est pas toujours le cas des pixels et des cookies.
                        Le développeur original de l'application peut installer des morceaux de code (SDK) de partenaires dans l'application et ainsi autoriser ces partenaires à récolter certaines informations sur les interactions avec les utilisateurs, ainsi que sur leur appareil et sur le réseau.
                        Synchronisation et pertinence des publicités
                        Afin de proposer des publicités aussi pertinentes que possible, WashMee travaille avec différents prestataires de services qui nous aident à fournir aux utilisateurs finaux des publicités similaires sur l'ensemble des appareils et plateformes. Par exemple, nous travaillons avec des services de publicité sur les réseaux sociaux afin de vous proposer des publicités pertinentes basées sur votre activité WashMee via leurs canaux. Nous pouvons également avoir recours à des prestataires de services pour vous proposer, sur un site Web mobile ou une application mobile, des publicités similaires à celles d'un site Web traditionnel. Vous trouverez ci-dessous les choix dont vous disposez pour limiter ces types de publicités.
</p>
                        <p><b>Vos choix</b></p>
                        <p>
                            Vous avez le droit de choisir d'accepter ou non les cookies. Cependant, vous devez savoir qu'ils jouent un rôle important dans le fonctionnement de nos services. Par conséquent, si vous refusez ou supprimez des cookies, cela pourrait affecter la disponibilité et le fonctionnement des services.
                            La plupart des navigateurs Web sont paramétrés pour accepter les cookies par défaut. Si vous le souhaitez, vous pouvez choisir de paramétrer votre navigateur pour supprimer ou rejeter des cookies. Pour cela, reportez-vous aux instructions fournies par votre navigateur, généralement disponibles dans le menu « Aide » ou « Préférences ». Certains tiers offrent également la possibilité de refuser leurs cookies directement en cliquant sur un lien de désinscription. Vous trouverez plus de détails sur cette possibilité dans le tableau ci-dessous.
                            Le fait de supprimer ou de refuser des cookies HTTP n'affecte pas nécessairement les cookies flash tiers, qui peuvent être utilisés par nous ou nos partenaires dans le cadre de nos services. Pour supprimer ou désactiver les cookies flash, rendez-vous sur ce site pour plus d'informations. Pour plus d'informations sur les cookies, et notamment pour connaître les cookies qui ont été installés sur votre appareil et savoir comment les gérer ou les supprimer, rendez-vous sur https://youradchoices.com/ et www.youronlinechoices.eu pour les visiteurs européens.
                            Pour les utilisateurs mobiles, certains paramètres du système d'exploitation de votre appareil vous permettent d'autoriser ou non les cookies et de partager votre identifiant de publicité avec des entreprises comme WashMee ou nos prestataires de services de publicité. Pour plus d'informations sur le paramétrage de vos choix sur votre appareil mobile, rendez-vous sur www.networkadvertising.org/mobile-choices.
Pour vous aider à contrôler ou bloquer certaines publicités dans vos applications mobiles, vous pouvez télécharger et utiliser l'application mobile DAA https://youradchoices.com/appchoices.</p>

                        <p><b>Types de cookies et fonctions</b>
                            <br />
Le tableau ci-dessous décrit les différentes catégories de cookies que nos Services utilisent et pourquoi nous les utilisons. La liste des fournisseurs de cookies tiers est fournie uniquement à titre indicatif et n’est pas exhaustive.
<br />
Types de cookies
<br />
Fonction
<br />
Qui les utilise (par exemple)
<br />
Cookies d’authentification
<br />
Ces cookies (y compris le stockage local et les technologies similaires) nous indiquent quand vous êtes connecté, ce qui nous permet de vous montrer les données et caractéristiques appropriées telles que les informations relatives à votre compte, l’historique des parcours et de corriger le paramétrage de votre compte.
WashMee
<br />
Cookies de sécurité et cookies relatifs à l’intégrité du site
<br />
Nous utilisons ces cookies pour supporter ou activer les fonctionnalités de sécurité afin d’aider WashMee à rester totalement sûr. Ils permettent par exemple de nous mémoriser quand vous vous êtes connecté dans une zone sûre des Services et de nous aider à protéger votre compte de tout accès par quelqu’un d’autre que vous.
<br />
WashMee
<br />
Localisation
<br />
Ces cookies aident WashMee à obtenir des données de localisation. Nous pouvons par exemple stocker des informations dans un cookie placé sur votre navigateur ou votre appareil de manière à ce que vous puissiez regarder le site dans la langue de votre choix.
WashMee
<br />
Fonctionnalités du site et Services
<br />
Ces cookies ont une fonctionnalité qui nous aide à fournir les produits et Services. Ils vous aident par exemple à vous connecter en pré-remplissant des champs. Nous pouvons également utiliser des cookies et des technologies similaires pour nous aider à vous fournir, ainsi qu’à d’autres, des modules sociaux et autres contenus et données personnalisé(e)s, comme vous faire des suggestions, ainsi qu’à d’autres.
WashMee
<br />
Facebook
<br />
Twitter
<br />
Google
<br />
Analyses et recherches
<br />
Ces cookies sont utilisés pour comprendre, améliorer et rechercher des produits et Services, y compris lorsque vous accéder au site internet de WashMee et applications associées à partir d’un ordinateur ou téléphone portable. Nous pouvons par exemple utiliser ces cookies pour comprendre la façon dont vous utilisez les fonctionnalités du site, et segmenter les publics pour des tests de page web. Nous pouvons et nos partenaires peuvent utiliser ces technologies et les informations que nous recevons afin d’améliorer les sites internet, les applications, les produits, les services et les messages publicitaires et comprendre la façon dont vous les utilisez.
WashMee
<br />
Google
<br />
Publicité
<br />
Nous utilisons notamment des cookies et pixels pour diffuser des messages publicitaires et suivre la performance et l’efficacité des campagnes publicitaires. Par exemple, nous pouvons et nos partenaires peuvent se servir d’informations collectées via ces cookies pour vous afficher des messages publicitaires susceptibles de vous intéresser sur d’autres sites internet. De la même manière, nos partenaires peuvent utiliser un cookie, un service d’attribution ou toute autre technologie similaire afin de savoir si nous avons affiché un message publicitaire, comment il a fonctionné ou nous fournir des informations sur la façon dont vous interagissez avec lui. Pour en savoir plus sur ces publicités ciblées, pour refuser que les informations de votre navigateur soient utilisées à des fins de publicité comportementale, veuillez visiter le site https://youronlinechoices.eu/ si vous résidez en dehors des États-Unis.
WashMee
<br />
Google
<br />
Facebook
<br />
Microsoft
<br />
Yahoo
<br />
Indeed
<br />
Twitter
</p>

Pour nous contacter
Si vous avez des questions au sujet de notre utilisation de cookies, envoyez-nous un courriel à info@washmee.fr pour nous poser vos questions.
                    </div>
                    : <></>
            }
            {
                isConditionVente
                    ? <div style={{ padding: '3em' }}>
                        <h3>Conditions générales de vente</h3>

                        <p>Date de modification : 04 mars 2019</p>
                        <p>Date d'entrée en vigueur : 04 mars 2019</p>

                        <p><b>1. CHAMP D’APPLICATION</b></p>

                        <p>Les présentes conditions générales de vente (ci-après « Conditions Générales de Vente » ou
                        « CGV ») régissent les conditions dans lesquelles tout client (ci-après le « Client ») achète un service ou un produit auprès d’un professionnel du lavage de véhicules via la société WashMee / BGA CBA, sur l’Application mobile WashMee (ci-après l’« Application »). WashMee / BGA CBA est une société par actions simplifiée au capital de 17 000 euros. Le siège social est situé au 39 rue Carnot, 92300 Levallois-Perret. Immatriculation au R.C.S. de Nanterre sous le n° 835 149 949 (ci-après la « Société »). Les laveurs de véhicules à domicile disposent d’une application spécifique WashMee Pro (ci-après l’« Application Professionnelle ») qui permet de recevoir, d’accepter ou de refuser les commandes de lavages de véhicules à domicile.</p>

                        <p>L’utilisation de l’Application, de l’Application Professionnelle ou du Site et l’achat auprès de la Société par le Client emporte acceptation des CGU des services proposés par WashMee et des présentes CGV.</p>

                        <p>Les présentes CGV sont en permanence accessibles sur l’Application, l’Application Professionnelle et le Site.</p>

                        <p>Le Client accepte que le courrier électronique soit le mode de communication privilégié de la relation commerciale.</p>

                        <p>Toute commande sur l’Application implique l’acceptation sans réserve des présentes CGV. En cas de désaccord avec les termes de ces CGV, aucune commande ne pourra être acceptée.</p>

                        <p><b>2. DEFINITIONS</b></p>

                        <p>- Application : L’application mobile WashMee par laquelle la Société fournit des informations et le cas échéant, propose des offres de produits ou de prestations relatifs au lavage de véhicules fournies par ses Partenaires.
                        <br />- Application Professionnelle : L’application mobile professionnelle WashMee Pro est destinée aux professionnels du lavage de véhicules à domicile. Les informations y figurant sont confidentielles et accessibles par les professionnels du lavage de véhicules à domicile au moyen d’un identifiant et d’un mot de passe.
                        <br />- Avis Clients : notes et commentaires laissés par les Utilisateurs de l’Application. La création d’un Compte client et la réalisation d’un achat sur l’Application sont indispensables pour laisser une note ou commentaire.
                        <br /> - Centre de lavage : Le lieu où l’Utilisateur de l’Application peut bénéficier d’un lavage de véhicule sur site dans le cadre de services proposés par un Partenaire.
                        <br /> - Client : tout Utilisateur de l’Application qui procède à un acte d’achat via l’Application.
                        <br /> Un Client dispose d’un Compte Client sur l’Application.
                        <br /> - Client Professionnel : tout Utilisateur de l’Application Professionnelle ou du Site qui procède à un acte de vente via l’Application.
                        <br /> Un Client Professionnel dispose d’un Compte Client sur le Site et/ou sur l’Application Professionnelle.
                        <br /> - Code lavage : code sécurisé unique généré pour le Client dans l’Application, à usage unique, afin de lui permettre d’obtenir un lavage de véhicule sur site dans le Centre de lavage de véhicules choisi.
                        <br /> - Compte client : le compte personnel, sur l’Application, l’Application Professionnelle ou le Site, du Client ou Client Professionnel, lui permettant d’accéder au service d’achat (ou vente pour les Professionnels du lavage de véhicules) de lavage de véhicules sur site sur l’Application ou l’Application professionnelle.
                        <br />- FastPass : La possibilité de réserver un créneau de lavage de véhicules sur une piste dédiée pour un lavage de véhicules sur site.
                        <br />- Lavage de véhicules sur site : Toutes prestations de lavage de véhicules proposées par les Partenaires dans leurs Centres de lavage de véhicules.
                        <br /> - Forfaits : Les professionnels du lavage de véhicules peuvent souscrire à différents Forfaits sur le Site afin de devenir Partenaire de la Société. Ces Forfaits sont payables mensuellement ou annuellement et permettent un référencement précis et individuel des solutions de lavage de véhicules.
                        <br />- Promotion : Le Partenaire peut, à son entière discrétion, créer des promotions pouvant être utilisées pour obtenir un avantage tarifaire sur les services proposés par le Partenaire. Ces Promotions sont visibles sur l’Application et l’Application Professionnelle.
                        <br /> - Partenaire : tout professionnel disposant d’un ou plusieurs Centres de lavage de véhicules, et ayant signé un contrat avec la Société en vue de son référencement sur l’Application, l’Application Professionnelle et le Site, et lui permettant de vendre via l’Application, des prestations de lavage de véhicules sur site aux Utilisateurs de l’Application.
                        <br /> - Non-partenaire : tout professionnel disposant d’un ou plusieurs centres de lavage de véhicules simplement répertorié(s) sur l’Application et le Site, sans avoir signé de contrat avec la Société.
                        <br /> - Réservation : la Société propose un service de prise de rendez-vous aux Utilisateurs de l’Application pour les prestations de lavage de véhicules à la main et de lavage de véhicules à domicile. La prise de rendez-vous se fait par un calendrier mis à disposition par la Société via l’Application dans certains centres de lavage de véhicules partenaires.
                        <br /> - Site : Le Site http://www.washmee.fr par lequel la Société fournit des informations relatives au lavage de véhicules fournies par ses Partenaires. Le Site dispose d’une interface de gestion complète pour les Partenaires ayant signé un contrat avec la Société. Cette interface permet d’ajouter, modifier ou supprimer des informations contenues dans l’Application et de créer des Comptes utilisateurs pour l’Application Professionnelle. L’interface de gestion permet aussi de communiquer vers les Utilisateurs et de créer des Promotions. Elle donne accès à plusieurs reportings d’activité.
                        <br /> - Utilisateur de l’Application : toute personne naviguant sur l’Application ou le Site afin de visualiser ses contenus (informations, localisation des Centres de lavage de véhicules des Partenaires, types de lavage de véhicules sur site et autres services annexes proposés, tarifs etc.).
</p>
                        <p><b>3. ACHAT DE PRESTATION DE LAVAGE DE VEHICULES SUR L’APPLICATION</b></p>

                        <p>3.1 Description des services de lavage de véhicules sur site vendus sur l’Application</p>

                        <p>3.1.1 Codes Lavages</p>

                        <p>La Société vend des services de lavage de véhicules sur site via l’Application. La Société ne rend pas elle-même de prestations de service de lavage de véhicules sur site. Les services de lavage de véhicules sur site sont présentés sur l’Application et le Site avec un descriptif informant le Client sur l’identité du prestataire de service (ci-après le « Partenaire »), de l’adresse du Centre de lavage de véhicules, ses coordonnées postales, téléphoniques et électroniques, les caractéristiques essentielles des services proposés et leur prix.
                        La Société propose au Client deux modes de navigation sur l’Application pour trouver la prestation de lavage de véhicules de son choix :</p>

                        <p>- Une navigation sur une carte : le Client est libre de choisir la station de lavage de véhicules de son choix proposant le service de paiement en ligne et le programme de son choix.
                        <br />- Une navigation sur une liste : le Client est libre de choisir la station de lavage de véhicules de son choix proposant le service de paiement en ligne et le programme de son choix.

                        Le Client obtient, dès validation de son achat, un Code lavage de véhicule à usage unique, cessible, lui permettant de se rendre dans le Centre de lavage de véhicules choisi afin de bénéficier du service de lavage de véhicules sur site acheté auprès de la Société.
                        Le Code de lavage de véhicule généré par l’Application permet principalement de :
                        <br />- soit récupérer dans le Centre de lavage de véhicules désigné des jetons de lavage / aspirateur
                        <br />- soit de démarrer directement un programme de lavage de véhicules

                        <br />Le Code lavage de véhicule sera utilisable sous un (1) an, et uniquement dans le Centre de lavage de véhicules désigné.
</p>
                        <p><b>3.1.2 Lavages de véhicules à la main</b></p>

                        <p>La Société vend des services de lavage de véhicules à la main sur site proposés par ses Partenaires via l’Application. La Société ne rend pas elle-même de prestations de service de lavage de véhicules à la main sur site. Les services de lavage de véhicules à la main sur site sont présentés sur l’Application et le Site avec un descriptif informant le Client sur l’identité du prestataire de service (ci-après le « Partenaire »), de l’adresse du Centre de lavage de véhicules, ses coordonnées postales, téléphoniques et électroniques, les caractéristiques essentielles des services proposés et leur prix.</p>

                        <p>La Société propose au Client deux modes de navigation sur l’Application pour trouver la prestation de lavage de véhicules à la main de son choix :</p>

                        <br />- Une navigation sur une carte : le Client est libre de choisir le Centre de lavage de véhicules à la main de son choix proposant le service de paiement en ligne et le programme de son choix.
                        <br />- Une navigation sur une liste : le Client est libre de choisir le Centre de lavage de véhicules à la main de son choix proposant le service de paiement en ligne et le programme de son choix.

                        <p>Le Client choisit une date de rendez-vous sur l’Application uniquement, grâce à un calendrier mis à disposition par la Société et connecté au calendrier du Partenaire. Le Client obtient, dès validation de son achat, une confirmation de son achat ainsi qu’un numéro de commande. Il pourra alors se rendre dans le Centre de lavage de véhicules à la main choisi, à la date et à l’heure sélectionnée afin de bénéficier du service de lavage de véhicules à la main sur site acheté auprès de la Société.

                        La Société ne peut, en aucun cas, être tenue pour responsable de la non-disponibilité de ses Partenaires (indisponibilité, annulation). Le Client a la possibilité de modifier ou annuler son rendez-vous auprès du Partenaire. Si le Client ne se présente pas à l’heure convenue pour le rendez-vous, sans avoir au-préalable annulé son rendez-vous, le lavage de son véhicule sera considéré comme réalisé et le client ne pourra pas re-planifier sa prestation.</p>

                        <p><b>3.1.3 Lavages de véhicules à domicile</b></p>

                        <p>La Société vend des services de lavage de véhicules à domicile via l’Application. La Société ne rend pas elle-même de prestations de service de lavage de véhicules à domicile. Les services de lavage de véhicules à domicile sont présentés sur l’Application avec un descriptif informant le Client sur l’identité du prestataire de service (ci-après le « Partenaire »), de sa disponibilité, des caractéristiques essentielles des services proposés et leur prix.</p>

                        <p>La Société propose au Client un mode de navigation sur l’Application pour trouver la prestation de lavage de véhicules à domicile de son choix :</p>

                        <br />- Une navigation sur une liste : le Client est libre de choisir le Partenaire laveur de véhicules à domicile de son choix et le programme de son choix. Tous les Partenaires laveurs de véhicule à domicile référencés dans l’Application proposent le service de paiement en ligne.
                        <p>Les résultats affichés sur l’Application prennent en compte les disponibilités des Partenaires laveurs de véhicule à domicile ainsi que leur rayon d’action.</p>

                        <p>Le Client choisit une date de rendez-vous sur l’Application uniquement, grâce à un calendrier mis à disposition par la Société et connecté au calendrier du Partenaire laveur de véhicules à domicile.
                        Une fois la commande validée par le Client, une préautorisation bancaire est réalisée sur le compte du Client.
                        Le Partenaire laveur de véhicules à domicile est averti par une notification Push ou SMS qu’il dispose de cinq (5) minutes pour accepter, refuser la prestation commandée ou proposer un autre rendez-vous.
                        Au terme de ces cinq (5) minutes, plusieurs possibilités :
                        <br />- Le Partenaire laveur de véhicule à domicile a accepté la Prestation commandée par le Client. Le Client est averti par une notification Push ou SMS que sa commande est validée. Le client obtient, dès validation de sa Prestation une confirmation de son achat ainsi qu’un numéro de commande et le numéro de téléphone du Partenaire laveur de véhicule à domicile. Le Partenaire laveur de véhicules à domicile averti alors le client via son Application Professionnelle qu’il part vers le lieu du rendez-vous, qu’il est arrivé puis que le lavage est terminé. A chaque étape, le Client reçoit un email, SMS ou notification Push. Quand le Partenaire laveur de véhicule à domicile déclare via l’Application Professionnelle qu’il a terminé le lavage du véhicule, le Client est informé que le débit de sa carte bancaire a eu lieu. Une facture est éditée et envoyée par email ou peut être demandée par le Client dans la Section Mes lavages de l’Application.
                        <br />- Le Partenaire laveur de véhicule à domicile a refusé la Prestation commandée. Le Client est averti par une notification Push ou SMS que sa Prestation ne pourra pas avoir lieu. Aucun débit n’est réalisé sur la carte bancaire du Client. Il est invité à choisir un autre Partenaire laveur de véhicule à domicile ou un autre créneau horaire.
                        <br /> - Le Partenaire laveur de véhicule à domicile n’a pas accepté ou refusé au bout de cinq (5) minutes la Prestation commandée par le Client. Le Client est averti par une notification Push ou SMS que sa Prestation ne pourra pas avoir lieu. Aucun débit n’est réalisé sur la carte bancaire du Client. Il est invité à choisir un autre Partenaire laveur de véhicule à domicile ou un autre créneau horaire.
                        <br /> - Le Partenaire laveur de véhicule à domicile a proposé un nouveau rendez-vous pour effectuer la Prestation commandée par le Client. Le Client est averti par une notification Push ou SMS que sa commande est validée mais qu’un nouveau créneau horaire est proposé par le Partenaire laveur de véhicules à domicile.
                        <br /> o Si le Client accepte la modification du rendez-vous, le Partenaire laveur de véhicules à domicile est averti par une notification Push ou SMS que le créneau horaire est modifié et qu’il devra se rendre à l’endroit prévu lors de la commande aux nouveaux horaires. Le Partenaire laveur de véhicules à domicile averti alors le client via son Application Professionnelle qu’il part vers le lieu du rendez-vous, qu’il est arrivé puis que le lavage est terminé. A chaque étape, le Client reçoit un email, SMS ou notification Push. Quand le Partenaire laveur de véhicule à domicile déclare via l’Application Professionnelle qu’il a terminé le lavage du véhicule, le Client est informé que le débit de sa carte bancaire a eu lieu. Une facture est éditée et envoyée par email ou peut être demandée par le Client dans la Section Mes lavages de l’Application.
                        <br /> o Si le Client ne répond pas (durée indéterminée) ou refuse la modification du rendez-vous, la Prestation commandée initialement par le Client est maintenue. Le Partenaire laveur de véhicules à domicile est averti par une notification Push ou SMS si le Client refuse la modification du rendez-vous mais n’est pas averti si le Client ne répond pas à sa demande. Le créneau horaire initial étant maintenu, le Partenaire laveur de véhicules à domicile est tenu de se rendre à l’endroit et horaires prévus lors de la commande initiale. Le Partenaire laveur de véhicules à domicile a néanmoins la possibilité d’annuler la prestation commandée via l’Application Professionnelle. Dans ce cas, le Client est averti par une notification Push ou SMS que sa Prestation ne pourra pas avoir lieu. Aucun débit n’est réalisé sur la carte bancaire du Client. Il est invité à choisir un autre Partenaire laveur de véhicule à domicile ou un autre créneau horaire.

                        La Société ne peut, en aucun cas, être tenue pour responsable de la non-disponibilité de ses Partenaires (indisponibilité, annulation). Le Client a la possibilité de modifier ou annuler son rendez-vous auprès du Partenaire. Si le Client ne se présente pas à l’heure convenue pour le rendez-vous ou ne donne pas ses clés au Partenaire laveur de véhicule à domicile dans le cas où un lavage intérieur est prévu, sans avoir au-préalable annulé son rendez-vous, le lavage de son véhicule sera considéré comme réalisé et le client ne pourra pas re-planifier sa prestation.</p>

                        <p><b>3.2 Commande</b></p>

                        <p><b>3.2.1. Création d’un Compte client</b></p>

                        <p>Tout achat de prestation de lavage de véhicules sur l’Application nécessite l’ouverture préalable d’un Compte client : il est en effet nécessaire que le Client communique les informations qui sont indispensables à la prise en compte de sa commande, à la facturation et à la réalisation du service. Ses informations sont collectées, traitées et conservées dans le respect des dispositions légales (pour plus d’information, cf. Politique de Confidentialité WashMee consultable à tout moment sur l’Application ou le Site).</p>

                        <p><b>3.2.2. Prix des services</b></p>

                        <p>Le prix du service payable à la Société est celui indiqué sur l’Application au moment de la commande. Le prix est indiqué en Euros toutes taxes comprises (TVA au taux en vigueur).</p>

                        <p><b>3.2.3. Modalités de paiement</b></p>

                        <p> Le paiement est dû à la Société. Il s’effectue directement via l’Application.

                        L’Application utilise un mode de paiement sécurisé. Le paiement s’effectue sur l’Application via MangoPay, société anonyme de droit luxembourgeois, au capital de 2.000.000 euros, dont le siège social est situé 59 Boulevard Royal, L-2449 Luxembourg et immatriculée au Registre du commerce et des sociétés luxembourgeois sous le numéro B173459, habilitée à exercer son activité en France en libre établissement, en qualité d’établissement de monnaie électronique agréé par la Commission de Surveillance du Secteur Financier, 110 route d’Arlon L- 1150 Luxembourg et dont les conditions générales d’utilisation sont soumises à l’acceptation de l’Utilisateur de l’Application lors de la création de son Compte client préalablement à tout paiement. Ces conditions générales d’utilisation sont également consultables à tout moment sur le site http://www.MangoPay.com. Les données bancaires du Client sont collectées uniquement par MangoPay pour les besoins du paiement et ne sont pas conservées par la Société.

                        Le Site utilise un mode de paiement sécurisé. Le paiement s’effectue sur le Site via le service Stripe Payment Europe, Ltd, dont le siège est basé au 7ème étage de Bower Warehouse, 211 Old Street, London EC1V 9NR, United Kingdom et dont les conditions générales d’utilisation sont soumises à l’acceptation du Client lors de la création de son Compte Client préalablement à tout paiement. Ces conditions générales d’utilisation sont également consultables à tout moment sur le site https://stripe.com).
                        Les données bancaires du Client sont communiquées à Stripe Payment Europe, Ltd pour les besoins du paiement et ne sont pas conservées par la Société.</p>

                        <p><b>3.2.4 Validation de la commande</b></p>

                        Le Contrat est conclu entre la Société et le Client au moment où le Client a lu et accepté les présentes CGV portées à sa connaissance au moment de la création de son compte Utilisateur, ce dont il atteste en cochant la case “J’accepte les conditions générales de vente“.
                        Une fois qu’une commande est validée et payée, celle-ci peut être modifiée ou annulée selon certaines conditions. Un courrier électronique de confirmation sera adressé au Client. Ce courrier électronique de confirmation comprend un reçu détaillé de la commande reprenant les coordonnées de la Société, la description du service acheté (type de lavage, adresse du Centre de lavage de véhicules, durée de validité du Code lavage de véhicule), le prix et l’adresse de facturation

                        <p><b>3.3. ÉVALUATION DES CENTRES DE LAVAGE DE VEHICULES</b></p>

                        L’Application met à la disposition des Clients des moyens leur permettant d’évaluer les Centres de lavage de véhicules et de formuler des commentaires. Après son achat le Client est invité à évaluer la performance du Centre de lavage de véhicules, par un système de notation de 1 à 5 étoiles (notation publiée sur l’Application) et de commentaires à destination du Partenaire chez qui il a réalisé son achat. La Société n’assure à ce titre aucune vérification des évaluations laissées par le Client, qu’elle se contente de stocker sur l’Application. Cette transparence permet aux Clients et Utilisateurs de l’Application et du Site de sélectionner des services auprès des Centres de lavage de véhicules les plus sérieux.

                        <p><b>3.4. DROIT DE RÉTRACTATION – VALABLE UNIQUEMENT POUR LES CLIENTS CONSOMMATEURS</b></p>

                        <p><b> 3.4.1. Champ d’application</b></p>
                        Tout Client consommateur (personne physique qui agit à des fins qui n’entrent pas dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole) dispose de différents délais de rétractation selon le type de Lavage acheté.

                        <p><b> 3.4.1.1 Lavage sur site avec achat de jetons ou de programmes via Code de lavage</b></p>
                        Le Client consommateur dispose d’un délai de un (1) an pour changer d’avis sur son achat à distance dans le cadre de l’achat de jetons ou de programmes de lavage sur site via un Code de lavage tant que ce Code de lavage n’a pas été généré par le Client. Le Client consommateur peut, dans l’année qui suit son achat de lavage de véhicule sur l’Application, exercer son droit de rétractation tant que le Code de lavage n’a pas été généré.

                        <p><b>2. Lavage à la main avec prise de rendez-vous ou Lavage à domicile avec prise de rendez-vous</b></p>
                        Le Client consommateur dispose d’un délai compris entre la commande du lavage sur l’Application et jusqu’à une (1) heure avant l’heure du rendez-vous programmé pour changer d’avis sur son achat à distance dans le cadre d’une commande de Lavage à la main avec prise de rendez-vous ou de Lavage à domicile avec prise de rendez-vous via l’Application et l’Agenda mis à disposition par l’Application.
                        A partir de cinquante-neuf (59) minutes cinquante-neuf (59) secondes avant le Lavage à la main avec prise de rendez-vous ou Lavage à domicile avec prise de rendez-vous, le Client pourra prétendre à un droit de rétractation mais sans remboursement. L’intégralité de la prestation commandée sera débitée et ne pourra en aucun cas être remboursée au Client.

                        <p><b>3.4.2. Modalités</b></p>
                        Préalablement à toute rétractation, le Client devra notifier son intention de se rétracter :

                        <br />soit directement par l’Application Section Mes lavages
                        <br />soit par tout moyen, exprimant sa volonté non équivoque de se rétracter, et mentionnant la commande concernée par cette rétractation à l’adresse suivante info@washmee.fr ou par courrier postal à l’adresse suivante :
                        <br /> Société WashMee — Service Client
                        <br />39 rue Carnot
                        <br /> 92300 Levallois-Perret
                        <br /> France



                        Le Client peut également utiliser le formulaire de rétractation ci-dessous :

                        <br />FORMULAIRE DE RÉTRACTATION

                        <br /> Je vous notifie par la présente ma rétractation du contrat portant sur la vente des produits identifiés ci-dessous :

                        <br /> Intitulé : … ……………………………………………………………………………

                        <br /> Référence (adresse du centre de lavage de véhicules, nom du programme de lavage de véhicule…) : …………………………………………………………………………………………………………..

                        <br /> Quantité : ………

                        <br />Commandé le / programmé le (*) : ……………………………/……………………………………

                        <br /> (*) Le cas échéant

                        <br /> Numéro de commande : ……………………………………………………………………….

                        <br /> Nom du client : …………………………………………………………………
                        <br /> ………………

                        <br /> Adresse du client : ……………………………………………………………………………….

                        <br />Signature du client : …………………………………………………………………………….

                        <br />(uniquement en cas de notification du présent formulaire sur papier)

                        <br />Date : ……………………………………………………………………………………………….

                        <p><b>3.4.3. Remboursement</b></p>
                        <p><b> 3.4.3.1 Codes lavage</b></p>
                        <p><b>3.4.3.2 Lavage de véhicules à la main</b></p>
                        <p><b>3.4.3.3 Lavage de véhicules à domicile</b></p>
                        <p><b>3.4.4.4 Fast Pass</b></p>

                        <br /> Tant que le Code de lavage de véhicule n’est pas généré dans l’Application par le Client, ce dernier a la possibilité d’annuler sa commande et d’obtenir son remboursement total.

                        <br />Le service de lavage de véhicules faisant l’objet d’une rétractation, s’il n’a pas été utilisé par le Client, donnera lieu à un remboursement complet, c’est à dire du prix d’achat du ou des service(s) acheté(s).

                        <br />Le remboursement du Client sera effectué par virement bancaire dans un délai de quatorze (14) jours suivant la date à laquelle la Société est informée de la décision de rétractation du Client consommateur et a bien les coordonnées bancaires valides du Client.

                        <br />Dans le cas d’une commande de Lavage de véhicule à la main avec prise de rendez-vous ou de Lavage à domicile avec prise de rendez-vous, le client dispose d’une (1) heure avant l’heure du rendez-vous planifié pour annuler sa commande et obtenir le remboursement intégral de sa prestation.
                        A partir de cinquante-neuf (59) minutes et cinquante-neuf (59) secondes jusqu’à l’heure du lavage programmée du véhicule, le Client pourra annuler la prestation commandée mais ne pourra pas obtenir de remboursement, même en cas de force majeure.

                        <p><b>3.5. RÉCLAMATION – GARANTIE</b></p>

                        Le Client bénéficie d’une garantie de conformité du service à la description figurant sur l’Application. En cas de non-conformité du Service, le Client pourra obtenir l’échange ou le remboursement de celui-ci. Conformément aux dispositions de l’article L216-2 du Code de la consommation, le Client consommateur peut demander l’annulation de sa commande lorsque le Centre de lavage de véhicules refuse de fournir le service ou lorsqu’il n’exécute pas son obligation de fourniture du service à la date ou à l’expiration du délai prévu. Toute réclamation doit être adressée au service clientèle à l’adresse suivante :

                        <br /> Société WashMee – Service Client
                        <br />39 rue Carnot
                        <br />92300 Levallois-Perret
                        <br />France

                        <p>SERVICE CLIENT</p>
                        Le Client peut à tout moment poser une question ou porter une réclamation auprès du responsable de l’Application et du Site :

                        <br /> – soit en téléphonant au numéro suivant : +336 52 52 07 69

                        Nos conseillers sont à votre disposition du lundi au vendredi de 9h à 18h.

                        <br /> – soit en adressant une demande écrite par e-mail à l’adresse info@washmee.fr

                        <br />– soit par courrier postal à l’adresse suivante :

                        <br /> Société WashMee – Service Client
                        <br />39 rue Carnot
                        <br />92300 Levallois-Perret
                        <br />France

                        Pour toute question relative aux Conditions Générales d’Utilisation de MangoPay, veuillez contacter MangoPay conformément aux CGU de MangoPay consultables sur le site de MangoPay.

                        Pour toute question relative aux Conditions Générales d’Utilisation de Stripe, veuillez contacter Stripe conformément aux CGU de Stripe consultables sur le site de Stripe.

                        <p>FORCE MAJEURE</p>
                        Aucune partie ne pourra être tenue pour responsable vis-à-vis de l’autre par suite de manquement, retard ou omission dans l’exécution totale ou partielle d’une commande, pour autant que cette défaillance ait pour origine ou résulte d’un évènement échappant raisonnablement au contrôle de la partie concernée.

                        La force majeure ne libère de ses obligations contractuelles la partie qui l’invoque que dans la mesure et pendant le temps où elle est empêchée de les exécuter, à moins que le retard qui en résulterait ne justifie la résolution de la commande. Si l’empêchement est définitif, la commande est résolue de plein droit et les parties sont libérées de leurs obligations

                        En tout état de cause, un retard ou défaut de paiement ne peut s’analyser comme un cas de force majeure.



                        <p> LOI APPLICABLE ET TRIBUNAUX COMPÉTENTS</p>
                        L’Application, l’Application Professionnelle et le Site sont édités par une société française et s’adresse à des Clients situés principalement sur le territoire français.

                        Les Conditions Générales d’Utilisation de l’Application, de l’Application Professionnelle et du Site sont soumises au droit français, sauf dispositions d’ordre public contraires dont pourrait bénéficier un Client ayant la qualité de consommateur.

                        En tout état de cause, tout Client a la possibilité de recourir, en cas de réclamation écrite non résolue, à une procédure de médiation ou à tout autre mode alternatif de règlement des différends. Plus particulièrement, le Client ayant la qualité de consommateur pourra recourir gratuitement à une procédure de médiation auprès du médiateur de la consommation.

                        La Commission Européenne met à disposition des consommateurs une plateforme en ligne de résolution des différends à laquelle vous pouvez accéder directement en cliquant sur le lien : http://ec.europa.eu/consumers/odr/.

                        En cas de litige non résolu, les tribunaux français seront compétents, sauf dispositions d’ordre public contraires prévoyant la compétence d’une autre juridiction.
                    </div>
                    : <></>

            }
            {
                isPolitiqueConfidentialite
                    ? <div style={{ padding: '3em' }}>
                        <h3>Politique de confidentialité</h3>

                        <p>Date de modification : 04 mars 2019</p>
                        <p>Date d'entrée en vigueur : 04 mars 2019</p>

                        <p><b>Introduction</b></p>
                        <p>Lorsque vous utilisez les Applications mobiles WashMee et WashMee Pro et le site Web WashMee, vous nous confiez vos informations. Nous nous engageons à faire honneur à votre confiance. Vous trouverez ci-après nos pratiques en matière de confidentialité.
                        La présente politique décrit les informations que nous recueillons, la manière dont elles sont utilisées et partagées, et vos choix à cet égard. Nous vous conseillons de lire ce texte pour connaître les aspects essentiels de nos pratiques en matière de confidentialité (notamment les informations que nous recueillons, les cas dans lesquels nous les recueillons et la façon dont nous les utilisons).
                        Collecte et utilisation des données
                        Portée</p>
                        <p><b>RÉSUMÉ</b></p>
                        <p>Cette politique s'applique aux utilisateurs des services WashMee partout dans le monde (utilisateurs des Applications mobiles WashMee et WashMee Pro et site Web WashMee).
                        La présente politique décrit la façon dont WashMee recueille et utilise vos informations personnelles pour vous fournir ses services. Elle s'applique à tous les utilisateurs de nos Applications mobiles et site Web partout dans le monde. ​Cette politique concerne spécifiquement ces utilisateurs :
                        <br />▪ Usagers : les utilisateurs qui commandent tout type de prestation de lavage de véhicule via l’Application mobile WashMee (immédiat ou différé).
                        <br /> ▪ Laveurs de véhicules à domicile (washers) : les utilisateurs qui fournissent un service de lavage de véhicules à domicile de façon indépendante ou par l'intermédiaire d'une société de lavage de véhicules via l’Application mobile WashMee Pro.
                        <br />▪ Professionnels du lavage de véhicules : les utilisateurs qui s’inscrivent sur le site Web professionnel de WashMee.
                        La présente politique s'applique également aux personnes qui fournissent des informations à WashMee dans le cadre d'une application mobile ou du site Web qui utilise nos services. Toutes les personnes soumises à cette politique sont désignées par le terme « utilisateurs » dans le cadre de la présente.
                        Les pratiques décrites dans cette politique sont soumises aux lois en vigueur dans les pays dans lesquels nous opérons. Cela signifie que nous appliquons les pratiques décrites dans la présente politique dans un pays ou une région donnés, uniquement si la loi locale l'autorise. Contactez-nous si vous avez des questions concernant nos pratiques dans votre pays ou votre région.
                        Responsable du traitement des données
                        La société WashMee (BGA CBA) est responsable du traitement des données personnelles.
                        Responsable du traitement des données : Christophe BARON</p>

                        <p>Contact :

                        <br />Adresse : 39 rue Carnot, 92300 Levallois-Perret

                        <br /> e-mail : info@washmee.fr

                        <br /> Téléphone : +33 6 52 52 07 69
                        <br /> Informations que nous recueillons</p>
                        <p> <b>RÉSUMÉ</b></p>
                        <p>WashMee recueille :
                        <br /> ▪ Les informations que vous fournissez à WashMee, lorsque vous créez votre compte WashMee par exemple.
                        <br /> ▪ Les informations créées lorsque vous utilisez nos services, comme celles relatives à la localisation, aux commandes et à l'utilisation et à l'appareil.
                        Les informations suivantes sont collectées par WashMee ou en son nom :
                        <br /> 1. Informations fournies par l'utilisateur
                        Cela peut inclure :
                        <br /> ▪ Le profil utilisateur : nous collectons des informations lorsque vous créez ou mettez à jour votre compte WashMee. Cela peut comprendre votre nom, votre prénom, votre adresse e-mail, votre numéro de téléphone, votre mot de passe, votre adresse, vos informations de paiement ou coordonnées bancaires (y compris les informations de vérification des paiements qui y sont liées), et votre photo. Cela comprend également les informations relatives à votre véhicule ainsi que les préférences et paramètres que vous activez pour votre compte WashMee.
                        <br /> ▪ Le profil « Professionnel du lavage de véhicules » : nous collectons des informations lorsque vous créez ou mettez à jour votre compte WashMee Pro (Application mobile WashMee Pro pour les laveurs de véhicules à domicile (washers) et site Web WashMee pour tous les professionnels du lavage de véhicules). Cela peut comprendre votre nom, votre prénom, votre adresse e-mail, votre numéro de téléphone, votre mot de passe, votre adresse, vos informations de facturation ou coordonnées bancaires (y compris les informations de vérification des paiements qui y sont liées), vos numéros d'identification officiels comme votre Kbis, votre pièce d’identité en cours de validité, et votre photo. Cela inclut également les préférences et paramètres que vous activez pour votre compte WashMee Pro.
                        <br /> ▪ Les données démographiques : nous pouvons recueillir des informations démographiques vous concernant, notamment par l'intermédiaire d'enquêtes. Dans certains pays, il se peut que nous recevions également des données démographiques vous concernant de la part de tierces parties.
                        <br />  ▪ Le contenu fourni par l'utilisateur : nous pouvons recueillir les informations que vous fournissez lorsque vous contactez l'assistance client WashMee, notez ou complimentez des professionnels du lavage de véhicules et/ou laveurs de véhicules à domicile (washers), ou lorsque vous contactez WashMee par un autre moyen.
                        <br /> 2. Informations générées par l'utilisation de nos services



                        <br /> Cela peut inclure :

                        <br />  ▪ Les données de localisation :
                        En fonction des autorisations de votre appareil, WashMee peut collecter des informations sur votre localisation précise ou approximative, selon les données GPS, l'adresse IP et le Wi-Fi.
                        <br />  ▪ Laveurs de véhicules à domicile (washers) : WashMee recueille des informations concernant votre localisation, que l'Application mobile WashMee Pro s'exécute sur votre appareil au premier plan (application ouverte et affichée à l'écran) ou en tâche de fond (application ouverte mais pas affichée à l'écran).
                        <br /> ▪ Si vous êtes un utilisateur et que vous avez autorisé le traitement des données de localisation, WashMee collecte vos données de localisation lorsque l'Application mobile WashMee s'exécute au premier plan. Dans certaines régions, WashMee collecte également ces informations lorsque l'Application mobile WashMee s'exécute en tâche de fond sur votre appareil si les autorisations de l'appareil le permettent.
                        <br /> ▪ Les utilisateurs peuvent utiliser l'Application mobile WashMee sans autoriser WashMee à recueillir leurs informations de localisation. Cela peut néanmoins affecter les fonctionnalités disponibles dans l'Application mobile WashMee. Par exemple, si vous n'autorisez pas WashMee à recueillir vos informations de localisation, vous devrez saisir manuellement votre adresse pour une recherche de professionnel de lavage de véhicules à proximité de cette adresse.
                        <br /> ▪ Les informations sur les transactions :
                         Nous recueillons les détails des transactions associés à votre utilisation de nos services, y compris le type de services demandés ou fournis, les détails des commandes, les informations de localisation, l'heure et la date de prestation du service, le montant facturé, et le moyen de paiement utilisé.
                        <br /> ▪ Les informations concernant l'utilisation :
                        Nous collectons des informations sur la façon dont vous interagissez avec nos services. Cela comprend des informations comme les dates et heures d'accès, les fonctionnalités des Applications utilisées ou les pages consultées, les pannes des Applications et autres activités du système, le type de navigateur utilisé et les sites ou les services tiers que vous utilisiez avant d'avoir recours à nos services.
                        Dans certains cas, nous recueillons ces informations par l'intermédiaire de cookies ou de techniques similaires qui créent et conservent des identifiants uniques. Pour en savoir plus sur les techniques utilisées, consultez notre Déclaration relative aux cookies.
                        <br /> ▪ Les informations sur l'appareil :
                        Nous pouvons recueillir des informations au sujet des appareils que vous utilisez pour accéder à nos services, y compris les modèles de matériel, l'adresse IP des appareils, les systèmes d'exploitation et leurs versions, les logiciels, les noms de fichiers et leurs versions, les langues préférées, les identifiants uniques des appareils, les identifiants publicitaires, les numéros de série, les informations concernant les déplacements des appareils et les informations relatives au réseau mobile.
                        <br /> ▪ Les données des communications
                        Les utilisateurs peuvent communiquer avec les professionnels du lavage de véhicules, dont les laveurs de véhicules à domicile (washers), à l'aide des Applications mobiles et du site Web WashMee. Par exemple, nous permettons aux utilisateurs et aux laveurs de véhicules à domicile (washers), de s'appeler ou de s'envoyer des SMS. Pour fournir ce service, WashMee reçoit certaines informations concernant les appels ou les SMS, notamment la date et l'heure de l'appel ou de l'envoi. WashMee peut également utiliser ces informations pour des services d'assistance au client (notamment pour résoudre les litiges entre utilisateurs), pour des questions de sécurité, pour améliorer la qualité de ses produits et services et à des fins d'analyse.
                        <br /> 3. Informations provenant d'autres sources
                        <br /> Cela peut inclure :
                        <br /> ▪ Les commentaires des utilisateurs, notamment les notes ou les compliments.
                        <br /> ▪ Les utilisateurs ou tierces personnes qui fournissent des informations dans le cadre de réclamations ou de litiges.
                        <br /> ▪ Les partenaires commerciaux de WashMee qui servent d'intermédiaires pour l'accès à votre compte WashMee ou sa création, par exemple les prestataires de services de paiement et les services de réseaux sociaux.
                        <br /> ▪ Les compagnies d'assurance (si vous êtes laveur à domicile (washer)).
                        <br /> ▪ Des sources publiques.
                        <br /> ▪ Les fournisseurs de services marketing.
                        WashMee peut combiner les informations collectées à partir de ces sources avec les informations déjà en sa possession.
</p>
                        Comment nous utilisons vos informations
                      <p>  <b>RÉSUMÉ</b></p>
                        WashMee recueille et utilise des informations pour que vous puissiez profiter de services de lavage de véhicules pratiques et fiables. Nous utilisons également les informations que nous recueillons aux fins suivantes :
                        <br /> ▪ pour améliorer la sécurité de nos utilisateurs et de nos services
                        <br />  ▪ pour l'assistance client
                        <br />  ▪ pour la recherche et le développement
                        <br />  ▪ pour permettre la communication à destination des utilisateurs ou entre utilisateurs
                        <br />  ▪ pour organiser des promotions
                        <br />  ▪ en relation avec les procédures judiciaires
                        WashMee ne vend pas et ne partage pas vos informations personnelles avec des tiers à des fins de marketing direct.
                        WashMee utilise les informations collectées à des fins telles que :
                        <br />  1. Fourniture de services et de fonctionnalités
                        WashMee utilise les informations que nous collectons pour fournir, personnaliser, tenir à jour et améliorer nos produits et services. Les informations sont notamment utilisées pour :
                        <br />  ▪ Créer votre compte et le mettre à jour.
                        <br />  ▪ Vérifier votre identité.
                        <br />  ▪ Traiter ou faciliter le paiement des services de lavage de véhicules.
                        <br />  ▪ Suivre la progression de votre achat ou de votre lavage de véhicules.
                        <br />  ▪ Activer des fonctions qui vous permettent de partager des informations avec d'autres personnes, comme lorsque vous envoyez un compliment au sujet d'un professionnel du lavage de véhicules, recommandez WashMee à une personne de votre entourage.
                        <br />   ▪ Effectuer les opérations internes nécessaires pour fournir nos services, y compris pour résoudre des bugs logiciels et des problèmes opérationnels, procéder à des analyses de données, des tests et des recherches, ainsi que pour surveiller et analyser les tendances en matière d'activité et d'utilisation.
                        <br />   2. Sécurité
                        Nous utilisons vos données pour promouvoir la sécurité et l'intégrité de nos services et de nos utilisateurs. Nous sommes par exemple susceptibles de recourir aux éléments suivants :
                        <br />  ▪ Utilisation des informations relatives aux appareils, à la position géographique, au profil, à l'utilisation et d'autres données pour prévenir, détecter et lutter contre toute activité frauduleuse ou dangereuse. Cela comprend le traitement de ces informations, dans certains pays, pour identifier des pratiques ou des tendances révélatrices de fraude ou de risques d'incidents de sécurité. Il peut également s'agir d'informations provenant de tiers. Dans certains cas, ces incidents peuvent entraîner la désactivation du compte à l'issue d'un processus de prise de décision automatique. Les utilisateurs situés dans l'Union européenne ont le droit de s'opposer à ce type de processus. Voir Section « Informations spéciales pour les utilisateurs au sein de l'U.E. » paragraphe 1.d pour plus d'informations.
                        <br />  ▪ Utilisation des notes des utilisateurs pour encourager les professionnels du lavage de véhicules, dont les laveurs de véhicules à domicile (washers), concernés à améliorer leurs prestations.
                        <br />  3. Assistance client
                        WashMee utilise les informations que nous collectons pour vous aider lorsque vous contactez les services d'assistance à la clientèle, notamment aux fins suivantes :
                        <br />  ▪ Transmettre vos questions à la personne compétente du service d'assistance à la clientèle
                        <br />  ▪ Examiner et traiter vos réclamations
                        <br />  ▪ Surveiller et améliorer les réponses de notre service d'assistance à la clientèle
                        <br />  4. Recherche et développement
                        Nous pouvons être amenés à utiliser les informations que nous collectons à des fins de test, de recherche, d'analyse et de développement de produit. Cela nous permet d'améliorer la sécurité de nos services, de développer de nouvelles fonctionnalités et de nouveaux produits, et de proposer des solutions d'assurance et de financement en lien avec nos services.
                        <br />  5. Communications entre utilisateurs
                        WashMee utilise les informations que nous collectons pour faciliter les communications entre nos utilisateurs. Par exemple, un laveur de véhicules à domicile (washer) peut envoyer un SMS à un utilisateur ou lui téléphoner pour confirmer le lieu de lavage du véhicule, ou bien le prévenir qu’il est sur place, qu’il aura du retard… A l’inverse, l’utilisateur peut contacter le laveur de véhicules à domicile (washer), une fois que la commande a été validée par ce dernier, afin de lui faire part d’un retard, d’un changement de position…
                        <br />  6. Communications de la part de WashMee
                        WashMee est susceptible d'utiliser les informations que nous collectons pour communiquer avec vous au sujet des produits, services, promotions, études, enquêtes, nouveautés, actualités et événements.
                        <br />  7. Procédures judiciaires et exigences légales
                        Nous pouvons utiliser les informations que nous collectons pour examiner ou traiter des réclamations ou litiges en lien avec votre utilisation des services WashMee ou pour tout autre cas autorisé par la loi applicable ou sur demande d'un organisme de réglementation, d'une administration ou dans le cadre d'une enquête officielle. Vous pouvez consulter nos Directives relatives aux demandes émanant des autorités judiciaires en cliquant ici.
                        Cookies et technologies tierces
                        <p><b>RÉSUMÉ</b></p>
                        <br />  WashMee et ses partenaires utilisent des cookies et d'autres technologies d'identification sur ses Applications mobiles, sites Internet, e-mails et publicités en ligne aux fins décrites dans ce règlement.
                        Les cookies sont de petits fichiers texte stockés dans votre navigateur ou votre appareil par les sites Internet, les applications mobiles, les médias en ligne et les publicités. WashMee utilise les cookies et des technologies similaires notamment aux fins suivantes :
                        <br />  ▪ Authentification des utilisateurs
                        <br />  ▪ Enregistrement des préférences et paramètres utilisateurs
                        <br />  ▪ Évaluation de la popularité d'un contenu
                        <br />  ▪ Amélioration et mesure de l'efficacité des campagnes publicitaires
                        <br />  ▪ Analyse des tendances et de la fréquentation du site, et compréhension globale des comportements en ligne et des centres d'intérêt des personnes qui interagissent avec nos services Nous pouvons également autoriser des tiers à fournir des mesures d'audience et des services d'analyse pour notre compte, à diffuser des publicités en notre nom sur Internet et à effectuer le suivi et le compte-rendu des performances desdites publicités. Ces organisations peuvent utiliser des cookies, des balises web, des kits de développement logiciel et d'autres technologies pour identifier votre appareil lorsque vous consultez notre site et utilisez nos services, ainsi que lorsque vous naviguez sur d'autres sites et services en ligne. Consultez notre Déclaration relative aux cookies pour de plus amples informations concernant l'utilisation des cookies et autres technologies décrites dans cette partie, notamment en ce qui concerne les différents choix desdites technologies.
                        Partage et divulgation d'informations
                       <p> <b>RÉSUMÉ</b></p>
                        Certains des produits, services et fonctionnalités de WashMee nécessitent que nous transmettions des informations à d'autres utilisateurs ou à votre demande. Nous pouvons également transmettre vos informations à nos filiales, nos succursales et nos partenaires commerciaux pour des raisons juridiques ou en lien avec une réclamation ou un litige.
                        WashMee peut communiquer les informations recueillies :
                        <br />   1. À d'autres utilisateurs
                        <br />   ▪ Par exemple, si vous êtes utilisateur, nous pouvons communiquer aux laveurs de véhicules à domicile (washers) votre prénom, nom, lieu de lavage du véhicule demandé dans le cadre d’un lavage de véhicules à domicile, la marque et le modèle de votre véhicule, sa couleur, son immatriculation que vous aurez renseignés préalablement dans l’Application mobile WashMee.
                        <br />  ▪ Si vous êtes laveur de véhicules à domicile (washer), nous pouvons communiquer des informations à votre client, notamment votre prénom, nom, votre photo, la note moyenne que les utilisateurs vous attribuent, leurs commentaires, le nombre de lavages de véhicules que vous avez effectués, ainsi que votre numéro de téléphone (une fois que la commande envoyée par l’utilisateur a été validée par vos soins). Si vous choisissez de remplir un profil de laveur de véhicules à domicile (washer) sur le site Web WashMee, nous pouvons également communiquer les informations associées à ce profil, notamment celles que vous envoyez et les compliments que les anciens utilisateurs ont formulés à votre égard. L’utilisateur recevra également un reçu dans lequel figureront notamment la répartition des sommes facturées, votre prénom et le récapitulatif du lavage du véhicule effectué.
                        <br />  2. Au grand public, lorsque vous publiez du contenu sur un forum public
                        Nous apprécions les commentaires de nos utilisateurs, y compris sur les forums publics tels que les réseaux sociaux et certaines fonctionnalités de notre réseau. Lorsque vous communiquez avec nous par le biais de ces canaux, vos publications peuvent être consultées par tout le monde.
                        <br />  3. Aux prestataires de services et partenaires commerciaux de WashMee
                        WashMee peut fournir des informations à ses fournisseurs, consultants, partenaires marketing, entreprises de recherche et autres prestataires de services ou partenaires commerciaux. Il peut s'agir, par exemple, des entités suivantes :
                        <br />  ▪ services de traitement des paiements et sociétés de financement,
                        <br />   ▪ fournisseurs de stockage dans le cloud
                        <br />   ▪ partenaires commerciaux et fournisseurs de plateformes marketing
                        <br />   ▪ fournisseurs de services d'analyses de données
                        <br />   ▪ partenaires de recherche, y compris les organismes réalisant des enquêtes ou projets de recherche en partenariat avec WashMee ou pour le compte de WashMee
                        <br />   ▪ fournisseurs assistant WashMee pour améliorer la sécurité de ses Applications mobiles
                        <br />   ▪ consultants, avocats, comptables et autres prestataires de services professionnels
                        <br />   ▪ partenaires d'assurance et de financement
                        <br />   ▪ fournisseurs de solutions pour les véhicules ou fournisseurs de véhicules tiers.
                        <br />   4. Pour des motifs juridiques ou en cas de litige
                        WashMee pourra communiquer vos informations si nous estimons que cela est requis par la législation, la réglementation, un contrat d'exploitation, une procédure judiciaire ou une demande officielle en vigueur, ou si la divulgation de vos données est autrement appropriée pour des raisons de sécurité ou des motifs similaires.
                        Nous pouvons ainsi communiquer vos informations aux autorités chargées de l'application de la loi, aux autorités gouvernementales, aux aéroports (sur demande des autorités de l'aéroport lorsque cela est requis pour une activité sur le site aéroportuaire), ou à d'autres tierces parties en fonction des exigences liées à nos Conditions d'Utilisation, contrats utilisateurs ou autres politiques, pour protéger les droits ou la propriété de WashMee, préserver la sécurité d'autres personnes ou en cas de réclamation ou de litige en rapport avec votre utilisation de nos services. Si vous utilisez la carte de crédit d'une autre personne, nous pouvons être légalement tenus de communiquer vos informations au titulaire de ladite carte, y compris les données relatives à vos paiements.
                        Nous pourrons également être amenés à communiquer vos informations à d'autres personnes concernées ou dans le cadre de négociations relatives à une fusion, une vente d'actifs de l'entreprise, un regroupement ou une restructuration, un financement ou l'acquisition de tout ou partie de notre entreprise par une autre entreprise.
                        Veuillez consulter nos directives relatives aux demandes émanant des autorités judiciaires pour de plus amples informations.
                        <br />   5. Avec votre consentement
                        WashMee pourra communiquer vos informations selon d'autres modalités que celles décrites dans la présente politique après vous en avoir informé et avoir reçu votre consentement.
                        Conservation et suppression des informations
                        <p> <b> RÉSUMÉ</b></p>
                        <p> WashMee conserve des informations de profil utilisateur ainsi que d'autres informations tant que vous utilisez votre compte WashMee.
                        WashMee conserve des informations relatives aux transactions, à la localisation et à l'utilisation ainsi que d'autres informations pendant sept ans, conformément aux exigences fiscales et en matière de réglementation et d'assurance, ou autres exigences en vigueur dans les endroits où WashMee opère. WashMee supprime ou anonymise ensuite ces informations conformément aux lois en vigueur.
                        Les utilisateurs peuvent demander la suppression de leur compte à tout moment. Suite à cette demande, WashMee supprime les informations qu'il n'est pas nécessaire de conserver et limite l'accès aux informations à conserver, ainsi que leur utilisation.
                        Pour proposer ses services, WashMee a besoin d'informations de profil utilisateur que nous conservons tant que vous utilisez un compte WashMee.
                        WashMee conserve des informations relatives aux transactions, à la localisation, aux appareils et à l'utilisation ainsi que d'autres informations pendant au moins sept ans, conformément aux exigences fiscales et en matière de réglementation et d'assurance, ainsi qu'aux autres exigences en vigueur dans les endroits où WashMee opère. Lorsque ces informations ne sont plus requises pour fournir les services WashMee, assurer le service d'assistance, améliorer l'expérience utilisateur ou poursuivre d'autres objectifs opérationnels, WashMee prend des mesures pour empêcher l'accès à ces informations ou leur utilisation à toutes fins autres que le respect de ces exigences ou pour des raisons de sécurité, ou à des fins de détection et de prévention de la fraude.
                        Vous pouvez demander la suppression de votre compte à tout moment via le menu Mon Compte de l'Application mobile WashMee ou le site Web de WashMee pour les professionnels du lavage de véhicules.
                        Suite à cette demande, WashMee procède à la suppression des informations qu'il n'est pas nécessaire de conserver. Dans certaines circonstances, WashMee peut se voir dans l'impossibilité de supprimer votre compte, par exemple s'il existe un crédit impayé sur votre compte, ou une réclamation ou un litige non résolus. Une fois ce problème résolu, WashMee supprimera votre compte comme indiqué ci-dessus.
                        WashMee peut également conserver certaines informations si nécessaire pour servir ses intérêts légitimes, comme pour prévenir la fraude et améliorer la sécurité des utilisateurs. Par exemple, si WashMee ferme le compte d'un utilisateur parce qu'il a un comportement abusif dans ses commentaires notamment, WashMee peut conserver certaines informations au sujet de ce compte afin d'éviter que cet utilisateur en ouvre un autre par la suite.
                        Informations spéciales pour les utilisateurs au sein de l'U.E.</p>
                        <p> <b>RÉSUMÉ</b></p>
                        Depuis le 25 mai 2018, le traitement des données personnelles des utilisateurs au sein de l'Union européenne est soumis au Règlement Général sur la Protection des Données (RGPD) de l'Union Européenne.
                        Cette section résume les motifs pour lesquels WashMee peut traiter des informations personnelles dans le cadre du RGPD, ainsi que les droits des utilisateurs dans l'U.E. concernant le traitement de leurs informations personnelles par WashMee.
                        Depuis le 25 mai 2018, le traitement des données personnelles des utilisateurs au sein de l'Union Européenne est soumis au EU General Data Protection Regulation ('GDPR') (Règlement Général sur la Protection des Données). La présente section contient des informations relatives aux droits des utilisateurs au sein de l'U.E. et aux responsabilités de WashMee en vertu de ce règlement.

                        <br />  1. Droits des utilisateurs au sein de l'U.E.
                        Si vous utilisez WashMee dans l'Union Européenne, vous jouissez des droits suivants concernant le traitement de vos données personnelles par WashMee. Pour exercer ces droits, veuillez consulter les données ci-dessous ou envoyer votre demande à info@washmee.fr.
                        Les utilisateurs en dehors de l'U.E. peuvent également demander le détail, la rectification ou la suppression de leurs données personnelles ou des copies de ces données en envoyant un courriel à info@washmee.fr.

                        <br />   a. Détail et copies de vos données
                        <br />   ▪ Vous avez le droit de demander à connaître les informations dont WashMee dispose vous concernant, ainsi que son utilisation de ces informations.
                       <br />> ▪ Vous avez également le droit de recevoir une copie des informations que WashMee recueille à votre sujet, si celles-ci sont collectées avec votre consentement ou parce que WashMee en a besoin pour fournir les services que vous demandez.

                        <br />   b. Rectification
                        <br />   ▪ Si vous pensez que WashMee dispose d'informations inexactes vous concernant, vous avez le droit d'en demander la rectification en envoyant un courriel à info@washmee.fr.
                        <br />   c. Suppression
                         <br />  ▪ Les utilisateurs peuvent demander la suppression de leur compte à tout moment. Ils peuvent le faire via le menu Mon Compte de l'Application mobile WashMee ou via le site Web WashMee pour les professionnels du lavage de véhicules. Nous pouvons être amenés à conserver certaines informations vous concernant afin de nous conformer à la législation ou pour des raisons commerciales légitimes, telles qu'autorisées par la loi.
                        <br />   ▪ Pour plus d'informations concernant les pratiques de WashMee en matière de conservation et de suppression d'informations, veuillez consulter la section correspondante ci-dessus.

                        <br />   d. Opposition et réclamations
                        <br />   ▪ Les utilisateurs au sein de l'U.E. ont le droit de s'opposer au traitement de leurs données personnelles par WashMee, notamment à des fins commerciales basées sur le profilage et/ou la prise de décision automatisée. WashMee peut, toutefois, continuer à traiter vos informations malgré votre opposition, dans les limites prévues par le RGPD.
                        <br />   ▪ Les utilisateurs au sein de l'U.E. ont également le droit de faire une réclamation concernant la façon dont WashMee traite leurs informations personnelles auprès de la COMMISSION NATIONALE DE L'INFORMATIQUE ET DES LIBERTÉS (CNIL) dont les coordonnées sont les suivantes :
                        o Adresse postale :
                        CNIL
                        <br />   3 Place de Fontenoy
                        <br />   TSA 80715
                        <br />   75334 PARIS CEDEX 07
                        <br />   o Tél : 01 53 73 22 22
                        <br />   o Fax : 01 53 73 22 00
                        <br />   o Site web : https://www.cnil.fr/fr/plaintes/
                        <br />  ▪ Vous pouvez également envoyer vos questions, commentaires ou réclamations à info@washmee.fr.

                        <br />    2. Conditions de traitement
                        Le RGPD exige que les entreprises qui traitent des données personnelles d'utilisateurs au sein de l'U.E. le fassent dans le cadre de fondements juridiques spécifiques. Comme décrit plus bas, WashMee traite les informations relatives aux utilisateurs au sein de l'U.E. dans le cadre d'un ou de plusieurs fondements stipulés dans le RGPD :

                        <br />   a. Le traitement est nécessaire pour fournir les services et les fonctionnalités que vous demandez.
                        <br />   ▪ WashMee doit recueillir et utiliser certaines informations pour vous fournir ses services. Cela comprend :
                        <br />    ▪ les informations de profil utilisateur nécessaires pour créer et conserver votre compte, y compris pour vérifier votre identité, communiquer avec vous au sujet de vos lavages de véhicules, vos commandes et vos comptes, et vous permettre d'effectuer des paiements ou de percevoir vos revenus ;
                        <br />    ▪ les données nécessaires au contrôle de sûreté, qui permettent aux laveurs de véhicules à domicile (washers) de proposer des services de lavage de véhicules via l'Application mobile WashMee Pro ;
                        <br />    ▪ les données de localisation des laveurs de véhicules à domicile (washers), nécessaires pour mettre en correspondance laveurs de véhicules à domicile (washers) et utilisateurs, suivre les lavages de véhicules en cours et proposer des modifications de commandes ;
                        <br />    ▪ les informations relatives aux transactions, qu'il est nécessaire de générer et de conserver en lien avec votre utilisation des services WashMee ;
                        <br />    ▪ les informations relatives à l'utilisation, nécessaires pour le maintien, l'optimisation et l'amélioration des services de WashMee, pour mettre en correspondance laveurs de véhicules à domicile (washers) et utilisateurs, et pour calculer les revenus des laveurs à domicile (washers).
                        <br />    ▪ La collecte et l'utilisation de ces informations sont nécessaires pour pouvoir utiliser les Applications mobiles WashMee.

                        <br />    b. Le traitement est nécessaire pour protéger les intérêts vitaux de nos utilisateurs ou d'autres personnes.
                        <br />    ▪ WashMee peut traiter des informations personnelles, notamment communiquer des données aux forces de l'ordre lorsque la sécurité des utilisateurs ou d'autres personnes est menacée.
                        <br />    c. Le traitement est nécessaire aux fins des intérêts légitimes de WashMee.
                        <br />    ▪ WashMee recueille et utilise des informations personnelles dans la mesure nécessaire aux fins de ses intérêts légitimes. Cela comprend la collecte et l'utilisation d'informations aux fins suivantes :
                        <br />    ▪ Garantir et améliorer la sécurité de nos utilisateurs. Par exemple, nous recueillons des informations relatives aux contrôles de sûreté (lorsque la loi l'autorise) pour éviter que des utilisateurs dangereux proposent des services via nos Applications mobiles. Nous utilisons également des informations personnelles pour éviter que nos services soient utilisés par des personnes ayant un comportement déplacé ou dangereux. Nous conservons, par exemple, des informations concernant les utilisateurs révoqués pour les empêcher d'utiliser les Applications mobiles WashMee. Nous nous basons également sur des données d'utilisation pour éviter de mettre en correspondance des laveurs de véhicules à domicile (washers) et des utilisateurs qui présentent un risque élevé de conflit (par exemple s'ils ont fait l'objet par le passé de plaintes de la part d'autres utilisateurs).
                        <br />    ▪ Éviter, détecter et combattre la fraude liée à l'utilisation de nos services. Par exemple, WashMee utilise des informations relatives au profil des utilisateurs, à leur position, à leurs appareils et à leur utilisation des services pour détecter et éviter des situations où des malfaiteurs tentent d'escroquer WashMee ou d'autres utilisateurs.
                        <br />    ▪ Informer les autorités d'actes criminels ou de menaces à la sécurité publique.
                        <br />    ▪ Fournir une assistance client.
                        <br />    ▪ Optimiser nos services et en développer d'autres. Cela comprend, par exemple, la recommandation de (nouvelles) fonctionnalités, l'amélioration de la navigation, des tarifs et de la mise en correspondance entre laveurs de véhicules à domicile (washers) et utilisateurs.
                        <br />    ▪ À des fins de recherche et d'analyse. Cela comprend, par exemple, l'analyse des tendances d'utilisation pour améliorer l'expérience utilisateur et la sécurité de nos services.
                        <br />    ▪ À des fins de marketing direct. Cela comprend, par exemple, l'analyse de données pour identifier des tendances et adapter les messages marketing aux besoins des utilisateurs.
                        <br />    ▪ Mettre en application les Conditions d'utilisation de WashMee.

                        <br />    d. Le traitement est nécessaire aux intérêts légitimes d'autres personnes ou parties.
                        <br />   ▪ WashMee recueille et utilise des informations personnelles dans la mesure nécessaire aux fins des intérêts d'autres personnes ou du grand public. Cela comprend le partage d'informations en lien avec des réclamations légales ou des déclarations de sinistre, pour protéger les droits et la sécurité d'autres personnes.
                        <br />    ▪ WashMee peut également traiter des informations personnelles lorsque cela est nécessaire pour des raisons d'intérêt public manifestes, en vertu des lois en vigueur.

                        <br />   e. Le traitement des données est nécessaire pour remplir les obligations légales de WashMee.
                        <br />   ▪ WashMee est soumis aux obligations légales de la juridiction dans laquelle il opère, qui impliquent la collecte, le traitement, la communication et la conservation de vos données personnelles. Par exemple, dans de nombreuses villes, WashMee est soumis à des lois et des réglementations qui impliquent la collecte d'informations concernant vos lavages de véhicules, leur conservation pendant de longues périodes et la transmission de copies de ces informations aux organismes gouvernementaux ou autres autorités compétentes. WashMee utilise vos informations conformément à ces lois, dans la mesure où elles s'appliquent à votre utilisation des Applications mobiles WashMee.
                        <br />   ▪ WashMee peut également communiquer des informations aux autorités répressives ou à des tiers dans le cadre de procédures judiciaires.

                        <br />   f. Consentement
                        <br />   ▪ WashMee peut recueillir et utiliser vos informations avec votre consentement. Vous pouvez retirer votre consentement à tout moment. Si vous retirez votre consentement, vous ne pourrez plus utiliser les services ou les fonctionnalités qui nécessitent la collecte ou l'utilisation des informations préalablement recueillies ou utilisées avec votre consentement.
                        <br />   ▪ WashMee a besoin de votre consentement pour la collecte ou l'utilisation de vos données afin d'améliorer l'expérience utilisateur, d'activer des services ou des fonctionnalités facultatifs, ou de communiquer avec vous. Si vous utilisez WashMee au sein de l'U.E., les collectes ou les utilisations suivantes des données sont effectuées avec votre consentement.
                        <br />   ▪ Informations de localisation (utilisateurs et laveurs de véhicules à domicile (washers))
                        <br />   ▪ Notifications : mises à jour concernant le compte et les lavages de véhicules
                        <br />   ▪ Notifications : réductions et actualités
                        <br />   * Consultez la Section « Choix et transparence » ci-après pour obtenir plus d'informations sur ces collectes et utilisations des données, pour savoir comment les accepter ou les refuser, et pour connaître les conséquences d'un refus sur votre utilisation des Applications mobiles WashMee.

                        <br />    * WashMee peut également recueillir des informations personnelles vous concernant par le biais d'enquêtes réalisées sur la base du volontariat. Vos réponses à ces enquêtes sont recueillies avec votre consentement et sont supprimées une fois qu'elles ne sont plus nécessaires aux fins auxquelles elles ont été recueillies.
                        Choix et transparence
                        RÉSUMÉ
                        WashMee vous offre la possibilité de consulter et vérifier les informations collectées à votre sujet, notamment par les biais suivants :
                        <br />    ▪ autorisations de l'appareil
                        <br />    ▪ pages de notation dans l'Application mobile
                        <br />     ▪ refus des communications marketing
                        Vous pouvez également demander à WashMee de vous fournir une copie de vos données, de vous les expliquer ou de les rectifier.

                        <br />    A. AUTORISATIONS DE L'APPAREIL
                        La plupart des plateformes mobiles (iOS, Android, etc.) ont défini certains types de données de l'appareil auxquelles les applications mobiles ne peuvent pas accéder sans votre accord. Ces plateformes possèdent différents systèmes d'autorisation pour obtenir votre accord.
                        La plateforme iOS vous alerte la première fois que l'Application mobile WashMee demande l'autorisation d'accéder à certains types de données et vous laisse le soin d'accepter ou de refuser cette demande. Les appareils Android vous signalent les autorisations demandées par l'Application mobile WashMee avant que vous n'utilisiez cette application mobile pour la première fois, et l'utilisation de cette application mobile vaut accord.
                        Vous trouverez ci-dessous des informations concernant ces paramètres, notamment la façon de les définir ou de les modifier, ainsi que les conséquences de leur désactivation.
                        <br />     ▪ Informations de localisation
                        <br />     ▪ WashMee utilise les services de localisation de l'appareil de :
                        <br />     - l’utilisateur pour lui permettre de trouver les stations à proximité de sa position.
                        <br />     - du laveur de véhicules à domicile (washer) pour lui permettre de trouver sur la carte ses prochains lavages de véhicules et de se diriger vers ses clients.
                        <br />     ▪ Vous pouvez activer/désactiver ou ajuster la collecte des informations de localisation de l’utilisateur et du laveur de véhicules à domicile (washer) par WashMee à tout moment dans les paramètres de votre appareil. La désactivation du service de localisation de votre appareil a des conséquences sur votre utilisation des Applications mobiles WashMee et WashMee Pro. Par exemple, vous devrez saisir manuellement votre lieu actuel pour accéder à la liste des stations à proximité ou pour commander un lavage de véhicule.
                        <br />     ▪ Notifications : mises à jour du compte et des lavages de véhicules
                        <br />     ▪ WashMee envoie aux utilisateurs et aux laveurs de véhicules à domicile (washers) des notifications concernant le statut de leurs lavages de véhicules (par exemple : accepté, refusé, nouvel horaire, fin de la prestation…) et des mises à jour concernant leur compte. Ces notifications sont nécessaires à l'utilisation de l'Application mobile WashMee. Cependant, vous pouvez à tout moment désactiver ces notifications dans les paramètres de votre appareil.
                        <br />    ▪ Notifications : réductions et actualités
                        <br />     ▪ Vous pouvez autoriser WashMee à vous envoyer des notifications Push concernant des réductions effectuées par des professionnels du lavage de véhicules et des actualités WashMee. Cependant, vous pouvez à tout moment désactiver ces notifications dans les paramètres de votre appareil.
                        <br />   B. CONSULTATION DES NOTES
                        Après chaque achat d’un type lavage de véhicules (portique, tunnel, haute pression, aspirateur, lavage de véhicules à la main et lavage de véhicules à domicile), les utilisateurs peuvent noter les professionnels du lavage de véhicules et fournir des commentaires sur le déroulement de la prestation. Les professionnels du lavage de véhicules peuvent répondre aux commentaires laissés par les utilisateurs soit par l’Application mobile WashMee Pro pour les laveurs de véhicules à domicile (washers), soit par le site Web WashMee pour tous les professionnels du lavage de véhicules.
                        Ce système de notation, de commentaire et de réponse permet de faire en sorte que chacun soit pleinement responsable de son comportement. Cette responsabilité permet de créer un environnement respectueux et sûr, à la fois pour les professionnels du lavage de véhicules et pour les utilisateurs.
                        WashMee en parallèle veille à ce que les commentaires soient conforme à l’éthique. Il est possible pour les professionnels du lavage de véhicules de demander la modération d’un commentaire à WashMee.
                        La note et le commentaire attribués au professionnel du lavage de véhicules par l’utilisateur et la réponse éventuelle du professionnel du lavage de véhicules sont disponibles dans le menu principal de l'Application mobile WashMee (rubrique Mes Avis).
                        La note et le commentaire attribués au professionnel du lavage de véhicules par l’utilisateur et la réponse éventuelle du professionnel du lavage de véhicules sont disponibles dans le menu principal de l'Application mobile WashMee Pro (rubrique Avis).

                        <br />   D. EXPLICATIONS, COPIES ET RECTIFICATION
                        Vous pouvez demander que WashMee :
                        <br />    ▪ fournisse une explication détaillée des informations collectées à votre sujet et de l'utilisation qui en est faite ;
                        <br />  ▪ fournisse une explication détaillée des informations collectées à votre sujet et de l'utilisation qui en est faite ;
                        <br />   ▪ vous envoie une copie des informations collectées à votre sujet ;
                        <br />   ▪ procède à la rectification de toute information erronée dont WashMee dispose à votre sujet.
                        Pour procéder à ces demandes, vous pouvez contacter WashMee en envoyant un courriel à info@washmee.fr.
                        Vous pouvez également modifier le nom, le numéro de téléphone et l'adresse e-mail associés à votre compte dans le menu Mon Compte des Applications mobiles WashMee et WashMee Pro. Vous pouvez également consulter l'historique de vos commandes et de vos lavages de véhicules dans les Applications mobiles WashMee et WashMee Pro et sur le site web WashMee.

                        <br />    E. REFUS DES COMMUNICATIONS MARKETING
                        <br />    Vous pouvez refuser de recevoir les e-mails promotionnels envoyés par WashMee en envoyant un courriel à info@washmee.fr. Vous pouvez également refuser de recevoir des e-mails et d'autres messages de WashMee en suivant les indications données dans ces messages. Notez que si vous choisissez de les refuser, nous pouvons continuer à vous envoyer des messages non promotionnels, comme les reçus de vos commandes ou des informations concernant votre compte.
                        Mises à jour de cette politique
                        Nous pouvons mettre à jour cette politique de manière occasionnelle. En cas d'évolution importante, nous vous avertissons par les Applications mobiles WashMee et WashMee Pro ou d'autres moyens, comme par e-mail. Dans la mesure où la législation applicable l'autorise, utiliser nos services après un tel avertissement équivaut à accepter les mises à jour de cette politique.
                        Nous vous encourageons à consulter régulièrement cette politique afin de connaître les dernières informations relatives à nos pratiques concernant la confidentialité. Nous mettrons également les versions précédentes de cette politique (si existantes) à disposition pour consultation.
                    </div>
                    : <></>
            }


            <Footer />
        </>
    )
}

export default MentionLegal;