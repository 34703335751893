import React, { useState, useEffect } from "react";

import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next'
import { CSSTransition } from "react-transition-group";

import { tabMenuDesktop, tabMenuMobile } from '../../helpers/texts/header.json';

import "../../style/layout/header.scss";


const Header = () => {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isProHeader, setIsProHeader] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    useEffect(() => {
        window.location.pathname === "/espacepro" ? setIsProHeader(true) : setIsProHeader(false);
    }, [])

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <header className="Header">
            <a href="/"><img src="./img/logo.png" className="Logo" alt="logo" /></a>
            <CSSTransition
                in={!isSmallScreen || isNavVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                {
                    !isSmallScreen
                        ? <nav className="Nav">
                            {
                                isProHeader
                                    ? <Button className="btn btn-washer" href="https://pro.washmee.fr/pro/?page=login" >Connexion</Button>
                                    : <></>
                            }
                            {
                                tabMenuDesktop.map((e, i) =>
                                    <Button key={i} className={e.class} href={e.href}>{e.text}</Button>
                                )

                            }


                        </nav>
                        :
                        <nav className="Nav">
                            {
                                isProHeader
                                    ? <Button className="btn btn-contact" href="https://pro.washmee.fr/pro/?page=login" >Connexion</Button>
                                    : <></>
                            }
                            {
                                tabMenuMobile.map((e, i) =>
                                    <Button key={i} className={e.class} href={e.href}>{e.text}</Button>
                                )
                            }

                        </nav>
                }


            </CSSTransition>
            <button onClick={toggleNav} className="Burger">
                <img src="./img/icon_menu.png" alt="icone menu" />
            </button>
        </header>
    );
}

export default withTranslation()(Header);