import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';



export const WashmeeUnivers = () => {
    return (
        <section className="section-universe">
            <Row>

                <Col className="text-section" md={6}>
                    <Row className="main-row-title-logo">
                        <Col md={6} xs={5} xl={3}>
                            <p>L’univers</p>
                        </Col>
                        <Col md={3} xs={7} className="" >
                            <img src="./img/logo.png" />
                        </Col>
                    </Row>
                    <Row className="main-row-univers">
                        <Col style={{ marginBottom: '1em' }} md>
                            <img alt="histoire de washmee" src="img/home/story-washmee-lavage-auto.jpg" className="img-story-washmee border-radius-img" />
                        </Col>
                        <Col md={12} >
                            <ScrollAnimation animateIn="slideInLeft">
                                <p>WashMee est la marketplace qui référence l’ensemble des professionnels du
                    lavage de véhicules en France.</p>
                                <p>Un réseau de centres fixes et d’intervenants en déplacement qui
                 valorisent leurs services et leurs offres via notre marque.</p>
                                <p>Forts d’une visibilité nationale, nous mettons en relation les utilisateurs
                                avec les professionnels pouvant répondre aux besoins de chacun. Réservez en
                     quelques clics votre lavage via notre site ou notre appli mobile.</p>
                                <p>WashMee plus qu’une marque, un écosystème du lavage !</p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="align-center">
                            <button className="btn-washmee" onClick={() => window.location = "https://washmee.fr/commander-lavage/?p=commander-station"}>Commander un Lavage</button>
                        </Col>
                    </Row>
                </Col>

            </Row >


        </section >
    )
}
export const WashmeeParticular = () => {
    return (
        <section className=" section-particulier" md>

            <Row>
                <Col className="text-section" md={6}>
                    <Row>
                        <Col xs={12} className="margin-top-img-text">
                            <h2>WashMee au service des particuliers</h2>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col style={{ marginBottom: '1em' }} md>
                            <img alt="histoire de washmee" src="img/home/washmee-service-clients-particuliers.jpg" className="img-story-washmee border-radius-img" style={{ width: '100%' }} />
                        </Col>
                        <Col md={12}>
                            <ScrollAnimation animateIn="slideInRight">
                                <p>5 000 professionnels sont répertoriés en France par WashMee.
                                Haute pression, portique, tunnel, lavage à la main en centre fixe ou en
                                    déplacement, tout y est !</p>
                                <p>WashMee vous permet de trouver le professionnel adapté à vos besoins
                                à proximité de votre position, de réserver une prestation et de profiter
                                        d’offres exclusives.</p>

                                <p className="bold-tilinium"><a style={{ color: '#96D7DE' }} href="/contact?form=particu">Adressez-nous votre demande</a></p>
                            </ScrollAnimation>
                        </Col>


                    </Row>
                    <Row>
                        <Col className="align-center">
                            <button className="btn-washmee" onClick={() => window.location = "https://washmee.fr/commander-lavage/?p=commander-station"}>Commander un Lavage</button>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </section >
    )
}


export const WashmeePro = () => {
    return (
        <section className="section-pro" >
            <Row>
                <Col className="text-section slideInRight" md={6}>
                    <Row>
                        <Col xs={12}>
                            <h2 className="margin-top-img-text-pro">L’offre WashMee adaptée aux professionnels</h2>
                        </Col>
                    </Row>
                    <Row className="main-row-univers">
                        <Col style={{ marginBottom: '1em' }} md>
                            <img alt="histoire de washmee" src="img/home/washmee-service-clients-professionnels.jpg" className="img-story-washmee border-radius-img" />
                        </Col>
                        <Col md={12}>
                            <ScrollAnimation animateIn="slideInLeft">
                                <p>Que vous soyez gestionnaire d’une flotte de véhicules, chef
                                d’entreprise, gérant(e) d’une conciergerie... nous répondons à
                                    tous les cahiers des charges.</p>
                                <p>Découvrez les avantages de faire appel à WashMee pour le lavage
                                de vos véhicules en vous rendant sur
                                    <a className="bold-tilinium" style={{ color: '#96D7DE' }} href="/entreprise"> notre page explicative.</a> </p>
                                <p>Vous avez une demande ? <a className="bold-tilinium" style={{ color: '#96D7DE' }} href="/contact?form=pro">Contactez-nous</a> en détaillant votre besoin et notre équipe se charge de tout ! </p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="align-center">
                            <button className="btn-washmee" onClick={() => window.location = "/contact?form=pro"}>Contactez-nous</button>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </section >
    )
};