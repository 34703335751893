import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import LocationSearchInput from './Autocomplete';

const Location = () => {
    const [valueLocation, setValueLocation] = useState(null);
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    const [address, setAddress] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            setLat(position.coords.latitude);
            setLon(position.coords.longitude)
        });
    }, []);
    const onClickLocation = (e) => {
        if (!lat && !lon)
            toast("Vous n'avez pas activé votre géolicalisation !", {
                autoClose: 5000,
                position: "top-center",
                closeOnClick: true,
            });
        else
            window.location = `https://www.washmee.fr/commander-lavage/?p=commander-station&lat=${lat}&lng=${lon}`;
    }

    const redirectOrder = () => {
        console.log(address)
    }

    const handleChangeLocation = () => {
        console.log(valueLocation)
    }
    return (
        <Container fluid>
            {
                !isSmallScreen
                    ? <Row className="home-location-row">
                        <Col className="no-padding" md={5}>
                            <button onClick={() => onClickLocation()}>Je me localise<img src="img/home/icon-location.svg" /></button>
                        </Col>
                        <Col className="no-padding" md={5}>
                            <LocationSearchInput address={address} setAddress={setAddress} setLocation={setValueLocation} />
                        </Col>
                    </Row>
                    :
                    <Row className="home-location-row">
                        <Col className="no-padding" xs={4}>
                            <button onClick={() => onClickLocation()}><img src="img/home/icon-location.svg" /></button>
                        </Col>
                        <Col style={{ paddingLeft: '0' }} xs={8}>
                            <LocationSearchInput address={address} setAddress={setAddress} setLocation={setValueLocation} />
                        </Col>
                    </Row>
            }

        </Container>
    )
}

export default Location;