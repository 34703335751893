import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';

import { api } from '../../helpers/api';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';

import '../../style/contact/contact.scss'
import '../../style/contact/contact-phone.scss';
import '../../style/contact/contact-tablette.scss';


const optionWasher = [
    {
        value: "Lavage à domicile",
        label: "Lavage à domicile"
    },
    {
        value: "Lavage en station",
        label: "Lavage en station"
    },
    {
        value: "Station de lavage haute pression",
        label: "Station de lavage haute pression"
    },
    {
        value: "Station de lavage automatique",
        label: "Station de lavage automatique"
    }
]
const optionPro = [
    {
        value: "Flotte",
        label: "Flotte"
    },
    {
        value: "Conciergerie",
        label: "Conciergerie"
    },
    {
        value: "Loueur",
        label: "Loueur"
    },
    {
        value: "Revendeur automobile",
        label: "Revendeur automobile"
    },
    {
        value: "Chef d'entreprise",
        label: "Chef d'entreprise"
    }
];

const MainSection = ({ openPopup }) => {


    return (
        <section id="section-main-contact">
            <Row className="section-main-contact ">
                <Col className="section-text" md={5}>
                    <Row>
                        <Col>
                            <h2 className="title-contact align-center">Restons en contact</h2>
                        </Col>
                    </Row>
                    <div style={{ height: '1em' }}></div>
                    <Row>
                        <Col>
                            <p width={{ width: '100%' }}>Un petit pont nous sépare de vous alors pourquoi ne pas venir nous rejoindre et discuter ?
                            Une question sur une station ou sur l’un de nos laveurs, besoin d’un conseil pour choisir
                            la prestation de lavage la mieux adaptée à votre véhicule ou simplement envie de prendre
                            un café ensemble ? Nous serions ravis de pouvoir vous aider et échanger avec vous.</p>

                        </Col>
                    </Row>
                    <div style={{ height: '1em' }}></div>
                    <Row>
                        <Col>
                            <h5>Les premières pistes  pour nous joindre :</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col md={1} xs={2} className="col-icon-phone">
                                    <img src="img/contact/phone.png" alt="sms" />
                                </Col>
                                <Col md={7} xs={8} className="col-link-phone">
                                    <a href="tel:0974748803" >09 74 74 88 03</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} xs={2} className="col-icon-mail">
                                    <img src="img/contact/email.png" />
                                </Col>
                                <Col md={7} xs={8} className="col-link-mail" >
                                    <a href="mailto:info@washmee.fr" alt="mail">info@washmee.fr</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ height: '2em' }}></div>

                </Col>
                <Col md={6}>
                    <img className="img-contact border-radius-img" src="./img/contact/washmee-contact.jpg" alt="photo du formulaire " />
                </Col>
            </Row>
            <Row>
                <Col className="section-text">
                    <Row>
                        <Col>
                            <h4 className="align-center">Notre formulaire dédié à votre besoin !</h4>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p className="align-center">Quelle que soit votre demande, nous vous apporterons totale satisfaction  </p>
                        </Col>
                    </Row >
                    <Row style={{ textAlign: 'center' }}>
                        <Col md={4} xs>
                            <Button className="formButton btnForm-left" onClick={() => openPopup(1)}>Particulier</Button>
                        </Col>
                        <Col md={4} xs>
                            <Button className="formButton" onClick={() => openPopup(2)}>Entreprise</Button>
                        </Col>
                        <Col md={4}>
                            <Button className="formButton" onClick={() => openPopup(3)}>Professionnel du lavage</Button>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: '1em' }}>
                        <Col md={8} xs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', boxShadow: 'none', border: 0 }),
    option: styles => {
        return {
            ...styles,
            color: 'black',
            ':hover': {
                backgroundColor: '#96D7DE',
                color: 'white',
            },
            ':active': {
                ...styles[':active'],
                backgroundColor: null,
            },
        }
    },
    multiValueRemove: styles => ({
        ...styles,
        ':hover': {
            backgroundColor: '#96D7DE',
            color: 'white',
        },
    })
};
const sizeContainer = 100;


const Contact = () => {
    const [show, setShow] = useState(null);
    const [infoForm, setInfoForm] = useState({});

    const closePopup = () => setShow(false);
    const openPopup = (step) => setShow(step);

    useEffect(() => {
        if (window.location.search === "?form=particu") {
            openPopup(1)
        }
        if (window.location.search === "?form=pro") {
            openPopup(2)
        }
        if (window.location.search === "?form=washer") {
            openPopup(3)
        }

    }, []);

    const sendForm = () => {
        if (Object.keys(infoForm).length === 0) {
            toast.warn("Les champs du formulaire sont vides !", {
                autoClose: 5000,
                position: "top-center",
                closeOnClick: true,
            });
        }
        else {
            axios.post('https://apiv1.washmee.fr/contact', {
                ...infoForm
            })
                .then(data => {
                    setInfoForm({});
                    toast.success("Votre demande à bien été envoyée !", {
                        autoClose: 5000,
                        position: "top-center",
                        closeOnClick: true,
                    });
                    closePopup();
                })
                .catch(err => {
                    setInfoForm({});
                    if (err.response && err.response.status === 400)
                        toast.warn(err.response.data, {
                            autoClose: 5000,
                            position: "top-center",
                            closeOnClick: true,
                        });

                    else
                        toast.error("Erreur interne", {
                            autoClose: 5000,
                            position: "top-center",
                            closeOnClick: true,
                        });
                })
        }
    }

    return (
        <>
            <Header />
            <MainSection openPopup={openPopup} />

            {/* POPUP FORMULAIRE CONTACT */}
            {/* PARTICULIER */}
            <Modal className="popup" show={show === 1} onHide={closePopup}>
                <Modal.Header closeButton>
                    <img className="bg-img-form" src="img/contact/washmee-formulaire-contact-clients-particuliers.png" />
                    <Modal.Title>Formulaire de contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Nom - Prénom*</Form.Label>
                        <Form.Control className="input-text-form" type="text" onChange={e => setInfoForm({ ...infoForm, fullName: e.target.value, from: "Particulier" })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email*</Form.Label>
                        <Form.Control className="input-text-form" type="email" onChange={e => setInfoForm({ ...infoForm, email: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Téléphone*</Form.Label>
                        <Form.Control className="input-text-form" type="number" onChange={e => setInfoForm({ ...infoForm, number: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Demande(s) : *</Form.Label>
                        <Form.Control className="input-text-form" as="textarea" type="text" onChange={e => setInfoForm({ ...infoForm, msg: e.target.value })} />
                    </Form.Group>
                    <Row>
                        <Col className="align-center" md>
                            <Button className="formButton btn-washmee-important" type="submit" onClick={() => sendForm()}>Envoyez</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {/* PROFESIONNEL */}
            <Modal className="popup" show={show === 2} onHide={closePopup}>
                <Modal.Header closeButton>
                    <img className="bg-img-form" src="img/contact/washmee-formulaire-contact-clients-professionnels.png" />
                    <Modal.Title>Formulaire de contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Nom - Prénom*</Form.Label>
                        <Form.Control className="input-text-form" type="text" onChange={e => setInfoForm({ ...infoForm, fullName: e.target.value, from: "Professionel" })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Entreprise*</Form.Label>
                        <Form.Control className="input-text-form" type="text" onChange={e => setInfoForm({ ...infoForm, company: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type*</Form.Label>
                        <Select
                            options={optionPro}
                            closeMenuOnSelect={false}
                            defaultValue={{ value: '', label: 'Choisissez une option...' }}
                            styles={colourStyles}
                            onChange={e => setInfoForm({ ...infoForm, type: e })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email*</Form.Label>
                        <Form.Control className="input-text-form" type="email" onChange={e => setInfoForm({ ...infoForm, email: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Téléphone*</Form.Label>
                        <Form.Control className="input-text-form" type="number" onChange={e => setInfoForm({ ...infoForm, number: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Demande(s) : Nombre de véhicule(s), date, heure, lieu, spécificité(s)*</Form.Label>
                        <Form.Control className="input-text-form" as="textarea" type="text" onChange={e => setInfoForm({ ...infoForm, msg: e.target.value })} />
                    </Form.Group>
                    <Row>
                        <Col className="align-center" md>
                            <Button className="formButton btn-washmee-important" type="submit" onClick={() => sendForm()}>Envoyez</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {/* WASHER */}
            <Modal className="popup" show={show === 3} onHide={closePopup}>
                <Modal.Header closeButton>
                    <img className="bg-img-form" src="img/contact/washmee-formulaire-contact-professionnels-lavage.png" />
                    <Modal.Title>Formulaire de contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Nom - Prénom*</Form.Label>
                        <Form.Control className="input-text-form" type="text" onChange={e => setInfoForm({ ...infoForm, fullName: e.target.value, from: "Laveur" })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Téléphone*</Form.Label>
                        <Form.Control className="input-text-form" type="number" onChange={e => setInfoForm({ ...infoForm, number: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email*</Form.Label>
                        <Form.Control className="input-text-form" type="email" onChange={e => setInfoForm({ ...infoForm, email: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type de lavage*</Form.Label>
                        <Select
                            options={optionWasher}
                            closeMenuOnSelect={false}
                            isMulti
                            placeholder="Choisissez une option..."
                            // defaultValue={{ value: '', label: 'Choisissez une option...' }}
                            styles={colourStyles}
                            onChange={e => setInfoForm({ ...infoForm, typeOfWash: e })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Message*</Form.Label>
                        <Form.Control className="input-text-form" as="textarea" type="text" onChange={e => setInfoForm({ ...infoForm, msg: e.target.value })} />
                    </Form.Group>
                    <Row>
                        <Col className="align-center" md>
                            <Button className="formButton btn-washmee-important" type="submit" onClick={() => sendForm()}>Envoyez</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}

export default Contact;