import React from 'react';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';

const CGU = () => {
    return (
        <>
            <Header />
            <div style={{ padding: '3em' }}>
                <h3>CONDITIONS GÉNÉRALES D’UTILISATION DE L’APPLICATION MOBILE WASHMEE, DE L’APPLICATION MOBILE WASHMEE PRO ET DU SITE INTERNET WWW.WASHMEE.FR</h3>
                <p>1. OBJET</p>
                <p>Les présentes Conditions Générales d’Utilisation(ci - après les « CGU ») ont pour objet de définir:</p>
                <p>-les conditions et modalités de l’accès et de l’utilisation des services proposés par la société WashMee / BGA CBA(ci - après la « Société ») et ses partenaires commerciaux(ci - après les « Partenaires »),
                les droits et obligations respectifs de la Société et de l’Utilisateur de l’Application, de l’Application Professionnelle et / ou du Site dans le cadre de l’utilisation de l’Application, de l’Application Professionnelle et / ou du Site.
                Les conditions générales s’appliquent à tous les utilisateurs des applications mobiles(ci - après l’« Application » et/ou l’ « Application Professionnelle ») et/ou du site internet https://www.washmee.fr (ci- après le « Site»). L’Application et l’Application Professionnelle sont téléchargeables sur l’AppStore® et le GooglePlay Store®.
                                Les services proposé s  par la Société sont régis par les présen t es CGU et les Conditions Générales de Vente applicables, en cas d’achat en ligne via l’Application et/ou le Site, à l’exclusion de tous les autres documents, tels que prospectus, catalogues, documentations, même émanant de WashMee qui ne sont donnés qu’à titre d’information et n’ont qu’une valeur indicative et non contractuelle.
                En accédant et en utilisant les services proposés par l’Application, l’Application Professionnelle et/ou le Site et leurs contenus, l’utilisateur (ci-après l’« Util i sateur ») accepte et s’engage à respecter les présentes CGU ainsi que les Conditions Générales de Vente pour ce qui concerne les Services payants. La Société se réserve à tout moment la possibilité d’adapter ou de modifier les CGU. En cas de modification, l’Utilisateur est invité à lire et accepter les nouvelles CGU avant de continuer à utiliser les Applications et/ou le Site.
                ne n’acceptant pas les présentes CGU doit s’abstenir de consulter et/ou d’utiliser les se r vices fournis via l’Application, l’Applicaton  P rofessionnelle et/ou le Site de quelque manière que ce soit.  </p>
            <br />
                    <p>2. DÉFINITIONS    </p>
            <p> Sauf précision contraire expresse, les termes et expressions suivants, lorsqu’ils sont employés avec des initiales majuscules, ont la signification qui leur est attribuée ci-dessous :
                <br />- Application : L’application mobile WashMee par laquelle la Société fournit des informations et le cas échéant, propose des offres de produits ou de prestations relatifs au   lavage ou l’entretien automobile fournies par la Société ou ses Partenaires.
            <br /> - Application Professionnelle : L’application mobile professionnelle WashMee Pro est destinée aux professionnels du lavage à domicile exclusivement. Les informations y figurant sont confidentielles et accessibles par les professionnels du lavage à domicile au moyen d’un identifiant et d’un mot de passe. - Avis Clients : notes et commentaires laissés par les Utilisateurs. La création d’un Compte client ainsi que la réalisation d’un achat / paiement sont indispensables pour laisser une note ou commentaire.
            <br /> - Centre de lavage : Le lieu ù l’Utilisateur de l’Application peut bénéficier d’un Lavage sur site dans le cadre de services proposés par un Partenire.
            <br /> - Client : tout Utlisateur de l’Application qui procède à un acte d’achat via l’Application. Un Client dispose d’un Compte Client sur l’Application et/ou le Site.
            <br /> - Client Professionnel : tout Utilisateur de l’Application Professionnelle ou du Site qui procède à un acte de vente via l’Application ou le Site. Un Client Professionnel dispose d’un Compte Client sur le Site (et l’Application Professionnelle s’il s’agit d’un laveur en déplacement).
                  CGU WashMee 2020 – version 1.9  
            1
            <br /> - Code lavage : code sécurisé unique, à usage unique, généré pur le Client dans l’Application afin de lui permettre d’obtenir un Lavage sur site dans le Centre de lavage choisi.
            te client : le compte personnel, sur’Application, l’Application Professionnelle ou le Site, du Client ou Client Professionnel, lui permettant d’accéder au service d’achat (ou vente pour les Professionnels du lavage) de Lavage sur l’Application ou le Site.
            <br /> - FastPass : La possibilité de réserver un créneau de lavage sur une piste dédiée pour un Lavage sur site.
            <br />  - Forfaits : es professionnels du lavage exerçant une activité de lavage de véhicules, peuvent souscrire à différents Forfaits sur le Site afin de devenir Partenaire de WashMee. Ces Forfaits sont payables mensuellement ou annuellement et permettent un référencement précis et individuel des solutions de lavage.
            <br />  - Lavage sur sie : Toutes prestations de Lavage ou d’entretien proposées par les Partenaires sur leurs Centres de lavage.
            <br />  - Promotion : Le Partenaire peut, à son entière discrétion, créer des promotions pouvant être utilisées pour obtenir un avantage tarifaire sur les services qu’il propose.
            <br />  - Partenaire : tout professionnel disposant d’un ou plusieurs Centres de lavage, et ayant signé un contrat avec WashMee en vue de son référencement sur l’Application, l’Applicatin Professionnelle et le Site, et lui permettant de vendre via l’Application, des prestations de lavage aux Utilisateurs de l’Application et/ou du Site.
            <br /> - Non-partenaire  tout professionnel disposant d’un ou plusieurs centres de lavage simplement répertorié(s) sur l’Application et le Site, sans avoir signé de contrat avec la Société.
            <br /> - Réservation : la Société propose un service de prise de rendez-vous aux Utilisateurs de l’Application et/ou du Site pour les prestations de lavage à la main et de lavage à domicile. La prise de rendez-vous se fait par un calendrier mis à disposition par la Société via l’Application et/ou le Site dans certains centres de lavage partenaires.
            <br /> - Site : Le Site https://www.washmee.fr par lequel la Société fournit des informations et le cas échéant, propose des offres de produits ou de prestations relatifs au lavage ou l’entretien automobile fournies par ses Partenaires. Le Site dispose d’une interface de gestion complète pour les Partenaires ayant signé un  c ontrat avec WashMee. Cette interface permet d’ajouter, modifier ou supprimer des informations contenues dans l’Application et le Site et de créer des Comptes utilisateurs pour l’Application Professionnelle. L’interface de gestion permet aussi de communiquer vers les Utilisateurs et de créer des Promotions. Elle donne accès à plusieurs reportings d’activité.
            <br />  - Uti l isateur : toute personne utilisant l’Application ou naviguant sur le Site afin de visualiser ses contenus (informations, localisation des Centres de lavage des Partenaires, types de Lavage et autres services annexes proposés, tarifs etc.).</p>
           <p> 3. CONDITIONS D’ACCÈS À L’APPLICATION WASHMEE, WASHMEE PRO ET AU   SITE INTERNET </p>     
            <br /> • Accès aux réseaux : L’accès et l’utilisation de l’Application, de l’Application Professionnelle et du Site nécessite de disposer d’un accès à internet. La Société ne saurait être tenue responsable de difficultés liée à l’accès au réseau.
            <br /> • Conditions tenant à l’Utilisateur de l’Application et/ou de l’Application Professionnelle et/ou du Site : l’Aplication, l’Application Professionnelle et le Site sont accessibles à toutes personnes physiques disposant de la pleine capacité juridique pour s’engager au titre des présentes Conditions Générales d’Utilisation.
                 <br />    • Téléchargement de l’Application et de l’Application Professionnelle : l’Applon et l’Application Professionnelle sont utilisables sur smartphones et tablettes mobiles, dans les systèmes d’exploitation Android et iOS. Les téléchargements de l’Application et de l’Application Professionnelle doivent être réalisés depuis une plateforme de téléchargement d’applications appropriée (AppStore® pour iOS et GooglePlay Store® Android). Ces téléchargements sont soumis aux conditions générales d’utilisation de la plateforme concernée.
                ashMee 020 – version 1.9 2
            <br />
            <br /><p> 4. ACCEPTATION DES PRESENTES CONDITIONS GENERALES D’UTILISATION :</p>
sateur du Site, de l’Application et/ou de l’Application Professionnelle déclare, lors de la création de son compte client, avoir pris connaissance et accepté les présentes CGU en cochant la case prévue à cet effet.
L’absence de création de « Compte Client » (et donc l’impossibilité d’achat), n’interdit pas la navigation sur le Site ou dans l’Application dans le respect des présentes CGU.
Il est en revanche indispensable de prendre connaissance et d’accepter les présentes CGU afin d’utiliser l’Application Professionnelle.
                ation ne peut être que pleine et entière. Toute adhsion sous réserve est considérée comme nulle et non avenue.
L’Utilisateur du Site peut prendre connais s ance des présentes CGU préalablement à la navigation sur le Site.
    Une fois l’installation de l’Application effectuée, l’utilisation de l’Application peut s’effectuer librement sans que l’Utilisateur de l’Application n’ait l’obligation de s’identifier ou d’accepter la géolocalisation proposée par l’Application. La création d’un Compte client est facultative. Toutefois certaines fonctionnalités ne sont accessibles que par suite d’une inscription sur l’Application : création d’un compte client, achat et paiement en ligne, réservation d’un créneau de lavage, réservation d’un créneau FastPass).
Une fois l’installation de l’Application Professionnelle effectuée, l’utilisation de l’Application Professionnelle ne peut s’effectuer que sous réserve d’identification préalable. La création d’un Compte client est obligatoire.
L’utilisation de l’interface de gestion web sur le Sie pour les Clients Professionnels impose leur identification et l’acceptation des présentes CGU.
            <br />
            <br /> <p> 5. MODALITES D’UTILISATION DE L’APPLICATION WASHMEE ET DU SITE 5.1.</p>
        Conditions financières
Les services fournis au travers de l’Application et du Site n’imposent pas l’utilisation des services payants tels que définis ci-dessous.
La gratuité de certains services ne comprend, en aucun cas ni aucune hypothèse, les frais de connexion à internet correspondants au téléchargement et à l’utilisation de l’Applicaton, ni les achats éventuellement effectués par l’Utilisateur au prix indiqué sur l’Application, conformément aux Conditions Générales de Vente.
                ation Professionnelle et l’interface de gestion web du Site sont des services payants.
            <br />
            <br />       <p> 5.2. Création d’un compte Client</p>
Pour pouvoir procéder à un achat sur l’Application et/ou le Site, l’Utilisateur doit avoir au préalable créé un Compte client.  
Le Compte client de l’Utilisateur est un Compte client dit Personnel.
    Le Compte client du Client Professionnel est un Compte client dit Professionnel. Pour pouvoir procéder à une vente sur l’Application et/ou le Site, le Client Professionnel doit avoir au préalable créé un Compte client Professionnel.
                L’Utlisateur et/ou le Client Professionnel garantit détenir un seul et unique Compte client.
    CGU WashMee 2020 – version 1.9  
3
            <br />
            <br />  <p> 5.2.1.Compte Cli e nt Personnel</p>
 pouvoir procéder à un achat via lpplication ou le Site, l’Utilisateur doit avoir au préalable créé un Compte Client Personnel sur l’Application ou le Site.
La création d’un Compte client Personnel nécessite que :
            <br />- L’Utilisateur de l’Application et/ou du Site s’enregistre en remplissant le formulaire disponible sur l’Application et/ou sur le Site dans le menu « Commander un lavage » puis « « Connexion » et renseigne les informations obligatoires signalées par un astérisque « * ».
                    <br />- Le Compte Client Personnel est le même sur l’Application ou le Site.
L’Utilisateur de l’Application ou du Site s’engage à ce que les informations communiquées lors de son inscription soient exactes et fiables, et s’engage à les mettre à jour dès leur modification.
            <br />
            <p>5.2.2.Compte Client Professionnel</p>
Pour pouvoir procéder à une vente v i a l’Application et/ou le Site, le Client Professionnel doit avoir au préalable créé  u n Compte Client Professionnel sur l’interface de gestion web du Site.
La création d’un Compte client Professionnel nécessite que :
            <br /> - Le Client Professionnel s’enregistre en remplissant le formulaire affiché dans la rubrique « Espace Pro » sur le Site lors de son inscription et renseigne les informations obligatoires signalées par un astérisque « * ».
                <br />    - Un Client Professionnel effectuant du lavage en déplacement doit s’enregistrer en plus sur l’Application Professionnelle en remplissant le formulaire disponible sur l’Application Professionnelle en cliquant sur « Première connexion » et renseigne les informations obligatoires signalées par un astérisque « * » qui n’ont pas encore été rentrées dans le Site via l’interface de gestion web par le Client Professionnel.
Le Client Professionnel enregistré sur le Site et sur  l ’Application Professionnelle pour les laveurs en déplacement, s’engage à ce que les informations communiquées lors de son inscription soient exactes et fiables, et s’engage à les mettre à jour dès leur modification.
            <br />
            <br /><p>5.3. Les Identifiants</p>
            <br />
            <br /><p>5.3.1.Compte Client Personnel</p>
Pour la création d’un Compte client Personnel, l’Utilisateur de l’Application et/ou du Site fournit son numéro de téléphone mobile et un mot de passe (ci-après les « Identifiants ») lui permettant d’accéder à son espace personnel. Ces Identifiants sont personnels. Ils ne peuvent être changés que sur demande de l’Utilisateur de l’Application et/ou du Site ou à l’initiative de la Société.
                r de l’Application et/ou du Site est seul et entièrement responsable de l’utilisation des Identifiants le concernant et s’engage à mettre tout en œuvre pour conserver secret son mot de passe et à ne pas le divulguer, à qui que ce soit, sous quelque forme que ce soit et pour quelque raison que ce soit.
                 CGUWashMee 2020 – version 1.9
                
            <br />
            <br />   <p>5.3.2.Compte Client Professionnel  </p>
our la création du Compte client Professionnel sur le Site, le Client Professionnel du Site choisit son adresse e-mail et un mot de passe (ci-après les « Identifiants Professionnels ») lui permettant d’accéder à son espace personnel sur le Site.
Pour les laveurs en déplacement, en plus de la création d’un Compte client Professionnel sur le Site, il est nécessaire de créer un compte sur l’Application Professionnelle pour renseigner les informations obligatoires signalées par un astérisque « * » qui n’ont pas encore été fournies sur le Site via l’interface de gestion web par le Client Professionnel. Pour la création du Compte client Professionnel sur l’Application Professionnelle, le Client Professionnel de l’Application Professionnelle choisit son numéro de téléphone mobile et un mot de passe (ci-après les « Identifiants Professionnels ») lui permettant d’accéder à son espace personnel sur l’Application Professionnelle.
Ces Identifiants Professionnels sont personnels. Ils ne peuvent être changés que sur demande du Client Professionnel du Site et de l’Application Professionnelle pour les laveurs en déplacement ou à l’initiative de la Société. Le Client Professionnel du Site et de l’Application Professionnelle pour les laveurs en déplacement est seul et entièrement responsable de l’utilisation des Identifiants Professionnels le concernant et s’engage à mettre tout en œuvre pour conserver secret ses Identifiants Professionnels et à ne pas les divulguer, à qui que ce soit, sous quelque forme que ce soit et pour quelque raison que ce soit.
                <br /><p>6. INFORMATIONS SUR LES SERVICES </p>
                <p>6.1. Servicesdebase</p>
                    L’Application et le Site permettent la mise à disposition, au bénéfice des Utilisateurs, des services suivants :    
            <br />- un service de recherche, gratuit pour l’Utilisateur, des centres de lavage de Partenaires et Non- partenaires à proximité de l’endroit où l’Utilisateur se situe, via sa géolocalisation, ou à proximité d’une adresse renseignée par l’Utilisateur ;  
            <br />- un service de fourniture d’informations, gratut pour l’Utilisateur, sur les Centres de lavage et les services de Lavage sur site et autres services annexes qui y sont proposés par des Partenaires. Les informations mises à isposition de l’Utilisateur de l’Application ou du Site sont les suivantes (ci-après les « Informations ») :
            o coordonnées du professionnel,
o adresse des Centres de lavage,
o les offres de Lavage sur site, 
o les prix et réductions proposées par le Centre de lavage, o les horaires d’ouverture et de fermeture...  
Toutes ces Informations sont fournies par les Centres de lavages concernés et sous leur seule responsabilité. Leur éventuel caractère incomplet ne saurait être imputable, ni engager la responsabilité de WashMee.
    Les centres de lavages des Partenaires et Non-Partenaires sont présentés sur l’Application et le Site par situation géographique (à proximité de l’adresse saisie par l’Utilisateur de l’Application ou de sa géolocalisation).
        Les résultats affichés e cas de recherche ne sauraient se montrer exhaustifs. Ils correspondent aux offres proposées par les Partenaires ayant conclu un contrat de partenariat avec la Société, et, le cas échéant, des Non-Partenaires répertoriés sans avoir signé de contrat de partenariat avec la Société.
            Les Informations sont mises à jour de façon continue, dès qu’un Partenaire souhaite apporter e modification sur son offre, celle-ci est effectuée dans un maximum de 48 heures ouvrées. De même, un
 CGU WashMee 2020 – version 1.95  
  
Non-Partenaire dont le centre de lavage est répertorié peut également signaler toute erreur le concernant, ou demander son retrait   de l’Application et du Site, à l’adresse suivante : ino@washmee.fr

.2. Services payants
          <p>6.2.1.Fournis uniquement au travers de l’Application et du Site</p>
L’utilisation de l’Application et/ou du Site, offre aux Utilisateurs, en sus de ceux proposés et décrit au 6.1, les services payants suivants :
<br />1)  u n service permettant à l’Utilisateur disposant d’un Compte client, sous réserve de l’acceptation des Conditions Générales de Vente applicables, d’acheter un ou plusieurs Servics, un ou plusieurs Codes lavage utilisables dans le Centre de lavage sélectionné par l’Utilisateur.
            <br />2) u service permettant à l’Utilisateur d’acheter des jetons de lavage ou des programmes de lavage en portique ou tunnel. En fonction des matériels utilisés par la station de lavage sélectionnée au préalable par l’Utilisateur, l’achat de jetons de lavage ou de programmes de lavage en portique ou tunnel se matérialise par la création dans l’Application rubrique « Mes Lavages » soit d’un QR code, d’un code numérique ou d’une information dans l’Application indiquant à l’Utilisateur que les de jetons de lavage ou de programmes de lavage en portique ou tunnel peuvent être respectivement récupérés ou déclenchés directement par l’Application. La durée de validité de l’achat est d’UN (1) AN à compter de la date d’achat (action de retirer physiquement les jetons en station ou de déclencher un lavage).
            <br />3) un service permettant à l’Utilisateur de prendre rendez-vous pour la réalisation d’une prestation de lavage dans le centre de son choix. Ce service est valable chez un Partenaire ayant souscrit au service de prise de rendez-vous.
            <br />4) un service permettant à l’Util i sateur de prendre rendez-vous pour la réalisation d’une prestation de lavage à une adresse déterminée et à n moment spécifié sur l’Application ou le Site.
            <br />5) Un service permettant à l’Utilisateur de réserver un créneau sur une piste de lavage via la fonction FastPass. Ce service est uniquement valable chez un Partenaire ayant souscrit au service FastPass. Pour toute information complémentaire, veuillez envoyer un courriel à info@washmee.fr.
            <p>6.2.2.Fournis au travers du Site uniquement</p> 
            Le Site met - en plus du point 6.1 - à disposition des Uti l isateurs du Site le service suivant :  
            <br /> - un service permettant, au moyen de forfaits annuels ou m e nsuels, aux Clients Professionnels de s’inscrire sur l’interface de gestion web au moyen d’un identifiant et d’un mot de passe, sous réserve de l’acceptation des Conditions Générales de Vente applicables. Le Client Professionnel pourra alors renseigner tous les détails de son entreprise de lavage, communiquer, proposer des Promotions et inscrire ses collaborateurs et/ou lui-même au programme de lavage à domicile donnant accès à l’Application Professionnelle.
            <p> 6.2.3.Service payant spécifique fourni par l’Application Professionnelle uniquement</p>
            L’Application Professionnelle permet l’obtention d service suivant :
            <br />- un service permettant, au moyen de forfaits annuels ou mensuels, au Client Professionnel denseigner sur l’Application Professionnelle toutes les informations nécessaires à son activité au moyen d’un identifiant et mot de passe, sous réserve de l’acceptation des Conditions Générales de Vente applicables.
      CGU WashMee 2020 – version 1.9    
            6

            <br />o Le Client Professionnel aura accès sur l’Application Professionnelle aux demandes de lavages immédiats et différés. Il pourra :
accepter, les refuser ou proposer deveaux rendez-vous ;
            <br />▪ proposer une modification de la prestation initialement commandée par le Client via l’Application ou le Site directement par l’Application Professionnelle. Le Client pourra alors accepter ou refuser la proposition de modification de la prestation.
            <br />▪ créer une nouvelle commande de prestation qu’il enverra par l’Application Professionnelle au Client disposant d’un Compte Client. Le Client pourra alors accepter ou refuser la nouvelle proposition de prestation.
                <br />▪ accéder à un tableau de bord de l’activité générée par l’Application ou le Site, à la liste des prestations déjà rélisées, ax notes et commentaires laissés par les Clients – auxquels il pourra répondre directement par l’Application Professionnelle – et à la localisation des prestations commandées par les Clients de l’Application ou du Site.
            <p>7. SERVICES DE LAVAGE 7.1. Remarquepréliminaire  </p>
LES SERVICES DE LAVAGE SUR SITE QUI SONT PRESENTES SUR L’APPLICATION SONT FOURNIS PAR LES PARTENAIRES SOUS LEUR PROPRE RESPONSABILITE.
WASHMEE NE FOURNIT AUCUNE PRESTATION DE SERVICE DE LAVAGE.
            <br /><p>7.2. Services de lavage sur site</p>
            Les services de Lavage sursite sont présentés sur l’Application ou le Site avec un descriptif informant le Client de l’identité du prestataire de service, l’adresse du Centre de lavage, ses coordonnées postales, téléphoniques et électroniques – si elles ont été renseignées par le Partenaire –, les caractéristiques essentielles des services proposés, leur prix, les éventuelles promotions en cours et les éventuels commentaires et notes.
Ces informations seront à renseigner par le Partenaire sur le Site.
            <br /><p>7.3. Services de lavage en déplacement</p>
            Les ervices de Lavage en déplacement sont présentés sur l’Application ou le Site avec un descriptif informant le Client de l’identité du prestataire de service, les caractéristiques essentielles des services proposés, leur prix, les éventuelles promotions en cours et les éventuels commentaires et notes.
Ces informations seront à renseigner par le Partenaire sur le Site.
            <p>8. INFORMATIONS FOURNIES PAR LES PRESTATAIRES </p>
                <p>8.1. Contenu des informations et recommandations</p>
            Les nformations disponibles sur l’Application, l’Application Professionnelle et/ou le Site sont proposées à l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site en toute bonne foi. Elles sont fournies par les Partenaires qui les renseignent directement. Ces Informations sont supposées être correctes et à jour au moment où l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site en prend connaissance.
 CGU WashMee 2020 – version 1.9
7
            
Toutefois, WASHMEE n’est pas garante du caractère exhaustif et complet de celles - ci, ni de leur mise à jour. Toute personne constatant qu’une information est incomplète ou erronée est invitée à le signale à
’adresse suivante : info@washmee.fr. 8.2. Avis et notations
L’Utilisateur de l’Application et/ou du Site qui a procédé à un achat sur l’Application et/ou le Site a la possibilité de soumettre un commentaire et de sélectionner des étoiles afin d’évaluer une prestation effectuée dans l’un des Centres de lavage.
lient soumet un commentaire ou évalue un Centre de lavage, il s’engage à le faire de bonne foi.
Le Client ne peut, en aucun cas, émettre de commentaires contenant des propos de   nature discriminatoire et/o diffamatoire et/ou des injures.
Les étoiles et lescommentaires seront visbles pour les autres Utilisateurs de l’Application et du Site.
Les Clients Professionnels de l’A p plication Professionnelle et du Site pourront modérer le s  commentaires laissés sur leurs Centres de lavage et leurs prestations en répondant aux commentaires des Utilisateurs de l’Application. Ils pourront aussi demander à la Société de supprimer des commentaires jugés contraires aux présentes CGU ou jugés comme erronés, sous réserve de fournir une explication circonstanciée à la Société par courriel : info@washmee.fr ou directement par l’interface de gestion web. WASHMEE se réserve le droit d’accepter et/ou de refuser de telle demande.
            <p>9. MODALITES DE REGLEMENT DES SERVICES</p> 
            <p>9.1. Services de lavage</p>
Le paiement des Services de lavage s’effectue par l’intermédiaire de l’Application ou du Site via le servi c e d’encaissement P a yline, édité par la SAS Monnext de droit français, au capital de 63 968 460 euros, dont le siège social est situé Tour Ariane, 5 Place de la pyramide, 92088 PARIS LA DEFENSE Cedex et immatriculée au Registre du commerce et des sociétés de Nanterre sous le numéro 503 185 001.
L’utilisation de Payline est soumise à des conditions générales d’utilisation que l’Utilisateur devra accepter lors de la création de son Compte client et préalablement à tout paiement. Ces conditions générales d’utilisation sont également consultables à tout moment sur le site https://www.payline.com. Les données bancaires du Client sont collectées uniquement par Payline pour les besoins du paiement et ne sont pas conservées par la Société.
            <p>9.2. Abonnements  </p>
                Le paiement des abonnements pour les Partenires s’effectue par l’intermédiaire du site via le service d’encaissement Stripe Payment Europe, Ltd, dont le siège est basé au 7ème étage de Bower Warehouse, 211 Old Street, London EC1V 9NR, United Kingdom et dont les conditions générales d’utilisation sont soumises à l’acceptation du Client Professionnel lors de la création de son Compte Client préalablement à tout paiement. Ces conditions générales d’utilisation sont également consultables à tout moment sur le site https://stripe.com. Les données bancaires du Client sont communiquées à Stripe Payment Europe, Ltd pour les besoins du paiement et ne sont pas conservées par la Société.
     CGU WashMee 2020 – version 1.9
    8

            <p>10. DISPOSITIONS FINANCIERES 10.1.Remarque préliminaire</p>
ciété a conclu des contrats de partenariat avec tous les Partenaires présents sur l’Application et le Site, en vue de leur référencement et afin de leur permettre de fournir leurs services par l’intermédiaire de l’Application, du Site et/ou de l’Application Professionnelle.
La Société est rémunérée par ces professionnels en cas de vente de services ainsi que par un système d’abonnement pour l’accès à l’interface de gestion web sur le Site et pour l’accès à l’Application Professionnelle.
Des centres de lavage de non-Partenaires sont également répertoriés, sans exhaustivité ni garantie : les informations les concernant sont données à titre informatif exclusivement et ne sauraient constituer une offre de vente ou de prestation de service.
            <br /><p>10.2.Accès au service de paiement</p>
Le service de paiement sécurisé, disponible sur l’Application et le Site, est réalisé par l’intermédiaire du service d’encaissement Payline. L’acceptation des Conditions Générales d’Utilisation de la Société vaut pour acceptation des conditio n s générales d’utilisation de Payline. C’est un prérequis à l’utilisation du service de paiement.
De même, l’acceptation des Conditions Générales d’Utilisation de la Société vaut pour acceptation des conditions générales d’utilisation du service de paiement de Stripe Europe Ltd lors de l’accès à l’interface de gestion web pour les professionnels du lavage. C’est un prérequis à l’utilisation du service de paiement.
            <br /><p>11. PROMOTIONS </p> 
            Le Client Professionnel a la possibilité, à son entière discrétion, de réaliser des Promotions ponctuelles sur l’Application et le Site.
Ces Promotions peuvent s’appliquer sur des types de lavages mais pas sur des options.
Les promotions sont paramétrées – date, heure, type(s) de lavage(s) concerné(s), nom de la Promotion – via le Site dans le Compte professionnel.
            Les Promotions sont visiblesobligatoirement sur l’Application et le Site. L’Utilisateur de l’Application et/ou du Site convient que les Promotions :
            <br />- doivent être utilisées légalement par le public auquel elles sont destinées, pour les finalités envisagées et dans les délais de validité prévus
            <br />- peuvent être désactivées par le Client Professionnel ou la Société à tout moment, pour quelque raison que ce soit, sans engager la responsabilité du Client Professionnel ou de WashMee
            <br />- ont une durée de validité définie par le Client Professionnel
            <br />- sont utilisables pour l’achat de lavages ou de jetons, à la discrétion d la Société.  
            <br />- ne peuvent être utilisées que lors d’un achat de lavage ou de jetons payé via l’Application ou le Site.
            <p>12. PROPRIÉTÉ INTELLECTUELLE 12.1.Informations et documents</p>
         CGU WashMee 2020 – version 1.9
            9
            
            Toutes les informations ou documents (textes, images animées ou non, bases de données, sons, photos, savoir-faire, produits cités) contenus dans l’Application, l’Application Professionnelle ou le Site ainsi que tous les éléments créés pour l’Application, pour l’Application Professionnelle ou le Site et sa structure générale, sont soit la propriété de la Société ou de ses filiales , soit font l’objet de droits d’utilisation, de reproduction et de représentation consentis au profit de ces dernières. Ces informations, documents ou éléments sont soumis aux lois protégeant le droit d’auteur dès lors qu’elles sont mises à la disposition du public sur l’Application, sur l’Application Professionnelle ou sur le Site.
ucune licence, ni aucuns droits autres que ceux de télécharger et consulter l’Application, l’Application Professionnelle ou le Site, ne sont conférés à quiconque au regard des droits de propriété intellectuelle. La reproduction des documents de l’Application, de l’Application Professionnelle ou du Site est autorisée à des fins exclusives d’information pour un usage personnel et privé : toute reproduction et toute utilisation de copie réalisée à d’autres fins est expressément interdite et soumise à l’autorisation préalable, écrite et expresse de la Société. Dans tous les cas, la reproduction autorisée des informations contenues dans cette Application, dans cette Application Professionnelle ou ce Site devra indiquer la source et la mention de propriété adéquates. Cette Application, cette Application Professionnelle et ce Site respectent le droit d’auteur. Tous les droits des auteurs des œuvres protégées reproduites et communiquées sur cette Application, sur cette Application Professionnelle ou ce Site sont réservés. Sauf autorisation, toute utilisation des œuvres autres que la reproduction et la consultation individuelle et privée sont interdites.
            <br /><p>12.2.Bases de données</p>
les bases de données mises à la disposition de l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site sont la propriété de la Société qui a la qualité de producteur des bases de données. Il est interdit à l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site d’extraire ou de réutiliser une partie qualitativement ou quantitativement substantielle des bases de données y compris à des fins privées.
            <br /><p>13. RESPONSABILITÉ ET MISES EN GARDE13.1.Responsabilité  </p>
Chaque Utilisateur de l’Application, de l’Application Professionnelle ou du Site déclare se conformer aux présentes Conditions Générales d’Utilisation, celles des plateformes de téléchargement, des prestataires ters et lois en vigueur.
            L’Utilisateur de l’Application, du Site et/ou de l’Application Professionnelle, et le Client Professionnel du Site sera seul responsable de l’utilisation de ses Identifiants et/ou Identifiants Professionnels. Toutes actions ou déclarations faites par l’intermédiaire de son compte personnel, qu’elles soient frauduleuses ou non, sont de nature à engager la responsabilité de l’Utilisateur qui garantit la Société contre toute action en résultant.
Par ailleurs, la Société n’ayant pas pour obligation et ne disposant pas des moyens techniques de s’assurer de l’identité des personnes s’inscrivant sur l’Application, l’Application Professionnelle ou le Site, elle n’es pas responsable en cas d’usurpation de l’identité de l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site. Si l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site a des raisons de penser qu’une personne utilise frauduleusement des éléments d’identification ou son compte, il devra en informer immédiatement la Société.
            En cas de perte ou de vol d’un des Identifiants et/ou Identifiants Professionnels le concernant, l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site est responsable de toute conséquence dommageable de cette perte ou de ce vol, et doit utiliser, dans les plus brefs délais, la procédure lui permettant de les modifier. Dans l’hypothèse où il aurait connaissance de l’accès d’une autre personne à son Compte client, l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site doit en informer
 CGU WashMee 2020 – version 1.9
    10    

immédiatement l’éditeur de l’Application, de l’App l ication Professionnelle et du Site par e-mail à l’adresse info@washmee.fr.
ne utilisation de l’Application, de l’Application Professionnelle ou du Site qui serait frauduleuse, ou qui contreviendrait aux présentes CGU, pourra justifier la fermeture du Compte client concerné.
        <p>13.2.Liens hypertextes</p>
L’Application, l’Application Professionnelle ou le Site peuvent comporter des liens vers des sites tiers sur lesquels la Société n’a pas de contrôle. La décision d’activer les liens appartient exclusivement aux Utilisateurs de l’Application, de l’Application Professionnelle ou du Site. La Société peut, à tout moment, modifier ou supprimer un lien sur l’Application, l’Application Professionnelle ou sur le Site.
            <p>13.3.Fonctionnement </p> 
La Société ne garantit pas, sans que cette liste soit exhaustive, que l’Application, l’Application Professionnelle ou le Site fonctionne sans interruption et que les serveurs qui y donnent accès ou les applications tierces pour lesquels apparaissent des liens hypertextes ne contiennent pas de virus.
            13.4.Prestations de lavage
Dans le prolongement de l’art 7.1 des présentes CGU, il est rappelé que la Société ne peut donc pas être tenue pour responsable de la « qualité » de a prestation effectuée par le Client Professionnel pour le compte de l’Utilisateur.
            Le Client Professionnel estseul responsable de la prestation fournie à l’Utilisateur et devra donc réparer les préjudices éventuels en cas de réclamation de l’Utilisateur.
            <p>14.MISES À JOUR DES CONDITIONS D’UTILISATION DE L’APPLICATIONWASHMEE, DE L’APPLICATION PROFESSIONNELLE WASHMEE PRO ET DU SITE</p>
            La Société peut procéder à la mise à jour de ces Conditions Générales d’Utilisation à tout moment. En conséquence, il est conseillé à l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site de se référer régulièrement à la dernière version des Conditions d’Utilisation de l’Application, de l’Application Professionnelle ou du Site en vigueur disponible en permanence sur le Site https://www.washmee.fr, ainsi que sur l’Application et l’Application Professionnelle.
            <p>15. NULLITE PARTIELLE</p>
Si une ou plusieurs stipulations des présentes CGU sont tenues pour non valides ou déclarées comme tel en application d'une loi, d'un règlement ou d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et toute leur portée.
       <p>   16. ENGAGEMENT DE L’UTILISATEUR DE L’APPLICATION WASHMEE, DE L’APPLICATION PROFESSIONNELLE WASHMEE PRO ET DU SITE</p>  
            <p>16.1.Engagement lors de l’accès</p>
Chaque Utilisateur de l’Application, de l’Application Professionnelle et du site déclare : - être majeur
   CGU WashMee 2020 – version 1.9
            11
                
            <br />- disposer de la capacité et des équipements et moyens nécessaires pour accéder, téléchager et utiliser l’Application, l’Application Professionnelle ou le Site
rendre à sa charge les frais de télécommunication induits pour accéder et utiliser l’Application, l’Application Professionnelle ou le Site, en fonction de son abonnement avec son opérateur de téléphonie mobile et son fournisseur d’accès internet.
            <p>16.2.Engagement lors de l’utilisation</p>
En utilisant l’Application, l’Application Professionnelle ou le Site, l’Utilisateur accepte de ne pas :
    <br />- utiliser l’Application, l’Application Professionnelle ou le Site à des fins illicites
        <br />- utiliser l’Application, l’Application Professionnelle ou le Site d’une façon qui pourrait interrompre, endommager, nuire à l’Application, l’Application Professionnelle ou au Site ou les rendre moins efficaces
            <br />            - transférer des fichiers contenant des virus, cheval de Troie ou tout autre programme informatique nuisible
            <br />- accéder ou tenter d’accéder au compte d’un autre Visiteur ou contourner ou tenter de contourner lesmesures de sécurité de l’Application, l’Application Professionnelle ou du Site ; ou utiliser l’Application, l’Application Professionnelle ou le Site d’une manière qui porterait atteinte à notre réputation, notre nom ou notre image.
            <p>17. SERVICE CLIENT</p>
    Pour toute question ou réclamation relative à l’utilisation de l’Application, de l’Application Professionnelle ou du Site, l’Utilisateur et/ou le Client Professionnel peut contacter la Société éditrice :
      <br />  - par courriel à info@washmee.fr
          <br />  - par courrier postal à l’adresse suivante :
            Société WashMee
39 rue Carnot  
92300 LEVALLOIS-PERRET – France
- par téléphone au numéro suivant : 06 52 2 07 69 (nos conseillers sont à votre disposition du lundi au vendredi de 9h à 18h)
18. SUSPENSION - RESILIATION
Les présentesonditions Générales d’Utilisation sont conclues pour une durée indéterminée. Chaque Partie peut en conséquence résilier les CGU à tout moment sans avoir à en justifier moyennant le respect d’un préavis de 2 mois.
En cas de manqu e ment par l’Utilisateur à l’une quelconque de ses obligations, la Société pourra après mise en demeure envoyée par tous moyens, restée sans effet pendant une durée de 15 jours, résilier le présent pourra résilier les présentes CGU sans préavis et sans que l’Utilisateur ne puisse prétendre à une quelconque indemnité, par simple envoi d'une lettre recommandée avec avis de réception.
    En cas de manquement grave et/ou épété par l’Utilsateur à ses obligations ou affectant ses comptes, tel que notamment et sans que cette liste soit limitative : fraude, propos injurieux, contrefaçon, atteinte aux droits des tiers, la Société est en droit de rompre les relations avec effet immédiat.
  CGU WashMee 2020 – version 1.9
12

            <p>19. DONNÉES PERSONNELLES </p> 
 Société est amenée à collecter les données à caractère personnel suivantes : nom, prénom, adresse de courriel, numéro de téléphone, pays de résidence, plaque d’immatriculation. Les traitements qui en découlent se fondent, selon les services proposés, soit sur l’exécution du contrat, soit sur l’intérêt légitime de WashMee. Les noms et prénoms enregistrés dans le Compte client ne devront pas enfreindre un quelconque droit de propriété intellectuelle, violer la vie privée ou tout autre droit d’un tiers, usurper l’identité d’un tiers ou d’un autre Utilisateur de l’Application, de l’Application Professionnelle ou du Site, porter atteinte à l’ordre public et aux bonnes mœurs.
Tout traitement de données à caractère personnel effectué par la Société lors de la navigation sur l’Application, l’Application Professionnelle et le Site est réalisé conformément aux disposition du Règlement Général sur la Protection des Données, la Loi informatique et liberté et sa Charte sur la protection des données à caractère personnel consultable à tout moment sur l’Application, l’Application Professionnelle et sur le site internet https://www.washmee.fr ou directement en cliquant sur le lien suivant: https://www.washmee.fr/legal?a=mentions#v-pills-pc.
            <p>20. LOI APPLICABLE ET TRIBUNAUX COMPÉTENTS</p>
L’Application, l’Application Professionnelleet le Site sont édités par une société française et s’adressent à des Utilisateurs de l’Application, de l’Application Professionnelle et du Site situés exclusivement sur le territoire français.
Les Conditions Générales d’Utilisation de l’Application, de l’Application Proessionnelle et du Site sont soumises au droit français, sauf dispositions d’ordre public contraires dnt pourrait bénéficier un Utilisateur de l’Application, de l’Application Professionnelle ou du Site ayant la qualité de consommateur.
En tout état de cause, tout Utilisateur de l’Application, de l’Application Professionnelle ou du Site a la possibilité de recourir, en cas de réclamation écrite non résolue, à une procédure de médiation ou à tout autre mode alternatif de règlement des différends. Plus particulièrement, l’Utilisateur de l’Application, de l’Application Professionnelle ou du Site ayant la qualité de consommateur pourra recourir gratuitement à une procédure de médiation auprès du médiateur de la consommation.
La Commission Européenne met également à disposition des consommateurs une plateforme en ligne de résolution des différends à laquelle vous pouvez accéder directement en cliquant sur le lien : http://ec.europa.eu/consumers/odr/. En cas de litige non résolu, les tribunaux français seront compétents, sauf dispositions d’ordre public contraires prévoyant la compétence d’une autre juridiction.

        </div>
        <Footer />
        </>
    )
}

export default CGU;