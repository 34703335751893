import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

import '../../style/home/textImg.css';

export const WashmeeEasyDesktop = () => {
    return (
        <>
            <h1 style={{ marginRight: '20em', fontSize: '1.9em' }}>Marketplace du lavage auto</h1>
            <section className="section-text-img-particu-pro" style={{ marignTop: '20em' }}>
                <div id="headerText">

                    <p>WashMee c’est la marketplace où vous retrouvez l'ensemble
                    des professionnels du lavage automobile en France pour vous
                    simplifier le nettoyage de votre voiture ou 2 roues.
                    Que vous soyez un(e) passionné(e) ou une personne considérant
                    son véhicule comme un simple moyen de locomotion, WashMee répond
                                 à vos attentes.</p>
                    <p>En quelques clics, choisissez le lavage qui vous convient :
                    d’un lavage haute pression à un nettoyage poussé réalisé à
                                     la main par un pro...</p>
                    <p>WashMee est pour vous la certitude de trouver le professionnel
                                     proche de chez vous qui répond à vos besoins du moment.</p>
                    <div className="btn-div-text">
                        <button style={{ textAlign: 'center', margin: '0 auto' }} className="btn-washmee" onClick={() => window.location = "https://washmee.fr/commander-lavage/?p=commander-station"}>Commander un Lavage</button>
                    </div>

                </div>
                <div id="headerImage">

                    <div class="partContentFileImageGallery partContentFileImageGallerySingle">

                        <img src="img/particular/lavage-auto-simple-washmee-particulier.jpg" alt="image" />

                    </div>

                </div>

            </section>
        </>

    )
}