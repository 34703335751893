import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import SliderDynamic from "../../components/SliderDynamic";
import { WashMeeServicePro } from './Mobile';
import { professional } from '../../helpers/texts/professional.json';

import '../../style/professional/professional.scss';
import '../../style/professional/professional-phone.scss';
import '../../style/professional/form.css';
import '../../style/utils.scss';


const WashmeeServicePro = () => {
    return (
        <>
            <h1 style={{ marginRight: '20em', fontSize: '1.9em' }}>Service  adapté aux entreprises</h1>
            <section className="section-text-img-particu-pro" style={{ marignTop: '20em' }}>

                <div id="headerText">

                    <p>  WashMee est la première marketplace française du lavage
                                 automobile qui référence tous les types de lavage : lavage à                               la main sans eau ou à la vapeur, lavage haute pression et lavage                                        portique/tunnel.</p>                               <p>Notre solution innovante s’adapte à chaque professionnel :                               chef d’entreprise, gestionnaire de flotte, responsable d’une                               conciergerie, constructeur/importateur automobile, concessionnaire,
                                         garagiste, carrossier, loueur ...</p>
                </div>

                <div id="headerImage">
                    <div class="partContentFileImageGallery partContentFileImageGallerySingle">
                        <img src="img/professional/washmee-lavage-auto-professionnel.jpg" alt="image" />
                    </div>
                </div>
            </section>
        </>

    )
}

const ClientTypo = () => {
    return (
        <section id="section-typo-client">
            <Row style={{ width: '100%' }}>
                <Col>
                    <h2>À qui s’adresse le service de lavage auto WashMee ?</h2>
                </Col>
            </Row>
            <Card data={professional.clientTypo} />

        </section>
    )
}

const Card = ({ data }) => {
    return (
        <Container >
            <ScrollAnimation animateIn="fadeIn">
                <Row className="cards-component" style={{ width: '100%' }}>
                    {
                        data.map((e, i) =>
                            <Col className="card-component" md={4}>
                                <Row className="text-over-image no-gutters">
                                    <div className="col-6 image"><img alt="carte avis" src={e.img} /></div>
                                    <div className="col-6 text">
                                        <div>
                                            <h2>{e.title}</h2>

                                            <p className={i === 0 ? 'first' : i === 1 ? 'second' : ''}>{e.text}</p>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                        )
                    }
                </Row>
            </ScrollAnimation>
        </Container >
    )
}

//CSS dans particular.scss => À changer au plus vite
const PictoSection = () => {

    return (
        <section id="picto-section">
            <Container>
                <Row>
                    <Col>
                        <h2> Un large panel de services pour répondre à toutes vos attentes </h2>
                    </Col>
                </Row>
                {
                    professional.pictoSection.map(e =>
                        <Row className="row-content" style={{ marginTop: '2em', margin: '' }} >
                            <Col md xs={12}>
                                <Row>
                                    <ScrollAnimation animateIn="slideInLeft">
                                        <Col className="col-icon" md={2} xs={12} >
                                            <img alt={e.title} className="icon" height="70" width="150" src={e.img} />
                                        </Col>
                                    </ScrollAnimation>

                                    <Col xs={12} md={10}>
                                        <ScrollAnimation animateIn="slideInRight">
                                            <p className="sub_title bold-tilinium">{e.title}</p>
                                            <p className="text-third">{e.text}</p>
                                        </ScrollAnimation>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                    )}
            </Container>
        </section >
    )
}

const StepForPro = () => {
    return (
        <section id="step-section">
            <Row>
                <Col>
                    <h2>La solution WashMee adaptée au lavage de vos véhicules</h2>
                </Col>
            </Row>
            <Row className="content-row">
                {
                    professional.step.map(e =>
                        <Col md={3}>
                            <Row>
                                <Col md>
                                    <img alt={e.text} src={e.img} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <p>{e.text}</p>
                                </Col>
                            </Row>
                        </Col>
                    )
                }
            </Row>
        </section>
    )
}

const Professional = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };
    return (
        <>
            <Header />
            <SliderDynamic
                slides={professional.slides}
                page="pro"
                title="WASHMEE, LA RÉFÉRENCE FRANÇAISE DU LAVAGE AUTOMOBILE"
                isSmallScreen={isSmallScreen}
                slidesMobile={professional.slidesMobile}
            />
            {!isSmallScreen ? <div style={{ height: '5em' }}></div> : <></>}

            {
                !isSmallScreen
                    ? <WashmeeServicePro />
                    : <WashMeeServicePro />
            }
            <ClientTypo />
            <PictoSection />
            <StepForPro />
            <Container fluid style={{ marginBottom: '1em' }}>
                <Row className="align-center">
                    <Col>
                        <button className="btn-washmee" onClick={() => window.location = "/contact?form=pro"}>Contactez-nous</button>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Professional;