import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import '../../style/notfound.scss';

const NotFound = () => {
    return (
        <>
            <Header />
            <Container className="container-404" fluid>
                <Row>
                    <Col md>
                        <p className="title-404 align-center">Ouuuups, cette page n'existe pas ....!</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-center" md>
                        <img src="img/utils/page-404.png" alt="404" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default NotFound;