import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Slider from '../../components/SliderDynamic';
import { WhyWashmeeMobile } from './Mobile';
import { WhyWashmeeDesktop } from './Desktop';

import { washer } from '../../helpers/texts/washer.json'
import '../../style/washer/washer.scss';

const WashmeeSolution = () => {
    return (
        <section id="washmee-solution" >
            <Container >
                <Row>
                    <Col>
                        <h2 className="align-center">Lavage en station et / ou lavage à domicile : nous avons la solution !</h2>
                    </Col>
                </Row>
                <Card data={washer.solution} />
            </Container>
        </section>
    )
}
const Card = ({ data }) => {
    return (
        <Container fluid >
            <ScrollAnimation animateIn="fadeIn">
                <Row >
                    {
                        data.map((e, i) =>
                            <Col className="card-component" md={6} xs={12} style={{ marginRight: '0em' }}>
                                <Row className="text-over-image no-gutters">
                                    <Col className="image" md={6} xs={6}><img alt={e.alt} src={e.img} /></Col>
                                    <Col className="text" md={6} xs={6}>
                                        <div style={{ float: 'right' }}>
                                            <p className={i === 0 ? 'first' : i === 1 ? 'second' : ''}>{e.text}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    }
                </Row>
            </ScrollAnimation>
        </Container >
    )
}

const MultipleService = ({ isSmallScreen }) => {
    return (
        <section id="section-multiple" style={{ marginTop: '2em', padding: '2em' }}>
            <Container fluid>
                <Row>
                    <Col>
                        <h2 className="align-center" style={{ marginBottom: '1em' }}>Un service aux multiples avantages</h2>
                    </Col>
                </Row>
                <Row class="left_item">
                    {
                        washer.service1.map(e =>

                            <Col md>
                                {
                                    isSmallScreen
                                        ? <Row>
                                            <Col className="align-center" md>
                                                <img class="icon" alt={e.alt} src={e.img} />
                                            </Col>
                                        </Row>
                                        : <></>

                                }
                                <Row class="rigth_item">
                                    {
                                        !isSmallScreen
                                            ? <ScrollAnimation animateIn="bounce">
                                                <Col md={2} className="col-img-service">
                                                    <img class="icon" alt={e.alt} src={e.img} />
                                                </Col>
                                            </ScrollAnimation>
                                            : <></>
                                    }

                                    <Col md>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <p class="sub_title bold-tilinium color-ligth-blue">{e.title}</p>
                                            <p class="text-third">
                                                {e.text}
                                            </p>
                                        </ScrollAnimation>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                </Row>
                <Row class="left_item">
                    {
                        washer.service2.map(e =>

                            <Col md>
                                {
                                    isSmallScreen
                                        ? <Row>
                                            <Col className="align-center" md>
                                                <img class="icon" alt={e.alt} src={e.img} />
                                            </Col>
                                        </Row>
                                        : <></>
                                }
                                <Row class="rigth_item">
                                    {
                                        !isSmallScreen
                                            ? <ScrollAnimation animateIn="bounce">
                                                <Col md={2} className="col-img-service">
                                                    <img class="icon" alt={e.alt} src={e.img} />
                                                </Col>
                                            </ScrollAnimation>
                                            : <></>
                                    }
                                    <Col md>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <p class="sub_title bold-tilinium color-ligth-blue">{e.title}</p>

                                            <p class="text-third">
                                                {e.text}
                                            </p>
                                        </ScrollAnimation>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                </Row>
            </Container>
        </section>
    )
}

const Washer = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };
    return (
        <>
            <Header />
            <Slider
                slides={washer.slides}
                title="WashMee, le partenaire des professionnels du lavage automobile !"
                page="washer"
                isSmallScreen={isSmallScreen}
                slidesMobile={washer.slides} />
            {isSmallScreen ? <WhyWashmeeMobile /> : <WhyWashmeeDesktop />}
            <MultipleService isSmallScreen={isSmallScreen} />
            <WashmeeSolution />
            <Container fluid style={{ marginBottom: '1em' }}>
                <Row className="align-center">
                    <Col>
                        <button className="btn-washmee" onClick={() => window.location = "/contact?form=washer"}>Contactez-nous</button>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Washer