import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

export const WashmeeProAndParticular = () => {
    return (
        <Container fluid>
            <Row style={{ margin: '0 auto' }}>
                <Col md xs={12}>
                    <Row>
                        <Col className="no-padding">
                            <h2 className="h2-particulier align-center" >Vous êtes un particulier</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md xs={12} className="no-padding" style={{ marginLeft: '2em!important' }}>
                            <figure className="snip1482" style={{ marginLeft: '0!important' }}>
                                <figcaption className="text-particulier">
                                    <p>5 000 professionnels sont répertoriés en France par WashMee.
                                    Haute pression, portique, tunnel, lavage à la main en centre fixe ou en
                                    déplacement, tout y est !</p>
                                    <p>WashMee vous permet de trouver le professionnel adapté à vos besoins
                                    à proximité de votre position, de réserver une prestation et de profiter
                                        d’offres exclusives.</p>
                                    <p><a href="/contact?form=particu" className="bold-tilinium" style={{ color: '#96D7DE' }}>Adressez-nous votre demande</a></p>
                                    <button className="btn-washmee" onClick={() => window.location = "https://washmee.fr/commander-lavage/?p=commander-station"}>Commander un Lavage</button>
                                </figcaption>
                                <a href="#"></a><img src="img/home/washmee-service-clients-particuliers.jpg" alt="sample45" />
                            </figure>
                        </Col>
                    </Row>

                </Col>
                <Col md xs={12}>
                    <Row>
                        <Col>
                            <h2 className="h2-entreprise align-center" style={{ marginLeft: '0!important' }}>Vous êtes une entreprise</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md xs={12} >

                            <figure className="snip1482" >

                                <figcaption className="text-pro">
                                    <p>Que vous soyez gestionnaire d’une flotte de véhicules, chef
                                    d’entreprise, gérant(e) d’une conciergerie... nous répondons à
                    tous les cahiers des charges.</p>
                                    <p>Découvrez les avantages de faire appel à WashMee pour le lavage
                                    de vos véhicules en vous rendant sur
    <a className="bold-tilinium" style={{ color: '#96D7DE' }} href="/entreprise"> notre page explicative.</a> </p>
                                    <p>Vous avez une demande ? <a className="bold-tilinium" style={{ color: '#96D7DE' }} href="/contact?form=pro">Contactez-nous</a> en détaillant votre besoin et notre équipe se charge de tout ! </p>
                                    <button className="btn-washmee" onClick={() => window.location = "/contact?form=pro"}>Contactez-nous</button>

                                </figcaption>
                                <a href="#"></a><img src="img/home/washmee-service-clients-professionnels.jpg" alt="sample60" />
                            </figure>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}



export const WashmeeUniversDesktop = () => {
    return (
        <section id="section-univers" className="text-img-section">
            <div id="div-title">
                <h1>L’univers</h1>
                <img className="logo-washmee-title" src="./img/logo.png" style={{ width: '9em' }} />
            </div>
            <ScrollAnimation animateIn="slideInLeft">
                <div id="div-text">
                    <p>WashMee est la marketplace qui référence l’ensemble des professionnels du
                    lavage de véhicules en France.</p>
                    <p>Un réseau de centres fixes et d’intervenants en déplacement qui
                 valorisent leurs services et leurs offres via notre marque.</p>
                    <p>Forts d’une visibilité nationale, nous mettons en relation les utilisateurs
                    avec les professionnels pouvant répondre aux besoins de chacun. Réservez en
                     quelques clics votre lavage via notre site ou notre appli mobile.</p>
                    <p>WashMee plus qu’une marque, un écosystème du lavage !</p>
                    <div className="btn-div-text">
                        <button className="btn-washmee" onClick={() => window.location = "https://washmee.fr/commander-lavage/?p=commander-station"}>Commander un lavage</button>
                    </div>

                </div>
            </ScrollAnimation>
            {/* <ScrollAnimation animateIn="slideInRigth"> */}
            <div id="div-img">
                {/* <div class="partContentFileImageGallery partContentFileImageGallerySingle"> */}
                <img className="img-div-img" src="img/home/story-washmee-lavage-auto.jpg" alt="image" />
                {/* </div> */}
            </div>
            {/* </ScrollAnimation> */}
        </section>

    )
}