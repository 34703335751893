import React, { useState, useEffect } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';

import { api } from '../../helpers/api';
import Header from '../Layouts/Header';

import { css } from "@emotion/core";

const override = css`
  display: block;
  margin-top: 3em;
  border-color: red;
  text-align: center
`;

const Newsletter = (props) => {
    const [state, setState] = useState(false)

    useEffect(() => {
        api.get(`/newsletter/:${props.match.params.token}`)
            .then(data => {
                setState(true);
                window.location = '/'
            })
            .catch(err => {
                toast.error("Erreur interne", {
                    autoClose: 5000,
                    position: "top-center",
                    closeOnClick: true,
                });
            })
    }, []);


    return (
        <>
            <Header />
            <p className="align-center" style={{ marginTop: '2em' }}>Merci d'avoir confirmé votre inscription à notre newsletter ! </p>
            <p className="align-center">Vous allez être rederigé vers notre page d'accueil !</p>

            <BeatLoader
                css={override}
                size={100}
                color={"#96D7DE"}
                loading={state.loading}
            />
        </>
    )
}

export default Newsletter;