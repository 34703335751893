import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';

import '../../style/partnership/searchBar.scss';
import '../../style/partnership/autocomplete.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';


const SearchBar = ({ setCity, removeState }) => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [washer, setWasher] = useState();
    useEffect(() => {
        if (selectedCity[0])
            setCity(selectedCity[0]);

        if (selectedCity.length === 0)
            setCity(null);

    }, [selectedCity[0]]);

    const getCityAutocomplete = (e) => {
        axios.get(`https://geo.api.gouv.fr/communes?nom=${e}&fields=departement&boost=population&limit=5`)
            .then(data => {
                const newCities = data.data.map((e => e.nom))
                setCities(newCities)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const resetInput = () => {
        removeState();
    }

    return (
        <>
            <Form.Group>
                <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onInputChange={(e) => getCityAutocomplete(e)}
                    options={cities}
                    onChange={setSelectedCity}
                    selected={selectedCity}
                    placeholder="Entrez votre ville ..."
                    emptyLabel="Entrez votre ville ..."
                />
                <img src="./img/partnership/close.png" onClick={() => resetInput()} />
            </Form.Group>
        </>
    )
}
export default SearchBar;
