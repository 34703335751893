import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import 'react-toastify/dist/ReactToastify.css';
import '../style/slider.scss';

import Form from '../containers/Professional/Form';


class SliderDynamic extends React.Component {
    constructor(props) {
        super(props);

        this.IMAGE_PARTS = 4;

        this.changeTO = null;
        this.AUTOCHANGE_TIME = 5000;

        this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };

        this.lat = null;
        this.lon = null;

        this.valueLocation = null
    }

    componentWillUnmount() {
        window.clearTimeout(this.changeTO);
    }

    componentDidMount() {
        this.runAutochangeTO();
        setTimeout(() => {
            this.setState({ activeSlide: 0, sliderReady: true });
        }, 0);

        navigator.geolocation.getCurrentPosition(position => {
            this.setState({ lat: position.coords.latitude, lon: position.coords.longitude });
        });
    }

    runAutochangeTO() {
        this.changeTO = setTimeout(() => {
            this.changeSlides(1);
            this.runAutochangeTO();
        }, this.AUTOCHANGE_TIME);
    }

    changeSlides(change) {
        window.clearTimeout(this.changeTO);
        const { length } = this.props.slides;
        const prevSlide = this.state.activeSlide;
        let activeSlide = prevSlide + change;
        if (activeSlide < 0) activeSlide = length - 1;
        if (activeSlide >= length) activeSlide = 0;
        this.setState({ activeSlide, prevSlide });
    }





    render() {
        const { activeSlide, prevSlide, sliderReady } = this.state;
        return (
            <div className={classNames('slider', { 's--ready': sliderReady }, 'sliderDynamique')}>
                <h1 className="slider__top-heading">{this.props.title}</h1>
                <div className="slider__slides">
                    {
                        !this.props.isSmallScreen
                            ? <>
                                {this.props.slides.map((slide, index) => (
                                    <div
                                        className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index })}
                                        key={index}
                                    >

                                        <div className="slider__slide-content">
                                            {
                                                this.props.page === 'pro'
                                                    ? <Row>
                                                        <Col>
                                                            <button className="btn-washmee-entreprise" onClick={() => window.location = "/contact?form=pro"}>Contactez-nous</button>
                                                        </Col>
                                                    </Row>
                                                    : <></>

                                            }

                                            {/* <h3 className="slider__slide-subheading"></h3>
                                <h2 className="slider__slide-heading">

                                </h2>

                                <p className="slider__slide-readmore">


                                </p> */}

                                        </div>
                                        <div className="slider__slide-parts">
                                            {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                                <div className="slider__slide-part" key={i}>
                                                    <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                            : <>
                                {this.props.slidesMobile.map((slide, index) => (
                                    <div
                                        className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index })}
                                        key={index}
                                    >

                                        <div className="slider__slide-content">
                                            {
                                                this.props.page === 'pro'
                                                    ? <Row>
                                                        <Col>
                                                            <button className="btn-washmee-entreprise" onClick={() => window.location = "/contact?form=pro"}>Contactez-nous</button>
                                                        </Col>
                                                    </Row>
                                                    : <></>
                                            }
                                        </div>
                                        <div className="slider__slide-parts">
                                            {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                                <div className="slider__slide-part" key={i}>
                                                    <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                    }
                </div>
            </div>
        );
    }
}

export default SliderDynamic;