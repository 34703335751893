import React from 'react';
import { toast } from 'react-toastify';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// import './config/i18n';

import {
    Home,
    Contact,
    NotFound,
    Partnership,
    Professional,
    Particular,
    Newsletter,
    CGU,
    MentionLegal,
    Washer
} from './routes';

const Router = () => {
    toast.configure();
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/index.html" exact component={Home} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/particulier" component={Particular} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/entreprise" component={Professional} />
                    <Route exact path="/espacepro" component={Washer} />
                    <Route path="/newsletterconfirmation/:token" component={Newsletter} />
                    <Route exact path="/CGU" component={CGU} />
                    <Route path="/mentionlegal" component={MentionLegal} />
                    <Route exact path="/partenaire" component={Partnership} />
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default Router;