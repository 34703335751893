import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Card as CardBlog } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import LazyLoad from 'react-lazyload';


import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import SliderDynamic from '../../components/SliderDynamic';
import Location from './Location';
import {
    WashmeeUnivers,
    WashmeeParticular,
    WashmeePro
} from './Mobile';
import {
    WashmeeProAndParticular,
    WashmeeUniversDesktop
} from './Desktop';

import { home } from '../../helpers/texts/home.json';

import '../../style/home/home.scss';
import '../../style/home/home-phone.scss';
import '../../style/home/textImg.css';
import '../../style/home/pro-particulier.css';


const ThreePicto = () => {
    return (
        <section id="three-picto-section">
            <Row>
                <Col className="col-h2">
                    <h2>La garantie WashMee</h2>
                </Col>
            </Row>
            <Row className="main-row-picto">
                {
                    home.picto.map(e =>
                        <Col md={4} xs={12} className="main-col-picto">
                            <Row className="row-picto">
                                <Col md={3} xs={12} className="no-padding">
                                    <ScrollAnimation animateIn="bounce">
                                        <img alt={e.text} src={e.img} />
                                    </ScrollAnimation>
                                </Col>
                                <Col md={7} xs={12} className="no-padding">
                                    <p>{e.text}</p>
                                </Col>
                            </Row>

                        </Col>
                    )
                }
            </Row>
        </section>
    )
}

const Partners = () => (
    <section id="partners" className="b-light-blue ">
        <h2>Nos partenaires en France </h2>
        <p>Retrouvez tous nos partenaires nationaux et régionaux sur notre rubrique partenaires.</p>
        <div className="slider">
            <div className="slide-track">
                {home.partners.map(e => (
                    <img src={e} alt="" />
                ))}
                {home.partners.map(e => (
                    <img src={e} alt="" />
                ))}
            </div>
        </div>
    </section>
);

const WashmeeWasher = ({ isSmallScreen }) => {
    return (
        <section id="banner">
            {
                !isSmallScreen
                    ?
                    <Row className="align-center">
                        <Col className="banner-picture" md={4}>
                            <img src="img/home/professionnel-lavage-auto-inscription.png" />
                        </Col>
                        <Col className="no-padding-rigth" md={1} >
                            <img src="img/home/polygone-bleu-full-screen.svg" className="banner-polygone" />
                        </Col>
                        <Col className="banner-color">
                            <Row>
                                <Col style={{ paddingLeft: '0', paddingRight: '3em' }}>
                                    <h2>Vous êtes un professionel du lavage ?</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={11}>
                                    <p>Gagnez en visibilité et la notoriété de votre marque avec WashMee</p>
                                    <p>Notre marque véhicule une image positive de votre profession
                                    en mettant en avant votre passion et votre professionnalisme.
                                    Être référencé chez WashMee, c’est la certitude d'être vu par des milliers
                                    d’utilisateurs, particuliers et entreprises.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button style={{ borderRadius: '6px' }} onClick={() => window.location = "https://washmee.fr/espacepro"} className="btn-savoir-plus">En savoir plus</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : <Row className="align-center">
                        <Col className="banner-picture" md={4}>
                            <img src="img/home/professionnel-lavage-auto.jpg" />
                        </Col>
                        <Col className="no-padding-rigth" md={1} >
                            <img src="img/home/polygone-bleu-mobile.svg" className="banner-polygone" />
                        </Col>
                        <Col className="banner-color">
                            <Row style={{ margin: '0 auto' }}>
                                <Col className="no-padding" style={{ margin: '0 auto' }}>
                                    <h3>Vous êtes un professionel du lavage ?</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="no-padding">
                                    <p>Gagnez en visibilité et la notoriété de votre marque avec WashMee</p>
                                    <p style={{ marginBottom: '1em' }}>Notre marque véhicule une image positive de votre profession
                                    en mettant en avant votre passion et votre professionnalisme.
                                    Être référencé chez WashMee, c’est la certitude d'être vu par des milliers
                                    d’utilisateurs, particuliers et entreprises.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button style={{ borderRadius: '6px' }} onClick={() => window.location = "https://pro.washmee.fr/pro"} className="btn-savoir-plus">En savoir plus</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }

        </section>
    )
}

const Blog = () => {
    const [articles, setArticles] = useState([]);
    const imgBlog = ["img/home/moteur-ferrari.jpg", "img/home/lavage-roue-voiture-blanche.jpg", "img/home/lavage-roue.jpg"];

    useEffect(() => {
        return axios.get('https://www.washmee.fr/blog/wp-json/wp/v2/posts',
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET',
                    'Access-Control-Allow-Headers': 'X-Requested-With'
                }
            })
            .then(data => {
                let tab = [];
                data.data.map((e, i) => {
                    let article;
                    if (i < 3) {
                        article = {
                            title: e.title.rendered,
                            link: e.link,
                            resume: e.excerpt.rendered,
                            img: "img/home/washmee-service-professionnel-lavage.png"
                        }
                        tab.push(article)
                    }
                })
                setArticles(tab)
            })
            .catch(err => {
                console.error(err);
            })
    }, []);


    return (
        <section id="section-blog" className="">
            <Row>
                <Col>
                    <h2>Les dernières actualités de WashMee </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Toujours à cœur de vous aider, vous trouverez nos conseils pour entretenir
                    votre véhicule en fonction des saisons.
                    <br />Profitez des offres exclusives de nos
                    partenaires pour redécouvrir le plaisir et les avantages de rouler dans un véhicule
                    propre et sain.</p>
                </Col>
            </Row>
            <Row className="cards">
                {
                    articles.map((e, i) =>
                        <CardBlog style={{ width: '18rem' }} key={i}>
                            <CardBlog.Img variant="top" src={imgBlog[i]} />
                            <CardBlog.Body>
                                <CardBlog.Title>{e.title}</CardBlog.Title>
                                <button target="_blank" className="btn-washmee" onClick={() => window.location = e.link}>Consulter cet article</button>
                            </CardBlog.Body>
                        </CardBlog>
                    )
                }
            </Row>
            <Row>
                <Col >
                    <p >Vous souhaitez en savoir plus sur comment bien entretenir votre véhicule ? <a className="bold-tilinium" href="https://washmee.fr/blog">Découvrez nos conseils.</a></p>
                </Col>
            </Row>
        </section >
    )
}

const Card = ({ data }) => {
    const [active, setActive] = useState(false);

    return (
        <Container id="card-section">
            <Row>
                <Col>
                    <h2 style={{ textAlign: 'center' }}>Trouvez un professionnel du lavage près de chez vous ! </h2>
                </Col>
            </Row>
            <ScrollAnimation animateIn="fadeIn">
                <Row className="cards-component-home">
                    {
                        data.map((e, i) =>

                            <Col className={"card-component-home " + (i >= 6 && !active ? 'no-display' : '')} md={4} onClick={() => window.location = e.href}>
                                <a href={e.href} style={{ width: '100%', height: '100%', display: "block", textDecoration: 'none' }}>
                                    <Row className="text-over-image-home no-gutters">
                                        <div className="col-6 image"><img src={e.img} alt={e.city} /></div>
                                        <div className="col-6 text">
                                            <div>
                                                <p>{e.city}</p>
                                            </div>
                                        </div>
                                    </Row>
                                </a>
                            </Col>

                        )
                    }
                </Row>
                {
                    !active
                        ? <Row style={{ marginBottom: '1em' }}>
                            <Col style={{ textAlign: 'center' }}>
                                <button className="btn-washmee" onClick={() => setActive(true)}>Afficher plus ...</button>
                            </Col>
                        </Row>
                        : <Row style={{ marginBottom: '1em' }}>
                            <Col style={{ textAlign: 'center' }}>
                                <button className="btn-washmee" onClick={() => setActive(false)}>Afficher moins ...</button>
                            </Col>
                        </Row>
                }

            </ScrollAnimation>
        </Container >
    )
}


const Home = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    return (
        <>
            <Header />
            <LazyLoad>
                <SliderDynamic isSmallScreen={isSmallScreen} slides={home.slides} slidesMobile={home.slidesMobile} title="WASHMEE, LA RÉFÉRENCE FRANÇAISE DU LAVAGE AUTOMOBILE" subtitle="Qu'est ce que WashMee ?" page="home" isSmallScreen={isSmallScreen} />
            </LazyLoad>
            <LazyLoad>
                <Location />
            </LazyLoad>
            <LazyLoad>
                <ThreePicto />
            </LazyLoad>
            <LazyLoad>
                {
                    !isSmallScreen
                        ? <WashmeeUniversDesktop />
                        : <WashmeeUnivers />
                }
            </LazyLoad>
            <LazyLoad>
                <Partners />
            </LazyLoad>
            <LazyLoad>
                {
                    !isSmallScreen
                        ? <WashmeeProAndParticular />
                        : <>
                            <WashmeeParticular />
                            <WashmeePro />
                        </>
                }
            </LazyLoad>
            <LazyLoad>
                <Blog />
            </LazyLoad>
            <LazyLoad>
                <WashmeeWasher isSmallScreen={isSmallScreen} />
            </LazyLoad>
            <LazyLoad>
                <Card data={home.localisation} />
            </LazyLoad>
            <LazyLoad>
                <Footer />
            </LazyLoad>
        </>
    )
}

export default Home;