import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import '../../style/utils.scss';

export const WashMeeServicePro = () => {
    return (
        <section className="section-service-pro" style={{ margin: '0 auto' }}>
            <Row>
                <Col className="text-section slideInRight" md={6}>
                    <Row>
                        <Col >
                            <h2 className="animated fadeIn">WashMee un service de lavage auto adapté à toutes les entreprises</h2>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '1em', marginTop: '1em' }}>
                        <Col md>
                            <img alt="histoire de washmee" src="img/professional/washmee-lavage-auto-professionnel.jpg" className="img-story-washmee" style={{ borderRadius: '7%' }} />
                        </Col>
                    </Row>
                    <Row className="main-row-service-pro">
                        <Col md={12} >
                            <ScrollAnimation animateIn="slideInLeft">
                                <p>WashMee est la première marketplace française du
                                lavage automobile qui référence tous les types de lavage :
                                lavage à la main sans eau ou à la vapeur, lavage haute pression
                                 et lavage portique/tunnel.</p>
                                <p>Notre solution innovante s’adapte à chaque professionnel :
                                chef d’entreprise, gestionnaire de flotte, responsable d’une
                                onciergerie, constructeur/importateur automobile, concessionnaire,
                                     garagiste, carrossier, loueur...</p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </section>
    )
}